<template>
    <div class="row" v-if="isAddingItem || addedItem">
        <div class="col-12">
            <div class="card border border-success af-progress-block">
                <div class="card-header bg-transparent border-success"><h5 class="my-0 text-success"><i class="mdi mdi-alert-circle-outline me-3"></i> Progress</h5></div>
                <div class="card-body">
                    <h5 class="card-title mt-0">Creating intake:</h5>
                    <div class="card-text text-muted mt-4">
                        <p v-if="failedAddingAsset">
                            <i class="mdi mdi-alert-outline text-warning me-1"></i> Couldn't update Asset.
                            <br/><small class="failed-adding-error-message">{{ failedAddingAsset.message }}</small>
                            <br/><a @click="tryAgain" class="btn btn-primary btn-sm af-accent">
                            <i class="fa fa-arrow-alt-circle-down"></i> Edit Asset
                        </a>
                        </p>

                        <p v-if="isAddingOrderLine">
                            <i class="mdi mdi-chevron-right me-1 text-info"></i> Adding Order Line...
                        </p>
                        <p v-else-if="addedOrderLine && addForm.order_id">
                            <i class="mdi mdi-check-all text-success me-3"></i> Added Order Line { <a :href="'/' + item_type + '/orders/' + addForm.order_id.id + '/view?order_line_id=' + addedOrderLine.id">{{ addedOrderLine.uid }}</a> }.
                        </p>
                        <p v-else-if="failedAddingOrderLine">
                            <i class="mdi mdi-alert-outline text-warning me-1"></i> Couldn't add Order Line.
                            <br/><small class="failed-adding-error-message">{{ failedAddingOrderLine.message }}</small>
                        </p>

                        <p v-if="isAddingManagedStorage">
                            <i class="mdi mdi-chevron-right me-1 text-info"></i> Adding Managed Storage...
                        </p>
                        <p v-else-if="addedManagedStorage">
                            <i class="mdi mdi-check-all text-success me-3"></i> Added Managed Storage { <a :href="'/' + item_type + '/managed_storages/' + addedManagedStorage.id + '/view?t=overview'">{{ addedManagedStorage.uid }}</a> }.
                        </p>
                        <p v-else-if="failedAddingManagedStorage">
                            <i class="mdi mdi-alert-outline text-warning me-1"></i> Couldn't add Managed Storage.
                            <br/><small class="failed-adding-error-message">{{ failedAddingManagedStorage.message }}</small>
                        </p>

                        <p v-if="isAddingWarehousingMovement">
                            <i class="mdi mdi-chevron-right me-1 text-info"></i> Adding Movement...
                        </p>
                        <p v-else-if="addedWarehousingMovement">
                            <i class="mdi mdi-check-all text-success me-3"></i> Added Movement { <a :href="'/' + item_type + '/sp_movements?s=WHG-' + addedWarehousingMovement.uid">WHG-{{ addedWarehousingMovement.uid }}</a> }.
                        </p>
                        <p v-else-if="couldNotAddWarehousingMovement">
                            <i class="mdi mdi-alert-outline text-warning me-1"></i> Couldn't add a movement because target storage wasn't set.
                        </p>
                        <p v-else-if="failedAddingWarehousingMovement">
                            <i class="mdi mdi-alert-outline text-warning me-1"></i> Couldn't add Movement.
                            <br/><small class="failed-adding-error-message">{{ failedAddingWarehousingMovement.message }}</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="text-right mt-2">
                <a v-if="addedOrderLine" class="btn btn-outline-success" :href="'/' + item_type + '/order_lines/' + addedOrderLine.id + '/view'"><i class="fa fa-arrow-alt-circle-down"></i> View Intake '{{ addedOrderLine.uid }}'</a>
                <a v-if="addForm.order_id" class="btn btn-outline-info" :href="'/' + item_type + '/orders/' + addForm.order_id.id + '/view'"><i class="fa fa-box-open"></i> View Order '{{ addForm.order_id.uid }}'</a>
                <a class="btn btn-outline-primary" :href="nextAction">Add Another Intake</a>
            </div>
        </div>
    </div>
    <div v-else>
        <AddNewOrder
            ref="add-new-reintake-order"
            modal_title="Add New Intake"
            :item_type="item_type"
            object_type="reintake-orders"
            :received_order_type="0"
            :received_customer="addForm.customer_id"
            @on-new-item-adding="appendOrder"
            @on-cancel-clicked="$bvModal.hide('create-reintake-orders')"
        />

        <ChangeStorageUtilisation
            ref="change-sp_storages-utilisation"
            modal_id="change-sp_storages-utilisation"
            item_type='sp_storages'
            modal_title="Change Storage Utilisation"
            @on-utilisation-change="refreshItem"
        >
        </ChangeStorageUtilisation>

        <ParkStorageItem
            ref="assign-sp_storages-location"
            modal_id="assign-sp_storages-location"
            item_type='sp_storages'
            modal_title="Park Storage"
            @on-park-storage="refreshItem"
        >
        </ParkStorageItem>

        <RemoveStorageLocation
            ref="remove-sp_storages-location"
            modal_id="remove-sp_storages-location"
            item_type='sp_storages'
            modal_title="Remove Storage"
            @on-remove-storage="refreshItem"
        >
        </RemoveStorageLocation>


        <AssignAndGetStorage
            ref="assign-and-get-sp_storages"
            modal_id="assign-and-get-sp_storages"
            item_type='sp_storages'
            modal_title="Assign Storage"
            @on-assign-to-customer="refreshItem"
        >
        </AssignAndGetStorage>

        <div class="row" v-for="(required_section, k) in required_sections" :key="k">
            <div class="col-sm-12" v-if="(required_section.section_name !== 'Custom' || required_section.required_items.custom_fields.type !== 'hidden_text')">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="row">
                        <div :class="required_section.hasOwnProperty('required_items_class') ? required_section.required_items_class : 'col-sm-12'">
                            <div class="card-body">
                                <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.required_items">
                                    <template v-slot:recent_storage="{ index, required_field }">
                                        <div class="recent-storage-item"
                                             v-if="required_field.options.length"
                                             v-for="(storage, idx) in required_field.options"
                                             :key="idx">
                                            <a href="#" @click="setSelectedStorage(storage, $event)" class="text-dark">
                                                <i v-if="storage && storage.hasOwnProperty('storage_wrapper_info') && storage.storage_wrapper_info" :class="storage.storage_wrapper_info.icon"></i> {{ storage.name }}
                                            </a>
                                        </div>
                                    </template>

                                    <template v-slot:status_checks="{ index, required_field }">
                                        <div class="row" v-if="status_checks">
                                            <div class="col-12">
                                                <ul>
                                                    <template v-if="status_checks.hasOwnProperty('account_missing')">
                                                        <li>
                                                            <i v-if="status_checks.account_missing" class="bx font-size-22 text-danger bx-x"></i>
                                                            <i v-else class="bx font-size-22 text-success bx-check"></i>
                                                            {{ status_checks.account_missing_message}}
                                                        </li>
                                                    </template>

                                                    <template v-if="status_checks.hasOwnProperty('asset_missing')">
                                                        <li v-if="status_checks.asset_missing"><i class="bx font-size-22 text-danger bx-x"></i> Asset Missing. {{ addForm.asset_uid }} is not currently part of the Inventory</li>
                                                        <li v-else><i class="bx font-size-22 text-success bx-check"></i> {{ status_checks.asset_missing_message }}</li>
                                                    </template>

                                                    <template v-if="status_checks.hasOwnProperty('storage_assigned')">
                                                        <li>
                                                            <i v-if="status_checks.storage_assigned" class="bx font-size-22 text-danger bx-x"></i>
                                                            <i v-else class="bx font-size-22 text-success bx-check"></i>
                                                            {{ status_checks.storage_assigned_message}}
                                                        </li>
                                                    </template>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-slot:asset_profile_info="{ index, required_field }">
                                        <div>
                                            <div class="st--common--box status--box">
                                                <div class="st-status-radio-box">
                                                    <div class="st-radio-row">
                                                        <div class="st-radio-col">
                                                            <div class="cm-radio-btn">
                                                                <label>
                                                                    <input type="radio" name="use_saved_intake_profile"
                                                                           :key="'_'+required_field.name"
                                                                            id="1_use_saved_intake_profile"
                                                                            @input="setFormValue('use_saved_intake_profile', 1)"

                                                                           :value="1" v-model="addForm.use_saved_intake_profile" onchange="">
                                                                    <div class="st-radio-box">
                                                                        <span></span>
                                                                        <p>Use Existing</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="st-radio-col">
                                                            <div class="cm-radio-btn">
                                                                <label>
                                                                    <input type="radio" name="use_saved_intake_profile"
                                                                           :key="'_'+required_field.name"
                                                                            id="0_use_saved_intake_profile"
                                                                            @input="setFormValue('use_saved_intake_profile', 0)"
                                                                           :value="0" v-model="addForm.use_saved_intake_profile">
                                                                    <div class="st-radio-box">
                                                                        <span></span>
                                                                        <p>Update</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div v-show="addForm.use_saved_intake_profile === 1" class="row">
                                                <div v-if="asset_info" class="col-12">
                                                    <AssetPreviewListView
                                                        :item_type="item_type"
                                                        :my_asset="asset_info" />
                                                </div>
                                            </div>
                                            <div v-show="addForm.use_saved_intake_profile === 0" class="row">
                                                <IntakeAssetIntakeFields ref="intake-asset-intake-fields" :current_page_type="item_type"/>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-slot:selected_storage="{ index, required_field }">
                                        <po-lines
                                            v-if="selected_storage_info"
                                            custom_header="Selected Storage:"
                                            custom_header_classes="col-12"
                                            :items="[selected_storage_info]"
                                            :current_page_type="item_type"
                                            object_type="sp_storages"
                                            :keys_list="sp_storage_keys_list" />
                                        <div v-else-if="isLoadingItem">
                                            Loading Storage Info...
                                        </div>
                                    </template>

                                    <template v-slot:assign_storage="{ index, required_field }">
                                        <div v-if="addForm.customer_id">
                                            <b-button @click="assignStorage($event)" class="af-btn-accent af-accent mt-2">Assign Storage to '{{ addForm.customer_id.name }}'</b-button>
                                        </div>
                                    </template>
                                </ChangeCommonFieldsAdvanced>
                            </div>
                        </div>
                        <div v-if="required_section.hasOwnProperty('second_required_items')" :class="required_section.second_required_items_class">
                            <div class="card-body">
                                <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.second_required_items"/>
                            </div>
                        </div>
                        <div v-if="required_section.hasOwnProperty('third_required_items')" :class="required_section.third_required_items_class">
                            <div class="card-body">
                                <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.third_required_items"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="$v.addForm.$anyError">
            <div class="col-12">
                <div class="card border border-warning af-warnings-block">
                    <div class="card-header bg-transparent border-warning"><h5 class="my-0 text-warning"><i class="mdi mdi-alert-outline me-3"></i> Couldn't proceed</h5></div>
                    <div class="card-body">
                        <h5 class="card-title mt-0">Please check the following:</h5>
                        <div class="card-text text-muted mt-4">
                            <p v-if="$v.addForm.asset_id.$dirty && !$v.addForm.asset_id.required"><i class="mdi mdi-chevron-right text-primary me-1"></i> Asset must not be empty.</p>
                            <p v-if="$v.addForm.customer_id.$dirty && !$v.addForm.customer_id.required"><i class="mdi mdi-chevron-right text-primary me-1"></i> Customer must not be empty.</p>
                            <p v-if="$v.addForm.order_id.$dirty && !$v.addForm.order_id.required"><i class="mdi mdi-chevron-right text-primary me-1"></i> Order must not be empty.</p>
                            <p v-if="$v.addForm.storage_id.$dirty && !$v.addForm.storage_id.required"><i class="mdi mdi-chevron-right text-primary me-1"></i> Storage must not be empty.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right mt-2">
            <b-button
                v-if="statusChecksFailed || isSendingRequest"
                disabled
                type="submit"
                variant="secondary">
                Create</b-button>
            <b-button v-else type="submit"
                      variant="success"
                      v-on:click="startAddingProcess($event)">
                Create</b-button>
            <b-button variant="outline-danger" :href="'/' + item_type + '/order_lines/move_to_storage'">Cancel</b-button>
        </div>
    </div>
</template>


<script>
import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import ChangeCommonFieldsAdvanced from "../extras/ChangeCommonFieldsAdvanced";
import SelectedSpStorageShared from "@/mixins/SelectedSpStorageShared.mixin";
import SpAddingIntakeShared from "@/mixins/SpAddingIntakeShared.mixin";

import AddNewOrder from "../extras/AddNewOrder";
import AssignAndGetStorage from "../sp_storages/AssignAndGetStorage";
import RemoveStorageLocation from "../sp_storages/RemoveStorageLocation";
import ParkStorageItem from "../sp_storages/ParkStorageItem";
import ChangeStorageUtilisation from "../sp_storages/ChangeStorageUtilisation";
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
import {IntakeProfilesShortKeysList} from "@/views/assetflow/form_data/IntakeProfilesShortKeysList";
import dropzoneMixin from "@/mixins/assetAcquisitionDropzoneMixin.mixin";
import AssetPreviewListView from "../../assets/subItems/AssetLayouts/AssetPreviewListView";
import IntakeAssetIntakeFields from "../extras/IntakeAssetIntakeFields";
import TagsManagerMixin from "@/mixins/TagsManagerMixin.mixin";

const ConditionList = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Excellent' },
    { id: 3, name: 'Good' },
    { id: 4, name: 'Attention Required' },
];

export default {
    name: "ReturnIntakeToStorage",
    props: ['item_id', 'item_type', 'action', 'other_info_string'],
    mixins: [validationMixin, dropzoneMixin, SelectedSpStorageShared, SpAddingIntakeShared, TagsManagerMixin],
    components: {
        IntakeAssetIntakeFields,
        AssetPreviewListView,
        PoLines,
        ChangeStorageUtilisation,
        ParkStorageItem,
        RemoveStorageLocation,
        AssignAndGetStorage,
        AddNewOrder,
        ChangeCommonFieldsAdvanced,
        Layout},
    validations: {
        addForm: {
            asset_id: {
                required
            },
            customer_id: {
                required
            },
            order_id: {
                required
            },
            storage_id: {
                required
            },
        }
    },

    computed:{
        statusChecksFailed() {
            return this.status_checks ? Object.values(this.status_checks).some(value => value === true) : true;
        },
        nextAction() {
            return ('/' + this.item_type + '/order_lines/move_to_storage/return_to_storage' + this.getNextActionParams());
        },
        asset_id() {
            return this.addForm.asset_id;
        },
        customer_id() {
            return this.addForm.customer_id;
        },
        order_id() {
            return this.addForm.order_id;
        },
        asset_uid() {
            return this.addForm.asset_uid;
        },
        use_saved_intake_profile() {
            return this.addForm.use_saved_intake_profile;
        },
    },
    watch:{

        use_saved_intake_profile(use_saved_intake_profile) {
            if (use_saved_intake_profile === 0 && this.asset_info){
                // shall setSelectedItem
                if (this.$refs['intake-asset-intake-fields'] && this.$refs['intake-asset-intake-fields'].length > 0){
                    this.$refs['intake-asset-intake-fields'][0].setSelectedItem(this.asset_info);
                }
            }
        },
        asset_uid(asset_uid) {
            // shall get orders of this customer id
            if (!asset_uid) { return; }
            this.$set(this, 'status_checks', null);
            this.setFormValue('use_saved_intake_profile', null);
            this.getAssetsListUsing(asset_uid);
        },
        asset_id(value) {
            this.setFormValue('customer_id', null);
            this.$set(this, 'asset_info', null);

            this.$set(this, 'status_checks', null);
            if (this.required_sections.asset_id.required_items.hasOwnProperty('status_checks')) {
                delete this.required_sections.asset_id.required_items.status_checks;
            }

            this.setFormValue('use_saved_intake_profile', null);
            if (this.required_sections.hasOwnProperty('asset_profile_info')){
                delete this.required_sections.asset_profile_info;
            }

            if (value){
                if (value.id === -99){
                    this.setFormValue('asset_id', null);
                    let params = new URLSearchParams(window.location.search);
                    if (params.has('asset_id')){
                        params.delete('asset_id');
                    }

                    let query_string = '';
                    if (params.toString().length > 0){
                        query_string = '?' + params.toString();
                    }
                    let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + query_string;

                    window.location.href = newUrl.replace('return_to_storage', 'new');
                }else{
                    let asset_info = this.addForm.asset_id;
                    this.asset_info = asset_info;

                    if (asset_info){
                        let asset_link = '#';
                        let asset_link_target = '';
                        if (this.hasPermission('browse_assets')){
                            asset_link = '/' + this.item_type + '/assets/' + asset_info.id + '/view?t=overview';
                            asset_link_target = ' target="_blank"';
                        }
                        this.required_sections.asset_id.required_items.asset_id.has_html_note = '<a href="' + asset_link + '" class="text-dark view-asset-link"' + asset_link_target + '>View Asset</a>';


                        if (asset_info.hasOwnProperty('condition') && asset_info.condition){
                            this.setFormValue('condition', ConditionList[asset_info.condition - 1]);
                        }

                        if (asset_info.hasOwnProperty('customer_info') && asset_info.customer_info){
                            let customer_info = asset_info.customer_info;
                            this.required_sections.order_details.required_items.customer_id.options = [customer_info];
                            this.setFormValue('customer_id', customer_info);
                        }

                        if (asset_info.hasOwnProperty('status_checks') && asset_info.status_checks){
                            this.addStatusChecksBlock();
                            this.status_checks = asset_info.status_checks;
                        }

                        this.setFormValue('use_saved_intake_profile', 1)
                        this.required_sections.asset_profile_info = {
                            section_name: 'Asset Profile',
                            required_items:{
                                asset_profile_info: {
                                    key: 'asset_profile_info',
                                    label: '',
                                    listLoading: true,
                                    type: 'custom_slot',
                                    slot_class: 'asset_profile_info',
                                    slot_name: 'asset_profile_info',
                                    options:[]
                                },
                            },
                        };

                    }
                }
            }
        },
        customer_id(value) {
            // shall get orders of this customer id
            this.required_sections.order_details.required_items.order_id.options = [];
            this.setFormValue('order_id', null);
            this.required_sections.movement.required_items.storage_id.options = [];
            this.setFormValue('storage_id', null);
            if (!value || !value.hasOwnProperty('id')) { return; }
            this.getSPCustomerOrders(value);
            this.getSPCustomerStorage(value);
        },
        order_id(value) {
            if (value){
                if (value.id === -99){
                    this.setFormValue('order_id', null);
                    let asset = {
                        order_type: 0,
                        status: 0
                    };
                    let customer_info = this.addForm.customer_id;
                    if (customer_info){
                        asset.customer_id = customer_info.id;
                        if (this.item_type === 'sp'){
                            asset.customer_info = customer_info;
                        }
                    }
                    this.$refs['add-new-reintake-order'].setSelectedItem(asset);
                    this.$bvModal.show('create-reintake-orders');

                }else{
                    let intake_date = this.$moment().format('DD-MM-YYYY');
                    if (value.hasOwnProperty('intake_date') && value.intake_date !== '' && value.intake_date){
                        intake_date = Vue.options.filters.moment(value.intake_date, 'DD-MM-YYYY');
                    }
                    this.setFormValue('intake_date', intake_date);

                    // if (value.hasOwnProperty('reference')){
                    //     this.setFormValue('reference', value.reference);
                    // }

                }
            }
        },
    },
    created() {
        let queryVars = this.getQueryVars();
        if ("asset_id" in queryVars && queryVars["asset_id"] !== ''){
            this.setFormValue('asset_uid', queryVars["asset_id"]);
        }

        this.other_info = JSON.parse(this.other_info_string);
        this.setFormValue('intake_date', this.$moment().format('DD-MM-YYYY'));

        this.required_sections.order_details.required_items.order_id.custom_label = function (order_info){
            return (order_info.hasOwnProperty('uid') ? order_info.uid + ': ' : '') + order_info.name;
        };
    },
    data() {
        return {
            isSendingRequest: false,
            intake_profiles_list_short_keys_list: IntakeProfilesShortKeysList,
            asset_info:null,
            asset_profile_info:null,
            status_checks:null,
            addForm:{
                filled_asset_images:[],
                asset_images:[],
                asset_id: null,
                customer_id: null,
                order_id: null,
                intake_date: null,
                storage_id: null,
            },
            required_sections:{
                asset_id: {
                    section_name: 'Asset',
                    required_items:{
                        asset_uid: {
                            key: 'asset_uid',
                            label: 'Asset UID',
                            type: 'text',
                        },
                        asset_id: {
                            key: 'asset_id',
                            label: 'Asset',
                            type: 'single-select',
                            options:[{id: -99, name: 'Add New Intake'}]
                        },
                    },
                },
                order_details: {
                    section_name: 'Order Details',
                    required_items:{
                        customer_id: {
                            key: 'customer_id',
                            label: 'Customer',
                            type: 'readonly_text',
                            options:[]
                        },
                        order_id: {
                            key: 'order_id',
                            label: 'Order',
                            type: 'single-select',
                            custom_label: null,
                            options:[]
                        },
                        intake_date: {
                            key: 'intake_date',
                            label: 'Intake Date',
                            type: 'date'
                        },
                    },
                },
                movement: {
                    section_name: 'Storage',
                    required_items:{
                        storage_id: {
                            key: 'storage_id',
                            label: 'Storage',
                            type: 'single-select',
                            options:[]
                        },
                        recent_storage: {
                            key: 'recent_storage',
                            label: 'Loading Recent Storage...',
                            listLoading: true,
                            type: 'custom_slot',
                            slot_class: 'recent-storage-block',
                            slot_name: 'recent_storage',
                            options:[]
                        },
                        selected_storage: {
                            key: 'selected_storage',
                            label: '',
                            type: 'custom_slot',
                            slot_class: 'selected-storage-block',
                            slot_name: 'selected_storage'
                        },
                        assign_storage: {
                            key: 'assign_storage',
                            label: '',
                            type: 'custom_slot',
                            slot_class: 'select-storage-block',
                            slot_name: 'assign_storage'
                        },
                    },
                },
                intake_notes: {
                    section_name: 'Intake Notes',
                    required_items_class: 'col-sm-12',
                    required_items:{
                        intake_images: {
                            key: 'asset_images',
                            label: '',
                            type: 'intake_images',
                            has_top_html_note: '<div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-info rts-image-note"> <i class="mdi mdi-alert-circle-outline me-2"></i> Existing asset images will already be available. Add an additional \'Return to Storage\' image here if required. </div>'
                        },
                        condition: {
                            key: 'condition',
                            label: 'Condition',
                            type: 'radio-select',
                            required_options_class: 'col-3',
                            options: ConditionList
                        },
                        notes: {
                            key: 'notes',
                            label: 'Notes',
                            type: 'textarea',
                            placeholder: 'Enter any intake notes about this item (cosmetic notes, observations, etc.)',
                            is_required:false
                        },
                    }
                }
            },
        }
    },
    methods:{

        performAction(item, action_name, event) {

        },
        async startAddingProcess(event){

            this.$v.addForm.$touch();
            if (this.$v.addForm.$anyError) {
                return;
            }
            let all_files = this.checkAndGetFiles(this.addForm.filled_asset_images);
            if (!all_files){ return; }


            this.isAddingItem = true;
            this.addedItem = false;

            this.addedAsset = true;
            this.addedOrderLine = null;
            this.addedManagedStorage = null;
            this.addedIntakeProfile = null;
            this.addedWarehousingMovement = null;
            this.couldNotAddWarehousingMovement = null;

            this.failedAddingAsset = null;
            this.failedAddingOrderLine = null;
            this.failedAddingManagedStorage = null;
            this.failedAddingIntakeProfile = null;
            this.failedAddingWarehousingMovement = null;
            // TODO:// should validate form here??

            let asset_info = this.addForm.asset_id;
            if (!asset_info){
                console.log("asset_info is not defined");
                return;
            }

            let asset_id = asset_info.id;

            if (!asset_id){
                console.log("(asset_id / added_intake_profile) is not defined");
                return;
            }
            // should update asset's status first, and add a new post update


            await this.updateAsset(asset_id)
                .then((updated_asset) => {
                    return this.addOrderLine(asset_id, true);
                })
                .then((added_order_line) => {
                    if (added_order_line && added_order_line.hasOwnProperty('id')){
                        let order_line_id = added_order_line.id;
                        let asset_id = added_order_line.item_id;
                        return this.addManagedStorage(asset_id, order_line_id, true, asset_info, all_files);
                    }
                }).then(managed_storage_info => {
                    if (this.selected_storage_info){
                        // should add movement AND update added_managed_storage's movement id
                        return this.addWarehousingMovement(managed_storage_info);
                    }else{
                        this.couldNotAddWarehousingMovement = true;
                    }
                }).then(added_movement => {
                    if (added_movement && added_movement.hasOwnProperty('id')){
                        let movement_id = added_movement.id;
                    }
                }).finally(() => {
                    this.isAddingItem = false;
                    this.addedItem = true;
                    if (this.addedOrderLine){
                        let message = 'Intake {' + this.addedOrderLine.uid + '} was created';
                        window.flash(message);
                        window.location.href = '/' + this.item_type + '/order_lines/' + this.addedOrderLine.id + '/view' + this.getNextActionParams();
                    }
                });
        },


        setListIsLoading(status) {
            let to_set_loading_list = {
                order_details: 'customer_id',
            }
            for (const section_name in to_set_loading_list) {
                let select_name = to_set_loading_list[section_name];
                this.required_sections[section_name].required_items[select_name].listLoading = status;
            }
        },

        setFormValue(property_name, property_value){
            this.$set(this.addForm, property_name, property_value);
        },

        addStatusChecksBlock: function () {
            if (!this.required_sections.asset_id.required_items.hasOwnProperty('status_checks')) {
                let status_checks = {
                    key: 'status_checks',
                    label: 'Status Checks',
                    listLoading: true,
                    type: 'custom_slot',
                    slot_class: 'status_checks',
                    slot_name: 'status_checks',
                    options: []
                };
                this.$set(this.required_sections.asset_id.required_items, 'status_checks', status_checks);
            }
        },
        async getAssetsListUsing(asset_uid){
            this.isSendingRequest = true;
            this.required_sections.asset_id.required_items.asset_id.listLoading = true;
            this.required_sections.asset_id.required_items.asset_id.options = [];
            this.setFormValue('asset_id', null);

            const assetResource = new Resource('api/assetflow/assets');

            var required_query = {
                page: 1,
                limit: 100,
                asset_uid: asset_uid,
                items_of_object: 'customers'
            };

            const { data } = await assetResource.list(required_query);
            let add_new_asset = {id: -99, name: 'Add New Intake'};
            this.isSendingRequest = false;
            this.addStatusChecksBlock();

            if (Object.keys(data.data).includes('items')){
                let asset_id_options = data.data.items;

                if (asset_id_options.length === 0){
                    this.status_checks = { asset_missing: true };
                }

                if (asset_id_options.length === 1){
                    this.setFormValue('asset_id', asset_id_options[0]);
                    this.required_sections.asset_id.required_items.asset_id.type = 'readonly_text';
                }else{
                    this.required_sections.asset_id.required_items.asset_id.type = 'single-select';
                    asset_id_options.splice(0, 0, add_new_asset);
                    this.required_sections.asset_id.required_items.asset_id.options = asset_id_options;
                }
            }else{
                this.required_sections.asset_id.required_items.asset_id.options = [add_new_asset];
                this.status_checks = { asset_missing: true };
            }
            this.required_sections.asset_id.required_items.asset_id.listLoading = false;

        },

        // end of methods
    }
}
</script>
