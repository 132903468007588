<template>
    <div>

        <generic-tasks-form
            ref="generic-tasks-form"
            :modal_id="'planning-generic-tasks'"
            :sub_modal_id="'planning'"
            modal_title="Complete task"
            :received_asset_info="item_info"
            @on-post-adding="postAdded"
            @on-completing-task="taskCompleted"
        />

        <add-new-event
            ref="add-new-event"
            item_type='event'
            :modal_title="'Add New Event to {' + item_info.name + '}'"
            shall_assign_to="assets"
            :shall_assign_to_id="item_info.id"
            @on-new-item-adding="eventsListChanged"
        >
        </add-new-event>

        <add-new-task
            ref="add-new-task"
            item_type='task'
            :tasks_options="item_info.event_tasks"
            :modal_title="'Add Task to {' + item_info.name + '}'"
            shall_assign_to="assets"
            :shall_assign_to_id="item_info.id"
            @on-new-item-adding="tasksListChanged"
        >
        </add-new-task>



        <!-- Assets Status  -->
        <div class="assets-section box-border p-2 p-lg-3">
            <div class="row">
                <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left col-divider">
                    <div class="assets-common ">
                        <div class="asseta-common-title mb-1">
                            <strong>Assets Status</strong>
                        </div>
                        <div class="assets-detail">
                            <span :class="'status-btn status-btn-' + item_info.status" style=" font-size: 25px!important; ">{{ item_info.status | titleize }}</span>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 col-xl-3 text-left text-md-center col-divider">
                    <div class="assets-common">
                        <div class="asseta-common-title mb-1">
                            <strong>Final Disposition Planning</strong>
                        </div>
                        <div class="assets-detail" :class="getSustainabilityClassFor(item_info)" v-html="getSustainabilityActionFor(item_info)"></div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left text-md-center col-divider">
                    <div class="assets-common">
                        <div class="asseta-common-title mb-1">
                            <strong>FDP Status</strong>
                        </div>
                        <div class="assets-detail">
                            {{ item_info.fdp_status === 1 ? 'Approved' : 'Pending' }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-2 text-left text-md-center col-divider">
                    <div class="assets-common">
                        <div class="asseta-common-title mb-1">
                            <strong>Last Updated By</strong>
                        </div>
                        <div class="assets-detail">

                            <a v-if="item_info.fdp_updated_by !== 0 && item_info.hasOwnProperty('fdp_updated_by_user')" :href="'/' + item_info.current_page_type + '/users/' + item_info.fdp_updated_by + '/view'" class="text-dark">{{ item_info.fdp_updated_by_user.name | titleize }}</a>
                            <span v-else>
                                AF Intelligence
                            </span>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-3 text-left text-md-center text-xl-right col-divider last-child">
                    <div class="assets-common">
                        <div class="asseta-common-title mb-1">
                            <strong>Date</strong>
                        </div>
                        <div v-if="item_info.fdp_updated_at" class="assets-detail">
                            {{ item_info.fdp_updated_at | moment('MMMM Do YYYY') }} - {{ item_info.fdp_updated_at | fromNow}}
                        </div>
                        <div v-else>-</div>

                    </div>
                </div>
            </div>
        </div>
        <!--  Assets Status Close -->


        <!-- Heading  -->

        <div v-if="item_info.asset_liability !== 'ended'" class="row mt-5 ">
            <div class="col-12" >
                <div class="common-title-block event-assets-heading d-block d-sm-flex justify-content-between align-items-center w-100">
                    <h2 class="mb-0">Events & Tasks</h2>
                    <a @click="open_create_event_dialog" class="add-event-link">Add Event</a>
                </div>
            </div>
        </div>
        <!-- Heading  Close-->


        <!-- Event Collapse | Expand -->
        <div v-if="shallShowEvents()" class="event-col-exp mt-4">
            <div class="row">
                <div class="col-12 col-sm-6 event-colexp--text">
                    <p><strong>Events</strong></p>
                </div>
                <div class="col-12 col-sm-6 event-colexp--col-exp text-left text-sm-right">
                    <a @click="collapseAll(false)"> <strong>Collapse All</strong> </a> | <a @click="collapseAll(true)"> <strong>Expand All</strong> </a>
                </div>
            </div>
        </div>
        <!-- Event Collapse | Expand End-->



        <!-- Tasks Section -->
        <div class="tasks-section mt-4" v-if="shallShowEvents()">
            <div class="row">
                <div class="col">
                    <div
                        v-for="asset_event in item_info.asset_events"
                        class="tasks-Seperate step_main_accordion_border section-tv_& film box-border p-2 p-sm-3 mb-3">
                        <div  class="step-small-label d-block d-sm-flex justify-content-between align-items-center">
                            <p class="date-to-date mb-0"> <span class="mr-1 round-color " :class="(asset_event.status === 1 ? 'green' : 'yellow') + '-round'"></span> {{ asset_event.name }} ({{ asset_event.start_date | moment('MMMM Do YYYY')  }} -
                                {{ asset_event.finish_date | moment('MMMM Do YYYY') }}) </p>
                            <p class="tasks-number-text mb-0" v-if="asset_event.hasOwnProperty('event_tasks')"> {{ asset_event.event_tasks.length + ' Task' + (asset_event.event_tasks.length === 1 ? '' : 's') }} </p>
                            <div class="complete-task-accordian d-flex align-items-center justify-content-between ">
                                <a href="#" class="complete-task-show mr-3">
                                    Complete Tasks
                                </a>
                                <a v-if="asset_event.event_tasks.length !== item_info.event_tasks.length" @click="open_create_task_dialog(asset_event)" class="add-task-link complete-task-show mr-3">
                                    Add Task
                                </a>
                                <div class="section-close-btn" @click="asset_event.is_shown = !asset_event.is_shown">
                                    <i class="fa fa-angle-down font-size-24" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>

                        <po-lines
                            v-show="asset_event.is_shown"
                            :items="asset_event.event_tasks"
                            :current_page_type="item_info.current_page_type"
                            object_type="tasks"
                            :keys_list="task_list_keys_list">

                            <template v-slot:action_name="{ item }">
                                <span>
                                    <a @click="completeTask(item, 'planning-generic-tasks')">{{ item.name | titleize }}</a>
                                </span>
                            </template>

                            <template v-slot:task_status="{ item }">
                                <span :class="'button_size ' + (task_status_list[item.status].class)">{{ task_status_list[item.status].name }}</span>
                                <span v-if="item.status === 0">| <a @click="completeTask(item, 'planning-generic-tasks')">Complete</a></span>
                            </template>
                        </po-lines>
                    </div>
                </div>
            </div>
        </div>
        <!-- Tasks Section close-->
    </div>
</template>

<script>
import AddNewEvent from "@/views/assetflow/customers/extras/AddNewEvent";
import AddNewTask from "@/views/assetflow/customers/extras/AddNewTask";
import PoLines from "../PoLines";
import {EventKeysList} from "@/views/assetflow/form_data/EventKeysList";
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
import AssetTasks from "@/mixins/AssetTasks.mixin";
import GenericTasksForm from "./GenericTasksForm";


export default {
    name: "SustainabilityTab",
    components: {GenericTasksForm, PoLines, AddNewEvent, AddNewTask},
    props:['item_info'],
    mixins: [AssetTabsShared, AssetTasks],
    data(){
        return {
            field_keys:'',
            event_list_keys_list: EventKeysList,
        }
    },
    created() {
    },
    mounted(){
    },
    methods:{
        shallShowEvents(){
            if (this.item_info.asset_liability === 'ended'){
                return false;
            }
            return this.item_info.hasOwnProperty('asset_events') && this.item_info.asset_events.length !== 0;
        },

        eventsListChanged(new_item, item_type) {
            if (new_item && this.item_info.hasOwnProperty('asset_events')) {
                let new_option = {id: new_item.id, name: new_item.name, status: new_item.status, start_date: new_item.start_date, finish_date: new_item.finish_date, event_tasks: [], is_shown:true};
                this.item_info.asset_events.push(new_option);
            }
        },
        tasksListChanged(new_item, event_id) {
            if (new_item) {
                location.reload();
            }
        },
        open_create_event_dialog: function () {
            this.$refs['add-new-event'].setSelectedItem(null);
            this.$bvModal.show('create-event');
        },
        open_create_task_dialog(event) {
            this.$refs['add-new-task'].setSelectedItem(null);
            let asset_info = {
                id: this.item_info.id,
                name: this.item_info.name,
            }
            this.$refs['add-new-task'].setSelectedEvent(event, asset_info);
            this.$bvModal.show('create-task');
        },
        collapseAll(is_shown){
            if (this.item_info.hasOwnProperty('asset_events')){
                this.item_info.asset_events = this.item_info.asset_events.map(events => {
                    return {...events, is_shown: is_shown};
                });
            }
        },
    }
}
</script>

