<template>
    <a v-if="tag_name==='a'"
       :key="key_prefix"
       :disabled="!is_enabled"
       v-on:click.once="first_argument !== undefined ? required_action(first_argument, $event) : required_action($event)"
       :class="item_classes + (!is_enabled ? ' disabled' : '')">
        {{ required_text }}
    </a>

    <button v-else-if="tag_name==='button'"
            :type="tag_type ? tag_type : 'button'"
            :disabled="!is_enabled"
            :key="key_prefix"
            v-on:click.once="first_argument !== undefined ? required_action(first_argument, $event) : required_action($event)"
            :class="item_classes + (!is_enabled ? ' disabled' : '')">
        <span :class="span_classes">{{ required_text }}</span>
    </button>

</template>
<script>
export default {
    name: 'AFButton',
    props:[
        'tag_name',
        'tag_type',
        'key_prefix',
        'is_enabled',
        'required_action',
        'first_argument',
        'item_classes',
        'span_classes',
        'required_text'
    ],

}
</script>
