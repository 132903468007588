import {SPStorageShortKeysList} from "@/views/assetflow/form_data/SPStorageShortKeysList";
import Resource from '@/api/resource';

// In your component file
import { EventBus } from '../events/eventBus';

export default {
    data() {
        return {
            selected_department: {
                id: -99,
                name: 'Department'
            },
            departments_list: [],
        }
    },
    mounted(){
    },
    created() {

        EventBus.$on('addedNewDepartment', this.handleAddedNewDepartment);

        this.getCurrentUserDepartments();
    },
    computed:{
    },
    watch:{
    },
    beforeDestroy() {
        EventBus.$off('addedNewDepartment', this.handleEvent);
    },
    methods: {
        handleAddedNewDepartment(new_department) {
            // Check if the department with the new_department.id already exists in the departments_list
            let departmentExists = this.departments_list.some(department => department.id === new_department.id);
            if (!departmentExists) {
                // If the department does not exist, add it to the array
                this.departments_list.push({ id: new_department.id, name: new_department.name, is_current: false })
                this.departments_list = [... this.departments_list];
            }
        },
        async getCurrentUserDepartments() {
            axios.get('/api/assetflow/user/departments')
                .then(response => {
                    // Handle successful response
                    let items_list = response.data.data.items;


                    this.departments_list = items_list.map(function (e) {
                        let is_current = (e.hasOwnProperty('pivot') && e.pivot) ? e.pivot.is_current === 1 : false;
                        return { id: e.id, name: e.name, is_current: is_current };
                    });

                    if (this.departments_list.length === 0){
                        // do nothing??
                        this.selected_department = {
                            id: null,
                            name: 'Department Not Set',
                            is_current:false
                        };
                    }else if (this.departments_list.length === 1){
                        this.selected_department = this.departments_list[0];
                    }else{
                        if (!this.addForm){
                            let all_item = {
                                id: -99,
                                name: 'All',
                                is_current:false
                            };
                            this.departments_list.splice(0, 0, all_item);
                        }

                        let current_department_id = this.current_department_id;
                        let found_item = this.departments_list.find((x) => x.is_current === true || x.id === current_department_id);
                        if (found_item){
                            this.selected_department = found_item;
                        }
                    }

                    if (this.addForm){
                        this.required_fields.department_id.options = this.departments_list;
                        if (this.selected_department){
                            if (this.selected_department.id !== -99 && this.selected_department.id !== null){
                                this.$set(this.addForm.item_details, 'department_id', this.selected_department);
                            }
                        }

                    }

                }).catch(error => {
                // Handle error
                console.error('Error fetching user departments:', error);
            });
        },
        setSelectedDepartment(department_item){
            console.log("department_item");
            console.log(department_item);

            this.selected_department = department_item;
            let that = this;
            axios.put('/api/assetflow/user/departments/select', { department_id: department_item.id })
                .then(response => {
                    let status = response.data.data.status;
                    let msg = response.data.data.msg;
                    // let error = response.data.data.error;

                    that.getList(this.selected_item);
                    this.presentToast((status ? "Success!" : "Error!"),
                        msg,
                        (status ? 'success' : 'danger'),
                        3000,
                        true,
                        true);

                })
                .catch(error => {
                    this.getList(this.selected_item);
                    // Handle error
                    console.log("error");
                    console.log(error);

                    window.flash('Error selecting department');
                    // location.reload();
                });


        },
    }
}
