<template>
    <Layout>
        <add-new-folder
            :ref="'add-new-' + object_type"
            :item_type="object_type"
            modal_title="Create New Folder"
            @on-new-item-adding="addedNewFolder"
            :selected_customer="selected_customer"
        >
        </add-new-folder>

        <div class="folders-wrapper" :id="item_type === 'customer' ? 'wrapper' : ''">
            <objects-sidebar
                v-if="item_type === 'customer'"
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="permission_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />
            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">

                    <div class="product-page-header">
                        <TopFiltersBlock
                            ref="TopFiltersBlock"
                            object_type="folders"
                            :current_title_html="getTitleFor(selected_item, 'folders')"
                            block_class="customers-warehouses-block both-customers-warehouses"
                            :required_items.sync="required_items"
                            @onSelectingTopFilter="onSelectingTopFilter"
                        />

                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Folders.."></search-box>
                            <b-dropdown v-if="permissions_list['add_' + object_type]" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item v-if="permissions_list['add_' + object_type]"
                                                 @click="addNewItem">Folder
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="current-page-filters">
                        <span v-if="is_sp_or_cross_or_admin && selected_customer && selected_customer.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Customer: <b>{{ selected_customer.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_customer')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_item && selected_item.id !== null && current_groupBy_option" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">{{ current_groupBy_option.label | titleizeToSingle }}: <b>{{ selected_item.name | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('item')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="search_keyword && search_keyword !== ''" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Keywords: <b>{{ search_keyword | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="setSearchKeyword('')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                    </div>
                    <div v-if="item_type === 'customer'" class="sidebad-toggle af-accent"></div>
                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">



                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                <div
                                    v-for="(layout, key) in supportedLayouts"
                                    :key="key"
                                    @click="changeObjectLayout(layout);"
                                    :id="'grid-list-'+layout.name.toLowerCase()"
                                    :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                </div>
                            </div>

                        </div>

                    </div>

                    <!-- Product Section Start-->
                    <LotsOfItemsAlert :perpage="perpage"/>

                    <div v-if="shall_select_customer_message" class="products text-center">
                        <i class="display-4 text-muted bx bx-info-circle mb-2"></i>
                        <h4>Please Select a Customer First...</h4>
                    </div>
                    <div v-else-if="listLoading" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching {{ specific_object_name }}...</h4>
                    </div>
                    <div v-else-if="!foldersData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No {{ specific_object_name }} found here at the moment.</h4>

                    </div>
                    <div v-else-if="foldersData && selectedLayout === 0" class="folders">
                        <div class="cm_list_view_wrapper">

                            <div class="cm_list_view af-primary cm_list_view-header">
                                <div class="cm_list_row row">
                                    <div class="col-12">{{ specific_object_name }}</div>
                                </div>
                            </div>

                            <ParentAwareObject
                                v-for="(object, objectKey) in foldersData"
                                :key="objectKey"
                                :object="object"
                                :selected_object_id="selected_object_id"
                                :item_type="item_type"
                                :object_type="object_type"
                                :object_index="objectKey"
                                :performAction="performAction"/>
                        </div>
                    </div>
                    <div v-else-if="foldersData" class="row">
                        <div v-if="selectedLayout === 1 && foldersData" class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="object_type"
                                :keys_list="folder_list_keys_list"
                                :get-line-class-using="getLineFolderClass"
                            >

                                <template v-slot:new_line="{ item, index }">
                                    <div class="tbody_item col-xs-12 col-sm-12 col-lg-12 scene_notes" v-if="item.hasOwnProperty('subfolders') && item.subfolders && item.subfolders.length > 0">
                                        <po-lines
                                            :items="item.subfolders"
                                            :current_page_type="item_type"
                                            :object_type="object_type"
                                            :keys_list="folder_list_keys_list"
                                            :custom_header="item.name + ' Sub Folders:'"
                                            custom_header_classes="col-12"
                                            :get-line-class-using="getLineFolderClass"
                                        >

                                            <template v-slot:new_line="sub_item">
                                                <div class="tbody_item col-xs-12 col-sm-12 col-lg-12 scene_notes" v-if="sub_item.item.hasOwnProperty('subfolders') && sub_item.item.subfolders && sub_item.item.subfolders.length > 0">
                                                    <po-lines
                                                        :items="sub_item.item.subfolders"
                                                        :current_page_type="item_type"
                                                        :object_type="object_type"
                                                        :keys_list="folder_list_keys_list"
                                                        :custom_header="sub_item.item.name + ' Sub Folders:'"
                                                        custom_header_classes="col-12"
                                                        :get-line-class-using="getLineFolderClass"
                                                    />
                                                </div>
                                            </template>
                                            <template v-slot:folders_actions="{ item, index }">
                                                <FolderActionsList :item="item" :index="index" :item_type="item_type" object_type="folders" :perform-action="performAction"/>
                                            </template>
                                        </po-lines>
                                    </div>
                                </template>

                                <template v-slot:folders_actions="{ item, index }">
                                    <FolderActionsList :item="item" :index="index" :item_type="item_type" object_type="folders" :perform-action="performAction"/>
                                </template>
                            </po-lines>
                        </div>
                    </div>

                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                            @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import SearchBox from "@/components/search-box";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import {FolderKeysList} from "@/views/assetflow/form_data/FolderKeysList";
import PoLines from "../../items/widgets/detailsViews/PoLines";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";

import TopFiltersMixin from "@/mixins/TopFiltersMixin.mixin";
import TopFiltersBlock from "../sp_storages/TopFiltersBlock";
import AddNewFolder from "../extras/AddNewFolder";
import FolderActionsList from "./FolderActionsList";
import ParentAwareObject from "./ParentAwareObject";
import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";

const folderResource = new Resource('api/assetflow/folders');
export default {
    name: 'FoldersList',
    props: ['item_id', 'item_type'],
    mixins: [ObjectsSidebarShared, TopFiltersMixin],
    components: {
        ObjectsSidebar,
        ParentAwareObject,
        FolderActionsList,
        AddNewFolder,
        TopFiltersBlock,
        LotsOfItemsAlert, PoLines, SearchBox, Layout},
    data() {
        return {
            folder_list_keys_list: FolderKeysList,
            total: 0,
            foldersData: [],
            listLoading: false,
            specific_object_name: 'Folders',
            request_meta: {},
            breadcrumb_items: [],
            title: "Folders",
            items: [
                {
                    text: "Folders",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_folder_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 4,
                    text: 'Created Date: New to Old',
                    name: 'created_at',
                    reversed: true
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            object_type: 'folders',
            permission_type: 'folders',
            groupBy_options: [
                {
                    id: 0,
                    label: 'Folders',
                    backend_key: 'parent_folder_id'
                },
            ],
            items_list: [
                // Folders
                [],
            ],
            loadableOptions: [
                {
                    index: 0, // index in items_list
                    object_name: 'folders'
                },
            ],
        };
    },
    mounted() {
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            let search_keyword = decodeURI(queryVars["s"]);
            this.search_keyword = search_keyword;
            this.$refs['search-box'].setInputValue(search_keyword);
            this.getList(this.selected_item);
        }
    },
    created() {
        if (this.item_type !== 'customer'){
            delete this.groupBy_options[0];
            delete this.items_list[0];
            delete this.loadableOptions[0];
        }
        this.sorting.sortOptions.splice(2,1);
        this.supportedLayouts = {
            list: {
                name: 'List',
                value: 0,
                class: 'list',
                isActive: false
            },
        };

        this.required_items = ['customers'];
        this.permissions_list['view_' + this.object_type] = false; // this.hasPermission('browse_' + this.object_type);
        this.permissions_list['add_' + this.object_type] = this.hasPermission('add_' + this.object_type);
        this.permissions_list['edit_' + this.object_type] = this.hasPermission('edit_' + this.object_type);
        this.permissions_list['delete_' + this.object_type] = this.hasPermission('delete_' + this.object_type);

        this.has_folder_actions_permissions = this.permissions_list['edit_' + this.object_type] || this.permissions_list['delete_' + this.object_type];

        if (this.has_folder_actions_permissions){
            this.folder_list_keys_list.actions = {
                key: 'actions',
                label: "Actions",
                class: "col-lg-1 po_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'folders_actions'
            }
        }


        if (this.item_type === 'sp'){
            if (this.is_tv_film_asset){
                this.folder_list_keys_list.customer_info.label = 'Production';
            }
            delete this.folder_list_keys_list.sp_info;
        }else if (this.item_type === 'customer') {
            delete this.folder_list_keys_list.customer_info;
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_folders_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.folder_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.folder_preferred_sorting;
        }

        this.getList(this.selected_item);
    },
    computed: {
        totalAssetsValue() {
            return this.foldersData ? this.foldersData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        addedNewFolder(){
            this.getList(this.selected_item);
            this.refreshSidebarLists({});
        },
        getLineFolderClass(item, line_type){
            return 'po-line parts-row folder-levels folder-level-'  + item.level;
        },
        performAction(folder, action, folderKey, e){
            if (action === 'add'){
                this.$bvModal.show('create-'+this.object_type);
                let my_item = {
                    parent_folder_id: folder.id
                }
                this.$refs['add-new-'+this.object_type].setSelectedItem(my_item);
            }else if (action === 'edit'){
                this.$bvModal.show('create-'+this.object_type);
                this.$refs['add-new-'+this.object_type].setSelectedItem(folder);
            }else if (action === ('delete'+this.object_type)){
                if (folder.id !== ''){
                    this.deleteItemByID(folder.id, folder.name, this.object_type, folder)
                        .then((response) => {
                            if (response.data.data.status){
                                this.foldersData.splice(folderKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
        searchFor(keyword, tag) {
            this.search_keyword = keyword;
            this.getList(this.selected_item);
        },
        sortItemsList() {
            return this.foldersData;
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('folder_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;
            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_folders_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            if (this.item_type !== 'customer'){
                // shall restrict!
                this.shall_select_customer_message = false;
                if (!this.selected_customer || !this.selected_customer.id){
                    this.shall_select_customer_message = true;
                    return;
                }
            }

            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };

            this.listLoading = true;

            // >>> shall be edited <<<

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if ([0, 1].includes(selected_item.id)){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
            }


            this.setTopFilterValue(selected_item);
            required_query = this.SBHandleQueryItems(required_query, selected_item);
            required_query.flat_only = 1;
            required_query.use_current_module = 0;
            required_query.s_keyword = this.search_keyword;
            required_query = this.appendTopFiltersValues(required_query);

            required_query.use_new_approach = true;
            const {data} = await folderResource.list(required_query);
            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.foldersData = data.data.items;
                // this.foldersData = this.getFolderObject(data.data.items, 1, 'folders', 'id', 'name').map(function(folder) {
                //     let cat = folder.folder;
                //     cat.name = folder.name;
                //     cat.level = folder.level;
                //     return cat;
                // });
                this.request_meta = data.meta;
                this.listLoading = false;
            }else{
                console.log('Response Error');
            }
        },
        addNewItem() {
            this.$refs['add-new-'+this.object_type].setSelectedItem(null);
            this.$bvModal.show('create-'+this.object_type);
        },
    },
}
</script>
