export default {
    data(){
        return {
            delete_after_untag: false,
            header_message: 'Untag Assets of ',
            confirm_message: 'Are you sure you want to Un-tag Assets of ',
            item_name: 'Tag'
        }
    },
    created: function () {
    },
    methods: {

        deleteItem(item_id, item_name, item_type, event, with_reload) {
            this.deleteItemByID(item_id, item_name, item_type, event)
                .then((response) => {
                    if (with_reload){
                        if (with_reload === 'flash' && response.hasOwnProperty('msg') && this.item_info && this.item_info.hasOwnProperty('current_page_type')){
                            window.flash(response.msg);
                            window.location = encodeURI("/" + this.item_info.current_page_type + "/" + item_type);
                        }
                        location.reload();
                    }else{
                        this.$emit('on-item-deleted');
                    }

                });
        },
        setDeleteAfterUntag(value){
            this.delete_after_untag = value;
        },
        untagAssets(item='', required_type='tags', item_name='tag', event){

            if (item){
                const h = this.$createElement;
                const item_name = Vue.options.filters.titleize(item.name);

                // Using HTML string
                const titleVNode = h('div', { domProps: { innerHTML: this.header_message+item_name } });
                // More complex structure

                const messageVNode = h('div', {}, [
                    h('p', {}, [
                        this.confirm_message,
                        h('strong', item_name),
                        '?',
                    ]),
                    h("b-form-checkbox", {
                            class: {
                                'custom-checkbox-af-accent': true
                            },
                            attrs: {
                                name: `delete_after_untag`,
                                value: true,
                            },
                            domProps: {
                                value: this.delete_after_untag
                            },
                            on: {
                                change: this.setDeleteAfterUntag
                            }
                        },
                        [
                            h('span', { class: ['text-danger'] } ,'Also Delete '+this.item_name+' {' + item_name + '}?')
                        ])
                ])
                this.presentConfirmBox([titleVNode], [messageVNode], 'primary', 'md')
                    .then(value => {
                        if (value){
                            axios({
                                method:'post',
                                url:'/api/assetflow/tags/unattach_assets',
                                baseURL: '/',
                                data:{
                                    item_id: item.id,
                                    item_type: required_type,
                                    delete_after_untag: this.delete_after_untag
                                }
                            }).then(response => {
                                let status = response.data.saved;
                                let msg = response.data.msg;

                                this.presentToast((status ? "Success!" : "Error!"),
                                    msg,
                                    (status ? 'success' : 'danger'),
                                    3000,
                                    true,
                                    true);

                                if (required_type === 'tags'){
                                    this.getTagsList();
                                    this.delete_after_untag = false;
                                }else if (['addresses', 'address_locations'].includes(required_type)){
                                    this.refreshList(item.id, required_type);
                                    this.delete_after_untag = false;
                                }

                            })
                        }
                    }).catch((error) => {
                    reject(error);
                });
            }
        },
        add_new_item(item_type='folder',parent_id = 0, name='', desc='', success_message, with_reload = false, item_id='') {
            axios({
                method:'post',
                url:'/api/assetflow/'+item_type+'/add_new_item',
                baseURL: '/',
                data: {
                    item_id: item_id,
                    item_type: item_type,
                    name: name,
                    desc: desc,
                    parent_id: parent_id
                },
            }).then(response => {
                this.handleResponse(response, success_message, with_reload);
            }).catch(error => {
                console.log(error);
            });
        },
        move_content_of(item_type, item_id, destination_id, success_message, with_reload = false) {
            axios({
                method:'post',
                url:'/api/assetflow/'+item_type+'/move_content',
                baseURL: '/',
                data: {
                    item_type: item_type,
                    item_id: item_id,
                    destination_id: destination_id
                },
            }).then(response => {
                this.handleResponse(response, success_message, with_reload);
            }).catch(error => {
                console.log(error);
            });
        },

        getClassFor(required_field){
            let options_count = required_field.options.length;

            if (options_count < 4){
                return 'col-' + (12/options_count);
            }
            return 'col-3';
        },
        getPostTextOf(my_asset) {
            let user = my_asset.hasOwnProperty('latest_post_update') && my_asset.latest_post_update ? my_asset.latest_post_update.user : null;
            if (user) {
                let image_tag = '';
                if (user.hasOwnProperty('avatar_url') && user.avatar_url) {
                    image_tag = '<img src="' + user.avatar_url + '" alt="' + user.name + '" class="rounded-circle item-post-image" width="20" height="20">';
                }

                let post_title = this.getItemPostTitle(my_asset, my_asset.latest_post_update);
                return image_tag + " <a class=\"text-dark\" href=\"/" + this.item_type + "/users/" + user.id + "/view\">" + user.name + "</a> " + post_title + " on " + Vue.options.filters.moment(my_asset.latest_post_update.created_at, 'DD/MM/YYYY');
            }
            return '';
        },
        set_item_value(item_type, item_id, key, value, success_message, with_reload = false, is_meta= false) {
            axios({
                method:'post',
                url:'/api/assetflow/'+item_type+'/save_item_value',
                baseURL: '/',
                data: {
                    item_type: item_type,
                    item_id: item_id,
                    key: key,
                    value: value,
                    is_meta: is_meta
                },
            }).then(response => {
                this.handleResponse(response, success_message, with_reload);
            }).catch(error => {
                console.log(error);
            });
        },
        mark_task_as_done(asset_id, event_id, task_id) {
            axios({
                method:'post',
                url:'/api/assetflow/assets/mark_task_as_done',
                baseURL: '/',
                data: {
                    asset_id: asset_id,
                    task_id: task_id,
                    event_id: event_id
                },
            }).then(response => {
                // this.handleResponse(response);
            }).catch(error => {
                console.log(error);
            });
        }
    }
}
