export const BulkUpdateKeysList = {
    uid: {
        key: 'uid',
        label: "ID",
        class: "col-lg-1 bu_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    created_at: {
        key: 'created_at',
        label: "Date",
        class: "col-lg-1 e_created_at",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return Vue.options.filters.moment(item[key], 'MMMM Do YYYY');
        }
    },
    user_info: {
        key: 'user_info',
        label: "User",
        class: "col-lg-2 bu_user_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let user_info = item.user_info;
            if (user_info){
                return user_info.name;
            }
            return '-';
        }
    },
    entity_info: {
        key: 'entity_info',
        label: "Entity",
        class: "col-lg-1 bu_entity_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let entity_info = item.entity_info;
            if (entity_info){
                return entity_info.name;
            }
            return '-';
        }
    },
    module_info: {
        key: 'module_info',
        label: "Module",
        class: "col-lg-1 bu_module_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let module_info = item.module_info;
            if (module_info){
                return module_info.name;
            }
            return '-';
        }
    },
    item_type: {
        key: 'item_type',
        label: "Object",
        class: "col-lg-1 bu_item_type",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return Vue.options.filters.titleizeToSingle(item.item_type);
        }
    },
    action_name: {
        key: 'action_name',
        label: "Action",
        class: "col-lg-1 bu_action_name",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return Vue.options.filters.titleize(item.action_name);
        }
    },
    total_items: {
        key: 'total_items',
        label: "Total",
        class: "col-lg-1 bu_total_items",
        show_on_mobile: false,
        case: ''
    },
    successful_items: {
        key: 'successful_items',
        label: "Successful",
        class: "col-lg-1 bu_successful_items",
        show_on_mobile: false,
        case: ''
    },
    failed_items: {
        key: 'failed_items',
        label: "Failed",
        class: "col-lg-1 bu_failed_items",
        show_on_mobile: false,
        case: ''
    },
    status: {
        key: 'status',
        label: "Status",
        class: "col-lg-1 bu_status",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let status_class = '';
            let status_text = Vue.options.filters.titleize(item.status);
            switch (item.status){
                case 'pending':
                    status_class = 'background-orange';
                    break;
                case 'completed':
                    status_class = 'background-green';
                    break;
                case 'partial':
                    status_class = 'background-green';
                    break;
            }

            return '<span class="status-btn status-'+status_class+'">'+status_text+'</span>';
        }
    },
};
