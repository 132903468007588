<template>
    <div class="cm_list_view" :class="row_class" :style="(is_actions_bar_opened && (!selected_ids.includes(order_line.id) && should_hide_unselected)) ? 'display:none;' : ''">

        <SelectableItemActions
            :add-to-selected-list="addToSelectedList"
            :item="order_line"
            :bulk_actions_customer_id="bulk_actions_customer_id"
            :is_actions_bar_opened="is_actions_bar_opened"
            :preform-bulk-action="preformBulkAction"
            :selected_ids="selected_ids"/>


        <div class="row cm_line_row mb-2" v-if="!is_order" :class="order_line.status === 4 ? 'row-line-through' : ''">
            <template v-for="(key_info, key) in keys_list">
                <PresentPoLineKeyInfo
                    :current_page_type="item_type"
                    :index="index"
                    :item="order_line"
                    :used_key="key"
                    :key_info="key_info"
                    object_type="order_lines"
                    :perform-action="performAction"
                    :permission_name="permission_name"
                    :permissions_list="permissions_list">

                    <template v-if="key_info.case === 'custom_slot'" v-slot:[key_info.slot_name]="{ item, index }">
                        <slot :name="key_info.slot_name" v-bind:index="index"
                              v-bind:item="item"></slot>
                    </template>
                </PresentPoLineKeyInfo>
            </template>
        </div>
        <div class="cm_list_row row">
            <div class="col-md-12 col-xl-2">
                <MyBCarouselImages parent_class="cm_list_view_image" :my_asset="order_line" :show-captiom-img="showCaptiomImg" :required_image_size="'item_details'"/>
                <div class="common_col_bg_box" v-if="item_info && item_info.hasOwnProperty('files_info') && item_info.files_info">
                    <ManagedStorageFiles
                        :ms_id="managed_storage_info ? managed_storage_info.id : order_line.id"
                        :object_type="managed_storage_info ? 'managed_storages' : 'order_lines'"
                        :item_info="item_info"
                        :item_type="item_type"
                        :perform-action="performAction"
                    />
                </div>

                <div class="common_col_bg_box" v-if="item_info && item_info.hasOwnProperty('asset_meta') && item_info.asset_meta && item_info.asset_meta.hasOwnProperty('asset_links') && item_info.asset_meta.asset_links">
                    <div class="row">
                        <div
                            v-for="(custom_field, key) in item_info.asset_meta.asset_links"
                            class="col-12 col-md-12" :class="customFieldsClass('asset_links')">
                            <div class="cm_usage">
                                <span>URL #{{ key + 1}}</span>
                                <p v-if="custom_field.field_value">
                                    <i class="fa fa-link"></i> <span class="cm_list_inner_common_value"><a class="text-dark" :href="custom_field.field_value" target="_blank">{{ custom_field.field_name || ('URL #' + (key + 1)) }}</a></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-10">
                <div class="row cm_line_row">
                    <div class="col-md-6 col-xl-6">
                        <div class="cm_list_title_content">
                            <div class="cm_list_view_title">
                                <h3>
                                    <a v-if="managed_storage_info" class="text-dark ms-name-block"
                                       target="_blank"
                                       :href="managed_storage_info.hasOwnProperty('id') ? ('/' + item_type + '/managed_storages/' + managed_storage_info.id + '/view') : '#'" >
                                        <span class="ms-name-span">{{ item_info.name }}</span> <span class="ms-uid-span">{{ managed_storage_info.uid }}</span> <span class="ms-view-span">view</span>
                                    </a>
                                    <a v-else-if="item_info && order_line.item_type === 0" class="text-dark ms-name-block"
                                       target="_blank"
                                       :href="'/' + item_type + '/assets/' + item_info.id + '/view'" >
                                        <span class="ms-name-span">{{ item_info.name }}</span> <span class="ms-uid-span">{{ item_info.uid }}</span> <span class="ms-view-span">view</span>
                                    </a>
                                    <a v-else class="text-dark" :href="'/' + item_type + '/order_lines/' + order_line.id + '/view'"><i class="bx bx-user-circle"></i> {{ order_line.uid }}</a>
                                </h3>
                            </div>
                        </div>
                        <div class="common_col_desc ms-item-description" v-if="item_info && item_info.hasOwnProperty('description') && item_info.description">
                            <p>{{ item_info.description }}</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-6">
                        <div class="cm_status_title_content">
                            <div class="cm_list_view_title" v-html="getMSStatus(order_line)"></div>
                            <div class="cm_status_ul">
                                <ul>
                                    <li>
                                        <p>QTY <span class="total_box_bg">{{ order_line.total_quantity }}</span></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row cm_list_view_row" :class="order_line.status === 4 ? 'row-line-through' : ''">
                    <div class="col-md-6 col-xl-6">
                        <div class="common_col_bg_box">
                            <ManagedStorageParents :item_info="item_info" :item_type="item_type"/>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Colour</span>
                                        <p>
                                            <span v-html="getColourHTML(item_info)"></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Weight</span>
                                        <p>
                                            <span
                                                v-if="item_info.hasOwnProperty('weight') && item_info.weight">
                                                {{ item_info.weight }}kg
                                            </span>
                                            <span v-else>0kg</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="cm_usage">
                                        <span>Height</span>
                                        <p>
                                            <span
                                                v-if="item_info.hasOwnProperty('height') && item_info.height">
                                                {{ item_info.height }}{{ item_info.preferred_unit }}
                                            </span>
                                            <span v-else>0m</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage">
                                        <span>Width</span>
                                        <p>
                                            <span
                                                v-if="item_info.hasOwnProperty('width') && item_info.width">
                                                {{ item_info.width }}{{ item_info.preferred_unit }}
                                            </span>
                                            <span v-else>0m</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage">
                                        <span>Depth</span>
                                        <p>
                                            <span
                                                v-if="item_info.hasOwnProperty('depth') && item_info.depth">
                                                {{ item_info.depth }}{{ item_info.preferred_unit }}
                                            </span>
                                            <span v-else>0m</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="cm_usage">
                                        <span>Area/Volume</span>
                                        <p>
                                            <span
                                                v-if="item_info.hasOwnProperty('total_area') && item_info.total_area">
                                                {{ (item_info.area * order_line.total_quantity).toFixed(2) }}m<sup>2</sup>
                                            </span>
                                            <span v-else>0m<sup>2</sup></span>
                                            <span
                                                v-if="item_info.hasOwnProperty('total_volume') && item_info.total_volume">
                                                {{ (item_info.volume * order_line.total_quantity).toFixed(2) }}m<sup>3</sup>
                                            </span>
                                            <span v-else>0m<sup>3</sup></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Waste Stream</span>
                                        <p><b>{{ item_info.waste_stream_type === 0 ? 'Product' : 'Material' }}</b></p>
                                        <p>
                                            <span v-if="item_info.waste_stream_type === 0 && item_info.hasOwnProperty('waste_stream_info') && item_info.waste_stream_info">{{ item_info.waste_stream_info.name }}</span>
                                            <span v-else-if="item_info.waste_stream_type === 1 && item_info.hasOwnProperty('composition_info') && item_info.composition_info">
                                                {{item_info.composition_info.map(item => item.name.charAt(0).toUpperCase() + item.name.slice(1)).join(', ') }}
                                            </span>
                                            <span v-else></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Current Condition</span>
                                        <p>
                                            <span
                                                v-if="order_line.hasOwnProperty('condition')">
                                                {{ getConditionText(order_line) }}
                                            </span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="tag-wrap list-show" v-if="item_info && item_info.hasOwnProperty('tags_info') && item_info.tags_info">
                            <ul v-if="item_info && item_info.hasOwnProperty('tags_info')">
                                <li v-for="tag in item_info.tags_info">
                                    <a v-if="tag.hasOwnProperty('id')" :href="'/' + item_type + '/tags/' + tag.id + '/view'">{{ tag.name }}</a>
                                </li>
                            </ul>
                            <tags-manager v-if="received_all_tags_list"
                                          class="mt-2"
                                          ref="tags-manager1"
                                          :product="item_info"
                                          :all_tags_list="received_all_tags_list"
                                          :asset_key="index"
                                          @on-add-tag="myAddTag"
                                          @on-remove-tag="myRemoveTag"
                            ></tags-manager>
                            <div v-else key="1" class="mt-2">
                                <span class="loading_tags"><i class="fas fa-tag"></i> Loading Tags...</span>
                            </div>
                        </div>


                        <div class="common_col_bg_box" v-if="item_info && item_info.hasOwnProperty('asset_meta') && item_info.asset_meta && item_info.asset_meta.hasOwnProperty('custom_fields') && item_info.asset_meta.custom_fields && item_info.asset_meta.custom_fields.length > 0">
                            <div class="row">
                                <div
                                    v-for="(custom_field, key) in item_info.asset_meta.custom_fields"
                                    class="col-6 col-md-3" :class="customFieldsClass()">
                                    <div class="cm_usage">
                                        <span>{{ custom_field }}</span>
                                        <p>
                                            <i class=""></i> <span class="cm_list_inner_common_value">{{ item_info.asset_meta.hasOwnProperty(custom_field) && item_info.asset_meta[custom_field] ? item_info.asset_meta[custom_field] : '-' }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-6">

                        <div class="common_col_bg_box" v-if="is_order">
                            <div class="row service-type-block">
                                <div class="col-md-6 col-xl-6">
                                    <div class="cm_list_view_title">
                                        <span v-if="service_type_info"><i :class="service_type_info.icon"></i> {{ service_type_info.name }}</span>
                                        <span v-else><i class="fas fa-question-circle status-grey"></i> Waiting</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-6">
                                    <span v-if="order_line_date"><i class="far fa-clock"></i> {{ order_line_date | fromNow }}</span>
                                    <span v-else><i class="far fa-clock"></i> -</span>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">

                                <div class="col-md-4" v-if="['sp', 'assetflow'].includes(item_type)">
                                    <div class="cm_usage folders_list">
                                        <span v-iconize="'customers'">{{ is_tv_film_asset ? 'Production' : 'Customer'}}</span>
                                        <p
                                            v-if="order_line.hasOwnProperty('customer_info') && order_line.customer_info">
                                            <a class="text-dark" :href="'/' + item_type + '/customers/?s=' + order_line.customer_info.name">{{ order_line.customer_info.name }}</a>
                                        </p>
                                        <p v-else><span>-</span></p>
                                    </div>
                                </div>
                                <div v-else class="col-md-4">
                                    <div class="cm_usage folders_list">
                                        <span v-iconize="'service_providers'">Service Provider</span>
                                        <p
                                            v-if="order_line.hasOwnProperty('sp_info') && order_line.sp_info">
                                            <a class="text-dark" :href="'/' + item_type + '/service_providers/?s=' + order_line.sp_info.name">{{ order_line.sp_info.name }}</a>
                                        </p>
                                        <p v-else><span>-</span></p>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="cm_usage">
                                        <span v-iconize="'orders'">{{ order_line.order_type === 1 ? 'Storage Order' : 'MTS Order' }}</span>
                                        <p v-if="order_line && order_line.hasOwnProperty('order_info') && order_line.order_info">
                                            <a class="text-dark" :href="'/' + item_type + '/orders/' + order_line.order_info.id + '/view'">
                                                <i v-if="order_line.hasOwnProperty('status')"
                                                   class="font-size-10 fa fa-circle intake-status-circle"
                                                   :class="order_line.order_info.status === 0 ? 'status-grey' : (order_line.order_info.status === 1 ? 'status-brown' : (order_line.order_info.status === 2 ? 'status-orange' : (order_line.order_info.status === 3 ? 'status-green' : ((order_line.order_info.status === 4 ? 'status-red' : 'status-black')))))"></i>
                                                {{ order_line.order_info.uid }}
                                            </a>
                                        </p>
                                        <p v-else>-</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage">
                                        <span v-if="service_info"><i :class="service_type_info.icon"></i> {{ service_info.name }}</span>
                                        <span v-else>{{ order_line.item_type === 0 ? 'Intake' : 'Task'}}</span>
                                        <p>
                                            <a class="text-dark" :href="'/' + item_type + '/order_lines/' + order_line.id + '/view'">
                                                <i v-if="order_line.hasOwnProperty('status')"
                                                   class="font-size-10 fa fa-circle intake-status-circle"
                                                   :class="order_line.status === 0 ? 'status-orange' : (order_line.status === 1 ? 'status-grey' : (order_line.status === 2 ? 'status-brightgreen' : (order_line.status === 3 ? 'status-green' : 'status-red')))"></i>
                                                {{ order_line.uid }}
                                            </a>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>MS ID</span>
                                        <p>
                                            <span v-if="managed_storage_info">{{ managed_storage_info.uid }}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>{{order_line.order_type === 0 ? 'Intake' : 'Ordered'}} By</span>
                                        <p>
                                            <span v-if="order_line.user_info">
                                                <a class="text-dark"
                                                   :href="'/' + item_type + '/users/' + order_line.user_info.id + '/view'">{{ order_line.user_info.name }}</a>
                                            </span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage">
                                        <span>{{order_line.order_type === 0 ? 'Intake In' : 'Ordered Date'}}</span>
                                        <p>
                                            <span v-if="order_line.hasOwnProperty('intake_date') && order_line.intake_date">
                                                {{ order_line.intake_date | moment('DD-MM-YYYY') }}
                                            </span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="common_col_bg_box" v-if="order_line.order_type === 0">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="cm_usage">
                                        <span>Intake Notes</span>
                                        <p v-if="order_line.notes && order_line.notes !== ''">
                                            <span>{{ order_line.notes }}</span>
                                        </p>
                                        <p v-else>-</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage">
                                        <span>Condition</span>
                                        <p>
                                            <span
                                                v-if="item_info.hasOwnProperty('condition')">
                                                {{ getConditionText(item_info) }}
                                            </span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_bg_box" v-if="['sp', 'assetflow'].includes(item_type)">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage categories_list">
                                        <span v-iconize="'sp_storages'">Storage</span>
                                        <div v-html="getSpStorageInfoHTML(order_line)"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage categories_list">
                                        <span v-iconize="'sp_locations'">Location</span>
                                        <div v-html="getLocationHTML(order_line)"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="common_col_bg_box" v-else-if="[1, 2].includes(managed_storage_info.status)">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="cm_usage">
                                        <span v-iconize="'sp_locations'">Location</span>
                                        <p>
                                            <span v-if="order_line.hasOwnProperty('current_warehouse_address_info') && order_line.current_warehouse_address_info">{{ order_line.current_warehouse_address_info.name }}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <template v-if="['sp', 'assetflow'].includes(item_type) && order_line.status !== 4 && order_line.order_type === 1">
                            <div class="common_col_bg_box">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <p class="task-action">
                                            <i class="fa fa-arrow-right"></i> <a class="text-dark">Movement Required</a>
                                        </p>
                                    </div>
                                    <div class="col-md-4">
                                    </div>
                                    <div class="col-lg-4"
                                         v-if="order_line.hasOwnProperty('order_info') && order_line.order_info">
                                        <p class="object-title-detail">
                                            <span class="item-header">
                                                <a class="text-dark" :href="'/' + item_type + '/orders/' + order_line.order_info.id + '/view'">{{ order_line.order_type === 0 ? 'SOURCE' : 'TARGET' }}</a>
                                            </span>
                                        </p>
                                        <p class="object-title-list">
                                            <i class="fa fa-shopping-cart"></i>
                                            <span class="item-header">
                                                <a
                                                    :title="order_line.order_info.name" v-b-tooltip.bottom
                                                    class="text-dark"
                                                    :href="'/' + item_type + '/orders/' + order_line.order_info.id + '/view'" >{{ order_line.order_info.uid }}</a>
                                            </span>
                                        </p>
                                    </div>
                                    <div class="col-lg-4" v-else>
                                        <p class="object-title-detail">
                                            <span class="item-header">TARGET</span>
                                        </p>
                                        <p class="task-action">-</p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="order_line.status !== 4 && managed_storage_info && order_line.order_type === 0">
                            <div v-if="(managed_storage_info.status === 2 && hasPermission('can_add_storage_order'))" class="common_col_bg_box">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="cm_list_inner_common">
                                            <span>In Storage Ordering:</span>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="managed_storage_info.available_quantity === 0" class="common_col_desc no-available-stock">
                                    <p>No Available Stock</p>
                                </div>
                                <div v-else class="cm_list_inner_common">
                                    <span></span>
                                    <p>
                                        <i class="bx bx-detail"></i>
                                        <span><button @click="performAction(order_line, 'addToOrder', index, $event)" class="btn btn-link">Add To Order</button></span>
                                    </p>
                                </div>
                            </div>
                        </template>


                        <div class="common_col_bg_box" v-if="item_type === 'sp' && order_line.hasOwnProperty('order_info') && (( [0, 1].includes(order_line.status)) || order_line.status === 3)">
                            <div class="row">
                                <div class="col-lg-12">
                                    <OrderLinesTaskActions
                                        :index="index"
                                        :order_line="order_line"
                                        :perform-action="performAction"
                                        :permission_name="permission_name"
                                        :permissions_list="permissions_list"
                                        :use_custom_slot="true"
                                        :with_remove="true"
                                    >
                                        <template v-slot:order_line_custom_actions="{ order_line, index }">

                                            <div v-if="managed_storage_info && order_line.order_type === 0 && managed_storage_info.status === 0" class="mt-1">
                                                <a :href="'/' + item_type + '/orders/'+order_line.order_id+'/view'" class="btn af-btn-accent af-accent btn-sm btn-block"><i class="fas fa-check"></i> View Order</a>
                                            </div>
                                            <div v-if="permissions_list['process_' + permission_name]" class="mt-1">
                                                <button
                                                    v-if="order_line.order_type === 0 && order_line.order_info.status === 1 && [0, 1].includes(order_line.status)"
                                                    class="btn af-btn-accent af-accent btn-sm btn-block"
                                                    @click="performAction(order_line, 'process-order_line', index)"><i class="fas fa-check"></i> Approve Intake</button>
                                                <button v-else-if="order_line.order_type === 0 && order_line.status === 3" :disabled="true" class="btn af-btn-accent af-accent btn-sm btn-block">Approved</button>


                                                <button
                                                    v-if="order_line.order_type === 1 && [0, 1].includes(order_line.status)"
                                                    class="btn af-btn-accent af-accent btn-sm btn-block"
                                                    @click="performAction(order_line, 'process-order_line', index)"><i class="fas fa-check"></i> Complete Task</button>
                                                <button v-else-if="order_line.order_type === 1 && order_line.status === 3" :disabled="true" class="btn af-btn-accent af-accent btn-sm btn-block">Completed</button>

                                                <a v-if="item_info && order_line.order_type === 1 && order_line.status === 3" :href="'/' + item_type + '/order_lines/move_to_storage/return_to_storage?asset_id='+item_info.uid" class="btn af-btn-accent btn-sm btn-block af-accent mt-1 return-to-storage-ol-btn">Return to Storage</a>


                                            </div>
                                        </template>
                                    </OrderLinesTaskActions>
                                </div>
                            </div>
                        </div>

                        <div class="cm_list_last_update mt-1" v-if="order_line.hasOwnProperty('latest_post_update')">
                            <p v-html="getPostText(order_line)"></p>
                        </div>

                    </div>
                </div>

                <OrderLineActionsBlock
                    v-if="order_line.status !== 4"
                    :index="index"
                    :order_line="order_line"
                    :item_type="item_type"
                    :performAction="performAction"
                    :performMSAction="performMSAction"
                    :without_actions="without_actions"
                />

            </div>
        </div>

        <div class="cm_list_view_btn">
            <a :href="'/' + item_type + '/order_lines/' + order_line.id + '/view'"><i class="bx bx-chevron-right"></i></a>
        </div>
    </div>
</template>
<script>
import MyBCarouselImages from "../../assets/subItems/AssetLayouts/MyBCarouselImages";
import HandleOrderLineActionsAndSlots from "../../customers/order_lines/HandleOrderLineActionsAndSlots";
import OrderLineActionsBlock from "./OrderLineActionsBlock";
import PresentPoLineKeyInfo from "../widgets/detailsViews/PresentPoLineKeyInfo";
import OrderLinesTaskActions from "../../customers/order_lines/OrderLinesTaskActions";
import TagsManager from "../../assets/subItems/TagsManager";
import SelectableItemActions from "../../customers/managed_storages/SelectableItemActions";
import ManagedStorageFiles from "../../customers/managed_storages/ManagedStorageFiles";
import ManagedStorageParents from "../../customers/managed_storages/ManagedStorageParents";

export default {
    name: 'OrderLineListView',
    components: {
        ManagedStorageParents,
        ManagedStorageFiles,
        SelectableItemActions,
        TagsManager,
        OrderLinesTaskActions,
        PresentPoLineKeyInfo,
        OrderLineActionsBlock,
        HandleOrderLineActionsAndSlots, MyBCarouselImages},
    props:[
        'index',
        'order_line',
        'item_type',
        'keys_list',
        'showCaptiomImg',
        'row_class',
        'getConditionText',
        'getColourHTML',
        'performAction',
        'permission_name',
        'permissions_list',
        'getCustomSlotNames',
        'getPostText',
        'getSpStorageInfoHTML',
        'getLocationHTML',
        'performMSAction',
        'getMSStatus',
        'received_all_tags_list',
        'myAddTag',
        'myRemoveTag',

        'is_actions_bar_opened',
        'is_selecting_items',
        'selected_ids',
        'addToSelectedList',
        'should_hide_unselected',
        'preformBulkAction',
        'bulk_actions_customer_id',
        'without_actions',
        'is_order'
    ],
    computed:{
        order_line_date(){
            if (this.order_line.status === 0){
                return this.order_line.hasOwnProperty('created_at') && this.order_line.created_at ? this.order_line.created_at : null;
            }else if (this.order_line.status === 1){
                return this.order_line.hasOwnProperty('service_target_date') && this.order_line.service_target_date ? this.order_line.service_target_date : null;
            }else if ([2, 3].includes(this.order_line.status)){
                return this.order_line.hasOwnProperty('completed_date') && this.order_line.completed_date ? this.order_line.completed_date : null;
            }else if (this.order_line.status === 4){
                return this.order_line.hasOwnProperty('removed_date') && this.order_line.removed_date ? this.order_line.removed_date : null;
            }else{
                return null;
            }
        },
        managed_storage_info(){
            return this.order_line.hasOwnProperty('managed_storage_info') && this.order_line.managed_storage_info ? this.order_line.managed_storage_info : null;
        },
        item_info(){
            return this.order_line.hasOwnProperty('item_info') && this.order_line.item_info ? this.order_line.item_info : null;
        },
        service_info(){
            return this.order_line.hasOwnProperty('service_info') && this.order_line.service_info ? this.order_line.service_info : null;
        },
        service_type_info(){
            return this.order_line.hasOwnProperty('service_type_info') && this.order_line.service_type_info ? this.order_line.service_type_info : null;
        }
    },
    methods:{

    }
}
</script>
