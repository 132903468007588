export default {
    data(){
        return {
            sustainability_actions:[
                {
                    label: 'Not Planned',
                    class: 'not_planned',
                },
                {
                    label: 'Store',
                    class: 'store',
                },
                {
                    label: 'Sell',
                    class: 'sell',
                },
                {
                    label: 'Transfer Internally',
                    class: 'transfer_internally',
                },
                {
                    label: 'Return to Supplier',
                    class: 'return_to_supplier',
                },
                {
                    label: 'Destroy',
                    class: 'destroy',
                },
                {
                    label: 'Consume \ Use',
                    class: 'consume_use',
                },
                {
                    label: 'Donate',
                    class: 'donate',
                },
                {
                    label: 'Recycle',
                    class: 'recycle',
                },
                {
                    label: 'Gift',
                    class: 'gift',
                },
                {
                    label: 'User Defined',
                    class: 'user_defined',
                }
            ],
            acquisition_types:[
                {id: 1, name: 'Purchase'},
                {id: 2, name: 'Rental'},
                {id: 3, name: 'No Cost'},
                {id: 4, name: 'Internal Transfer'}
            ]
        }
    },
    created: function () {
    },
    methods: {
        getValueOrDefault(item, key, default_value){
            return item && item.hasOwnProperty(key) ? item[key] : default_value;
        },
        getSustainabilityClassFor(item_info, with_action_status=true){

            if (item_info.final_disposition_planning){
                let action_class = '';
                if (item_info.hasOwnProperty('fdp_type_info')){
                    action_class = 'sustainability-user-defined-action';
                }else{
                    action_class = 'sustainability-'+this.sustainability_actions[item_info.final_disposition_planning].class+'-action';
                }
                return (with_action_status ? 'action-populated' : '') + action_class;

            }else{
                return (with_action_status ? 'action-missing' : '') +'sustainability-not_planned-action';
            }

        },
        getSustainabilityActionFor(item_info, text_only = false){
            let html = '<span></span>';
            let text = '';
            if (item_info.hasOwnProperty('final_disposition_planning')){
                if (item_info.hasOwnProperty('fdp_type_info') && item_info.fdp_type_info){
                    html += '<span>' + item_info.fdp_type_info.planning_name + '</span>';
                    text += item_info.fdp_type_info.planning_name;
                }else{
                    let sustainability_action = this.sustainability_actions[item_info.final_disposition_planning > 9 ? 10 : item_info.final_disposition_planning];
                    if (sustainability_action){
                        html += '<span>' + sustainability_action.label + '</span>';
                        text += sustainability_action.label;
                    }
                }

            }else{
                html += '<span><i class="bx font-size-22 text-danger bx-x"></i></span class="missing">';
                text += '-';
            }
            return text_only ? text : html;
        },
    }
}
