import Vue from 'vue'
// Use it to import all Vue file containing this folder as Components
// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key =>Vue.component(key.split('/').pop().split('.')[0], files(key).default))



// to reduce app.js file size
// should use () => import('') instead of required('').default



Vue.component('not_found', require('./utility/404').default);
Vue.component('assetflow-files-file-import-export', require('./assetflow/files/FileImport').default);

Vue.component('home', require('./Dashboard').default);
Vue.component('af_dashboard', require('./Dashboard').default);

Vue.component("login", require("./account/login-2").default);
Vue.component("login-carousel", require("./account/LoginCarousel").default);
Vue.component("register", require("./account/register").default);

Vue.component("assetflow-icons-boxicons", require("./assetflow/customers/icons/boxicons").default);
Vue.component("assetflow-icons-dripicons", require("./assetflow/customers/icons/dripicons").default);
Vue.component("assetflow-icons-materialdesign", require("./assetflow/customers/icons/materialdesign").default);
Vue.component("assetflow-icons-fontawesome", require("./assetflow/customers/icons/fontawesome").default);

Vue.component("forgot-password", require("./account/forgot-password").default);
Vue.component("reset-password", require("./account/reset-password").default);

Vue.component("quick_actions", require("./assetflow/AssetMenu/AddAssetMenu").default);

Vue.component("viewItem", require("./assetflow/items/View").default);
Vue.component("customer-changes-scene", require("./assetflow/items/View").default);
Vue.component("customer-overview", require("./assetflow/customers/Overview").default);
Vue.component("assetflow-customers-assets_dashboard", require("./assetflow/customers/Dashboards/Dashboard").default);
Vue.component("assetflow-customers-sto_dashboard", require("./assetflow/customers/Dashboards/StoDashboard").default);

Vue.component("assetflow-addresses", require("./assetflow/customers/addresses/List").default);
Vue.component('assetflow-addresses-new', require('./assetflow/customers/addresses/New').default);
Vue.component('assetflow-addresses-edit', require('./assetflow/customers/addresses/New').default);


Vue.component("assetflow-suppliers", require("./assetflow/customers/suppliers/List").default);
Vue.component("assetflow-events", require("./assetflow/customers/events/List").default);
Vue.component("assetflow-asset_groups", require("./assetflow/customers/asset_groups/List").default);

Vue.component("assetflow-orders", require("./assetflow/customers/orders/List").default);
Vue.component('assetflow-orders-new', require('./assetflow/customers/orders/New').default);
Vue.component('assetflow-orders-edit', require('./assetflow/customers/orders/New').default);
Vue.component('assetflow-orders-process', require('./assetflow/customers/orders/Process').default);
Vue.component('assetflow-orders-confirm', require('./assetflow/customers/orders/ConfirmOrder').default);
Vue.component('assetflow-order_setup', require('./assetflow/customers/orders/NotifiedUserList').default);

Vue.component('assetflow-admin_tools', require('./assetflow/customers/managed_storages/AdminTools').default);

Vue.component('assetflow-order_lines-new', require('./assetflow/customers/order_lines/AddOrReturnIntakeToStorage').default);
Vue.component('assetflow-order_lines-return_to_storage', require('./assetflow/customers/order_lines/AddOrReturnIntakeToStorage').default);


Vue.component('assetflow-orders-storage_orders', require('./assetflow/customers/orders/List').default);
Vue.component('assetflow-orders-move_to_storage', require('./assetflow/customers/orders/List').default);

Vue.component('assetflow-order_lines-storage_orders', require('./assetflow/customers/order_lines/List').default);
Vue.component('assetflow-order_lines-move_to_storage', require('./assetflow/customers/order_lines/List').default);

Vue.component('assetflow-managed_storages-dispatched', require('./assetflow/customers/managed_storages/List').default);
Vue.component('assetflow-managed_storages-in_progress', require('./assetflow/customers/managed_storages/List').default);
Vue.component('assetflow-managed_storages-in_storage', require('./assetflow/customers/managed_storages/List').default);


Vue.component("assetflow-order_lines", require("./assetflow/customers/order_lines/List").default);
Vue.component("assetflow-managed_storages", require("./assetflow/customers/managed_storages/List").default);
Vue.component("assetflow-activity_logs", require("./assetflow/customers/activity_log/List").default);

Vue.component("assetflow-bulk_updates", require("./assetflow/customers/bulk_updates/List").default);
Vue.component("assetflow-bulk_updates-user", require("./assetflow/customers/bulk_updates/List").default);
Vue.component("assetflow-ledgers", require("./assetflow/customers/ledgers/List").default);
Vue.component("assetflow-ledger_groups", require("./assetflow/customers/ledger_groups/List").default);
Vue.component("assetflow-characters", require("./assetflow/customers/characters/List").default);
Vue.component("assetflow-scenes", require("./assetflow/customers/scenes/List").default);
Vue.component("assetflow-scripts", require("./assetflow/customers/scripts/List").default);
Vue.component("assetflow-shoot-day-live", require("./assetflow/customers/ShootDayLive/Dashboard").default);

Vue.component("assetflow-changes", require("./assetflow/customers/changes/List").default);

Vue.component("assetflow-scenes-costume", require("./assetflow/customers/parts_costume/List").default);
Vue.component("assetflow-scenes-costume-new", require("./assetflow/customers/parts_costume/New").default);
Vue.component("assetflow-scenes-costume-edit", require("./assetflow/customers/parts_costume/New").default);

Vue.component("assetflow-parts", require("./assetflow/customers/parts/List").default);
Vue.component("assetflow-parts-new", require("./assetflow/customers/parts/New").default);
Vue.component("assetflow-parts-edit", require("./assetflow/customers/parts/New").default);

Vue.component("assetflow-scene_locations", require("./assetflow/customers/scene_locations/List").default);

Vue.component("assetflow-payments", require("./assetflow/customers/payments/List").default);
Vue.component("assetflow-receipts", require("./assetflow/customers/receipts/List").default);

Vue.component("assetflow-sp_warehouses", require("./assetflow/customers/sp_warehouses/List").default);
Vue.component("assetflow-sp_spaces", require("./assetflow/customers/sp_spaces/List").default);
Vue.component("assetflow-sp_assignments", require("./assetflow/customers/sp_assignments/List").default);

Vue.component("assetflow-sp_storages", require("./assetflow/customers/sp_storages/List").default);
Vue.component("assetflow-sp_storage_wrappers", require("./assetflow/customers/sp_storage_wrappers/List").default);
Vue.component("assetflow-sp_locations", require("./assetflow/customers/sp_locations/List").default);
Vue.component("assetflow-sp_location_types", require("./assetflow/customers/sp_location_types/List").default);

Vue.component("assetflow-sp_movements", require("./assetflow/customers/sp_movements/List").default);

Vue.component("assetflow-files", require("./assetflow/customers/files/List").default);
Vue.component("assetflow-file_imports", require("./assetflow/customers/file_imports/List").default);

Vue.component("group-asset_passports", require("./assetflow/customers/asset_passports/List").default);
Vue.component("assetflow-asset_passports", require("./assetflow/customers/asset_passports/List").default);

Vue.component("assetflow-intakes", require("./assetflow/customers/intakes/List").default);

Vue.component("assetflow-departments", require("./assetflow/customers/departments/List").default);


Vue.component("assetflow-purchaseOrders", require("./assetflow/customers/purchaseOrders/List").default);
Vue.component("assetflow-purchaseOrders-new", require("./assetflow/customers/purchaseOrders/New").default);
Vue.component('assetflow-purchaseOrders-edit', require('./assetflow/customers/purchaseOrders/New').default);
Vue.component('assetflow-settings', require('./assetflow/customers/settings/SettingsTabs').default);
Vue.component('group-settings', require('./assetflow/customers/settings/GroupSettingsTabs').default);

Vue.component("assetflow-acquisitions", require("./assetflow/customers/acquisitions/List").default);
Vue.component('assetflow-acquisitions-new', require('./assetflow/customers/acquisitions/New').default);
Vue.component('assetflow-acquisitions-edit', require('./assetflow/customers/acquisitions/New').default);

Vue.component("assetflow-dispositions", require("./assetflow/customers/dispositions/List").default);

Vue.component("assetflow-tags", require("./assetflow/customers/tags/List").default);
Vue.component("assetflow-folders", require("./assetflow/customers/folders/List").default);
Vue.component("assetflow-categories", require("./assetflow/customers/categories/List").default);


Vue.component("assetflow-listView", require("./assetflow/ListViewTest").default);
Vue.component("htmlRenderer", require("./assetflow/HtmlRenderer").default);
Vue.component("tristHtmlRenderer", require("./assetflow/TristHtmlRenderer").default);
Vue.component("formTemplate", require("./assetflow/templates/AssetFormTemplate").default);
Vue.component("chartTest", require("./assetflow/ChartTest").default);



Vue.component('assetflow-assets-status', require('./assetflow/assets/List').default);
Vue.component('assetflow-assets-folders', require('./assetflow/assets/List').default);

Vue.component('assetflow-assetsNoTabs', require('./assetflow/assets/ListNoTabs').default);
Vue.component('assetflow-assets', require('./assetflow/assets/List').default);
Vue.component('assetflow-olderassets', require('./assetflow/assets/List-OLD').default);

Vue.component("assetflow-aflogs", require("./assetflow/aflogs/List").default);


Vue.component('assetflow-customers', require('./assetflow/customers/List').default);
Vue.component('assetflow-service_providers', require('./assetflow/service_providers/List').default);

Vue.component('assetflow-users', require('./assetflow/users/List').default);
Vue.component('assetflow-buyers', require('./assetflow/customers/buyers/List').default);

Vue.component('assetflow-roles', require('./assetflow/roles/List').default);
Vue.component('assetflow-modules', require('./assetflow/modules/List').default);
Vue.component('assetflow-templates', require('./assetflow/file_templates/List').default);


Vue.component('sp-assets', require('./assetflow/assets/List').default);
Vue.component('sp-customers', require('./assetflow/customers/List').default);
Vue.component('sp-users', require('./assetflow/users/List').default);
Vue.component('sp-roles', require('./assetflow/roles/List').default);

Vue.component('sp-roles-edit', require('./assetflow/roles/New').default);

Vue.component('assetflow-switch', require('./account/switch').default);
Vue.component('group-switch', require('./account/switch').default);
Vue.component('character-switch', require('./account/switch').default);


Vue.component('assetflow-roles-new', require('./assetflow/roles/New').default);
Vue.component('assetflow-roles-edit', require('./assetflow/roles/New').default);
Vue.component('assetflow-roles-view', require('./assetflow/roles/View').default);
Vue.component('assetflow-roles-permissions', require('./assetflow/roles/PermissionsList').default);


Vue.component('assetflow-users-new_user', require('./assetflow/users/New').default);
Vue.component('assetflow-users-new', require('./assetflow/users/Add').default);
Vue.component('assetflow-users-edit', require('./assetflow/users/New').default);
Vue.component('assetflow-users-view', require('./assetflow/users/View').default);


Vue.component('assetflow-assets-csv_import', require('./assetflow/assets/CsvImport').default);

Vue.component('assetflow-assets-new-older', require('./assetflow/assets/New-old').default);
Vue.component('assetflow-assets-edit-older', require('./assetflow/assets/New-old').default);

Vue.component('assetflow-assets-new', require('./assetflow/assets/New').default);

Vue.component('assetflow-assets-new_asset', require('./assetflow/assets/NewAssetOrBulkPhotoUpload').default);
Vue.component('assetflow-assets-edit_asset', require('./assetflow/assets/EditAsset.vue').default);
Vue.component('assetflow-assets-bulk_photo_upload', require('./assetflow/assets/NewAssetOrBulkPhotoUpload').default);


Vue.component('assetflow-acquisitions-new_acquisition', require('./assetflow/customers/acquisitions/NewAssetAcquisition').default);
Vue.component('assetflow-assets-edit', require('./assetflow/assets/New').default);
Vue.component('assetflow-assets-admin_new', require('./assetflow/assets/NewAdmin').default);
Vue.component('assetflow-assets-admin_edit', require('./assetflow/assets/NewAdmin').default);


Vue.component('assetflow-customers-new', require('./assetflow/customers/New').default);
Vue.component('assetflow-customers-edit', require('./assetflow/customers/New').default);
Vue.component('assetflow-customer-view', require('./assetflow/customers/View').default);


Vue.component('assetflow-groups', require('./assetflow/groups/List').default);
Vue.component('assetflow-groups-new', require('./assetflow/groups/New').default);
Vue.component('assetflow-groups-edit', require('./assetflow/groups/New').default);

Vue.component('assetflow-customer-sp', require('./assetflow/items/View').default);


Vue.component('assetflow-service_providers-new', require('./assetflow/service_providers/New').default);
Vue.component('assetflow-service_providers-edit', require('./assetflow/service_providers/New').default);
Vue.component('assetflow-service_providers-view', require('./assetflow/service_providers/View').default);

Vue.component('bss-assets', require('./assetflow/assets/List').default);


Vue.component('profile', require('./account/profile').default);
Vue.component('my_roles', require('./account/my_roles').default);
Vue.component('lockscreen', require('./account/lockscreen').default);
Vue.component('new_signup_wizard', require('./account/FirstLoginWizard').default);

Vue.component('shall_select_module', require('./account/SelectAModule').default);



Vue.component('assetflow-sustainability', require('./sustain/Sustainability').default);


Vue.component('group-assets', require('./assetflow/assets/GroupAssets').default);
Vue.component('group-customers', require('./assetflow/customers/List').default);



// Vue.component('home', () => import(/* webpackChunkName: "dashboard" */ './Dashboard').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('login', () => import(/* webpackChunkName: "account_login_2" */ './account/login-2').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('login-carousel', () => import(/* webpackChunkName: "account_logincarousel" */ './account/LoginCarousel').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('register', () => import(/* webpackChunkName: "account_register" */ './account/register').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('forgot-password', () => import(/* webpackChunkName: "account_forgot_password" */ './account/forgot-password').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('reset-password', () => import(/* webpackChunkName: "account_reset_password" */ './account/reset-password').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('viewItem', () => import(/* webpackChunkName: "assetflow_items_view" */ './assetflow/items/View').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('customer-overview', () => import(/* webpackChunkName: "assetflow_customers_overview" */ './assetflow/customers/Overview').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-customers-assets_dashboard', () => import(/* webpackChunkName: "assetflow_customers_dashboards_dashboard" */ './assetflow/customers/Dashboards/Dashboard').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-customers-sto_dashboard', () => import(/* webpackChunkName: "assetflow_customers_dashboards_stodashboard" */ './assetflow/customers/Dashboards/StoDashboard').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-addresses', () => import(/* webpackChunkName: "assetflow_customers_addresses_list" */ './assetflow/customers/addresses/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-addresses-new', () => import(/* webpackChunkName: "assetflow_customers_addresses_new" */ './assetflow/customers/addresses/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-addresses-edit', () => import(/* webpackChunkName: "assetflow_customers_addresses_new" */ './assetflow/customers/addresses/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-suppliers', () => import(/* webpackChunkName: "assetflow_customers_suppliers_list" */ './assetflow/customers/suppliers/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-events', () => import(/* webpackChunkName: "assetflow_customers_events_list" */ './assetflow/customers/events/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-asset_groups', () => import(/* webpackChunkName: "assetflow_customers_asset_groups_list" */ './assetflow/customers/asset_groups/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-orders', () => import(/* webpackChunkName: "assetflow_customers_orders_list" */ './assetflow/customers/orders/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-orders-new', () => import(/* webpackChunkName: "assetflow_customers_orders_new" */ './assetflow/customers/orders/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-orders-edit', () => import(/* webpackChunkName: "assetflow_customers_orders_new" */ './assetflow/customers/orders/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-orders-process', () => import(/* webpackChunkName: "assetflow_customers_orders_process" */ './assetflow/customers/orders/Process').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-order_lines', () => import(/* webpackChunkName: "assetflow_customers_order_lines_list" */ './assetflow/customers/order_lines/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-managed_storages', () => import(/* webpackChunkName: "assetflow_customers_managed_storages_list" */ './assetflow/customers/managed_storages/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-activity_logs', () => import(/* webpackChunkName: "assetflow_customers_activity_log_list" */ './assetflow/customers/activity_log/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-nominal_codes', () => import(/* webpackChunkName: "assetflow_customers_nominal_codes_list" */ './assetflow/customers/nominal_codes/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-payments', () => import(/* webpackChunkName: "assetflow_customers_payments_list" */ './assetflow/customers/payments/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-receipts', () => import(/* webpackChunkName: "assetflow_customers_receipts_list" */ './assetflow/customers/receipts/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('group-asset_passports', () => import(/* webpackChunkName: "assetflow_customers_asset_passports_list" */ './assetflow/customers/asset_passports/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-asset_passports', () => import(/* webpackChunkName: "assetflow_customers_asset_passports_list" */ './assetflow/customers/asset_passports/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-intakes', () => import(/* webpackChunkName: "assetflow_customers_intakes_list" */ './assetflow/customers/intakes/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-departments', () => import(/* webpackChunkName: "assetflow_customers_departments_list" */ './assetflow/customers/departments/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-purchaseOrders', () => import(/* webpackChunkName: "assetflow_customers_purchaseorders_list" */ './assetflow/customers/purchaseOrders/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-purchaseOrders-new', () => import(/* webpackChunkName: "assetflow_customers_purchaseorders_new" */ './assetflow/customers/purchaseOrders/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-purchaseOrders-edit', () => import(/* webpackChunkName: "assetflow_customers_purchaseorders_new" */ './assetflow/customers/purchaseOrders/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-settings', () => import(/* webpackChunkName: "assetflow_customers_settings_settingstabs" */ './assetflow/customers/settings/SettingsTabs').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('group-settings', () => import(/* webpackChunkName: "assetflow_customers_settings_groupsettingstabs" */ './assetflow/customers/settings/GroupSettingsTabs').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-acquisitions', () => import(/* webpackChunkName: "assetflow_customers_acquisitions_list" */ './assetflow/customers/acquisitions/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-acquisitions-new', () => import(/* webpackChunkName: "assetflow_customers_acquisitions_new" */ './assetflow/customers/acquisitions/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-acquisitions-edit', () => import(/* webpackChunkName: "assetflow_customers_acquisitions_new" */ './assetflow/customers/acquisitions/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-dispositions', () => import(/* webpackChunkName: "assetflow_customers_dispositions_list" */ './assetflow/customers/dispositions/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-tags', () => import(/* webpackChunkName: "assetflow_tags_list" */ './assetflow/tags/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-listView', () => import(/* webpackChunkName: "assetflow_listviewtest" */ './assetflow/ListViewTest').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('htmlRenderer', () => import(/* webpackChunkName: "assetflow_htmlrenderer" */ './assetflow/HtmlRenderer').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('tristHtmlRenderer', () => import(/* webpackChunkName: "assetflow_tristhtmlrenderer" */ './assetflow/TristHtmlRenderer').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('formTemplate', () => import(/* webpackChunkName: "assetflow_templates_assetformtemplate" */ './assetflow/templates/AssetFormTemplate').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('chartTest', () => import(/* webpackChunkName: "assetflow_charttest" */ './assetflow/ChartTest').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-assets-status', () => import(/* webpackChunkName: "assetflow_assets_list" */ './assetflow/assets/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-assets-folders', () => import(/* webpackChunkName: "assetflow_assets_list" */ './assetflow/assets/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-assetsNoTabs', () => import(/* webpackChunkName: "assetflow_assets_listnotabs" */ './assetflow/assets/ListNoTabs').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-assets', () => import(/* webpackChunkName: "assetflow_assets_list" */ './assetflow/assets/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-olderassets', () => import(/* webpackChunkName: "assetflow_assets_list_old" */ './assetflow/assets/List-OLD').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-aflogs', () => import(/* webpackChunkName: "assetflow_aflogs_list" */ './assetflow/aflogs/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-folders', () => import(/* webpackChunkName: "assetflow_folders_list" */ './assetflow/folders/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-customers', () => import(/* webpackChunkName: "assetflow_customers_list" */ './assetflow/customers/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-service_providers', () => import(/* webpackChunkName: "assetflow_service_providers_list" */ './assetflow/service_providers/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-users', () => import(/* webpackChunkName: "assetflow_users_list" */ './assetflow/users/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-roles', () => import(/* webpackChunkName: "assetflow_roles_list" */ './assetflow/roles/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('sp-assets', () => import(/* webpackChunkName: "assetflow_assets_list" */ './assetflow/assets/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('sp-customers', () => import(/* webpackChunkName: "assetflow_customers_list" */ './assetflow/customers/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('sp-users', () => import(/* webpackChunkName: "assetflow_users_list" */ './assetflow/users/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('sp-roles', () => import(/* webpackChunkName: "assetflow_roles_list" */ './assetflow/roles/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('sp-roles-edit', () => import(/* webpackChunkName: "assetflow_roles_new" */ './assetflow/roles/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-switch', () => import(/* webpackChunkName: "account_switch" */ './account/switch').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('group-switch', () => import(/* webpackChunkName: "account_switch" */ './account/switch').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-roles-new', () => import(/* webpackChunkName: "assetflow_roles_new" */ './assetflow/roles/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-roles-edit', () => import(/* webpackChunkName: "assetflow_roles_new" */ './assetflow/roles/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-roles-view', () => import(/* webpackChunkName: "assetflow_roles_view" */ './assetflow/roles/View').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-roles-permissions', () => import(/* webpackChunkName: "assetflow_roles_permissionslist" */ './assetflow/roles/PermissionsList').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-users-new', () => import(/* webpackChunkName: "assetflow_users_new" */ './assetflow/users/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-users-edit', () => import(/* webpackChunkName: "assetflow_users_new" */ './assetflow/users/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-users-view', () => import(/* webpackChunkName: "assetflow_users_view" */ './assetflow/users/View').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-assets-csv_import', () => import(/* webpackChunkName: "assetflow_assets_csvimport" */ './assetflow/assets/CsvImport').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-assets-new-older', () => import(/* webpackChunkName: "assetflow_assets_new_old" */ './assetflow/assets/New-old').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-assets-edit-older', () => import(/* webpackChunkName: "assetflow_assets_new_old" */ './assetflow/assets/New-old').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-assets-new', () => import(/* webpackChunkName: "assetflow_assets_new" */ './assetflow/assets/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-assets-edit', () => import(/* webpackChunkName: "assetflow_assets_new" */ './assetflow/assets/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-assets-admin_new', () => import(/* webpackChunkName: "assetflow_assets_newadmin" */ './assetflow/assets/NewAdmin').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-assets-admin_edit', () => import(/* webpackChunkName: "assetflow_assets_newadmin" */ './assetflow/assets/NewAdmin').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-customers-new', () => import(/* webpackChunkName: "assetflow_customers_new" */ './assetflow/customers/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-customers-edit', () => import(/* webpackChunkName: "assetflow_customers_new" */ './assetflow/customers/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-customer-view', () => import(/* webpackChunkName: "assetflow_customers_view" */ './assetflow/customers/View').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-groups', () => import(/* webpackChunkName: "assetflow_groups_list" */ './assetflow/groups/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-groups-new', () => import(/* webpackChunkName: "assetflow_groups_new" */ './assetflow/groups/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-groups-edit', () => import(/* webpackChunkName: "assetflow_groups_new" */ './assetflow/groups/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-customer-sp', () => import(/* webpackChunkName: "assetflow_items_view" */ './assetflow/items/View').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-service_providers-new', () => import(/* webpackChunkName: "assetflow_service_providers_new" */ './assetflow/service_providers/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-service_providers-edit', () => import(/* webpackChunkName: "assetflow_service_providers_new" */ './assetflow/service_providers/New').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-service_providers-view', () => import(/* webpackChunkName: "assetflow_service_providers_view" */ './assetflow/service_providers/View').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('bss-assets', () => import(/* webpackChunkName: "assetflow_assets_list" */ './assetflow/assets/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('profile', () => import(/* webpackChunkName: "account_profile" */ './account/profile').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('lockscreen', () => import(/* webpackChunkName: "account_lockscreen" */ './account/lockscreen').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('new_signup_wizard', () => import(/* webpackChunkName: "account_firstloginwizard" */ './account/FirstLoginWizard').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('assetflow-sustainability', () => import(/* webpackChunkName: "sustain_sustainability" */ './sustain/Sustainability').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('group-assets', () => import(/* webpackChunkName: "assetflow_assets_groupassets" */ './assetflow/assets/GroupAssets').then((AsyncComponent) => { resolve(AsyncComponent.default); }));
//
// Vue.component('group-customers', () => import(/* webpackChunkName: "assetflow_customers_list" */ './assetflow/customers/List').then((AsyncComponent) => { resolve(AsyncComponent.default); }));





// Vue.component('home', (resolve) => {
//     import('./Dashboard')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('login', (resolve) => {
//     import('./account/login-2')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('login-carousel', (resolve) => {
//     import('./account/LoginCarousel')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('register', (resolve) => {
//     import('./account/register')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('forgot-password', (resolve) => {
//     import('./account/forgot-password')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('reset-password', (resolve) => {
//     import('./account/reset-password')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('viewItem', (resolve) => {
//     import('./assetflow/items/View')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('customer-overview', (resolve) => {
//     import('./assetflow/customers/Overview')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-customers-assets_dashboard', (resolve) => {
//     import('./assetflow/customers/Dashboards/Dashboard')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-customers-sto_dashboard', (resolve) => {
//     import('./assetflow/customers/Dashboards/StoDashboard')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-addresses', (resolve) => {
//     import('./assetflow/customers/addresses/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-addresses-new', (resolve) => {
//     import('./assetflow/customers/addresses/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-addresses-edit', (resolve) => {
//     import('./assetflow/customers/addresses/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
//
// Vue.component('assetflow-suppliers', (resolve) => {
//     import('./assetflow/customers/suppliers/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-events', (resolve) => {
//     import('./assetflow/customers/events/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-asset_groups', (resolve) => {
//     import('./assetflow/customers/asset_groups/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-orders', (resolve) => {
//     import('./assetflow/customers/orders/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-orders-new', (resolve) => {
//     import('./assetflow/customers/orders/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-orders-edit', (resolve) => {
//     import('./assetflow/customers/orders/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-orders-process', (resolve) => {
//     import('./assetflow/customers/orders/Process')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-order_lines', (resolve) => {
//     import('./assetflow/customers/order_lines/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-managed_storages', (resolve) => {
//     import('./assetflow/customers/managed_storages/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-activity_logs', (resolve) => {
//     import('./assetflow/customers/activity_log/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-nominal_codes', (resolve) => {
//     import('./assetflow/customers/nominal_codes/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-payments', (resolve) => {
//     import('./assetflow/customers/payments/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-receipts', (resolve) => {
//     import('./assetflow/customers/receipts/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('group-asset_passports', (resolve) => {
//     import('./assetflow/customers/asset_passports/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-asset_passports', (resolve) => {
//     import('./assetflow/customers/asset_passports/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-intakes', (resolve) => {
//     import('./assetflow/customers/intakes/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-departments', (resolve) => {
//     import('./assetflow/customers/departments/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
//
// Vue.component('assetflow-purchaseOrders', (resolve) => {
//     import('./assetflow/customers/purchaseOrders/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-purchaseOrders-new', (resolve) => {
//     import('./assetflow/customers/purchaseOrders/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-purchaseOrders-edit', (resolve) => {
//     import('./assetflow/customers/purchaseOrders/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-settings', (resolve) => {
//     import('./assetflow/customers/settings/SettingsTabs')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('group-settings', (resolve) => {
//     import('./assetflow/customers/settings/GroupSettingsTabs')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-acquisitions', (resolve) => {
//     import('./assetflow/customers/acquisitions/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-acquisitions-new', (resolve) => {
//     import('./assetflow/customers/acquisitions/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-acquisitions-edit', (resolve) => {
//     import('./assetflow/customers/acquisitions/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-dispositions', (resolve) => {
//     import('./assetflow/customers/dispositions/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-tags', (resolve) => {
//     import('./assetflow/tags/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-listView', (resolve) => {
//     import('./assetflow/ListViewTest')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('htmlRenderer', (resolve) => {
//     import('./assetflow/HtmlRenderer')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('tristHtmlRenderer', (resolve) => {
//     import('./assetflow/TristHtmlRenderer')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('formTemplate', (resolve) => {
//     import('./assetflow/templates/AssetFormTemplate')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('chartTest', (resolve) => {
//     import('./assetflow/ChartTest')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
//
//
// Vue.component('assetflow-assets-status', (resolve) => {
//     import('./assetflow/assets/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-assets-folders', (resolve) => {
//     import('./assetflow/assets/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-assetsNoTabs', (resolve) => {
//     import('./assetflow/assets/ListNoTabs')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-assets', (resolve) => {
//     import('./assetflow/assets/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-olderassets', (resolve) => {
//     import('./assetflow/assets/List-OLD')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-aflogs', (resolve) => {
//     import('./assetflow/aflogs/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-folders', (resolve) => {
//     import('./assetflow/folders/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-customers', (resolve) => {
//     import('./assetflow/customers/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-service_providers', (resolve) => {
//     import('./assetflow/service_providers/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-users', (resolve) => {
//     import('./assetflow/users/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-roles', (resolve) => {
//     import('./assetflow/roles/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
//
// Vue.component('sp-assets', (resolve) => {
//     import('./assetflow/assets/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('sp-customers', (resolve) => {
//     import('./assetflow/customers/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('sp-users', (resolve) => {
//     import('./assetflow/users/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('sp-roles', (resolve) => {
//     import('./assetflow/roles/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('sp-roles-edit', (resolve) => {
//     import('./assetflow/roles/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-switch', (resolve) => {
//     import('./account/switch')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('group-switch', (resolve) => {
//     import('./account/switch')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
//
// Vue.component('assetflow-roles-new', (resolve) => {
//     import('./assetflow/roles/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-roles-edit', (resolve) => {
//     import('./assetflow/roles/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-roles-view', (resolve) => {
//     import('./assetflow/roles/View')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-roles-permissions', (resolve) => {
//     import('./assetflow/roles/PermissionsList')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
//
// Vue.component('assetflow-users-new', (resolve) => {
//     import('./assetflow/users/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-users-edit', (resolve) => {
//     import('./assetflow/users/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-users-view', (resolve) => {
//     import('./assetflow/users/View')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
//
// Vue.component('assetflow-assets-csv_import', (resolve) => {
//     import('./assetflow/assets/CsvImport')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-assets-new-older', (resolve) => {
//     import('./assetflow/assets/New-old')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-assets-edit-older', (resolve) => {
//     import('./assetflow/assets/New-old')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-assets-new', (resolve) => {
//     import('./assetflow/assets/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-assets-edit', (resolve) => {
//     import('./assetflow/assets/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-assets-admin_new', (resolve) => {
//     import('./assetflow/assets/NewAdmin')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-assets-admin_edit', (resolve) => {
//     import('./assetflow/assets/NewAdmin')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
//
// Vue.component('assetflow-customers-new', (resolve) => {
//     import('./assetflow/customers/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-customers-edit', (resolve) => {
//     import('./assetflow/customers/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-customer-view', (resolve) => {
//     import('./assetflow/customers/View')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
//
// Vue.component('assetflow-groups', (resolve) => {
//     import('./assetflow/groups/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-groups-new', (resolve) => {
//     import('./assetflow/groups/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-groups-edit', (resolve) => {
//     import('./assetflow/groups/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('assetflow-customer-sp', (resolve) => {
//     import('./assetflow/items/View')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
//
// Vue.component('assetflow-service_providers-new', (resolve) => {
//     import('./assetflow/service_providers/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-service_providers-edit', (resolve) => {
//     import('./assetflow/service_providers/New')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('assetflow-service_providers-view', (resolve) => {
//     import('./assetflow/service_providers/View')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('bss-assets', (resolve) => {
//     import('./assetflow/assets/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
//
// Vue.component('profile', (resolve) => {
//     import('./account/profile')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('lockscreen', (resolve) => {
//     import('./account/lockscreen')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('new_signup_wizard', (resolve) => {
//     import('./account/FirstLoginWizard')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
//
//
// Vue.component('assetflow-sustainability', (resolve) => {
//     import('./sustain/Sustainability')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
//
// Vue.component('group-assets', (resolve) => {
//     import('./assetflow/assets/GroupAssets')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
//
//
// Vue.component('group-customers', (resolve) => {
//     import('./assetflow/customers/List')
//         .then((AsyncComponent) => {
//             resolve(AsyncComponent.default);
//         });
// });
