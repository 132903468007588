<template>
    <Layout>
        <div class="bulk_updates-wrapper">
            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <h2 class="title">Bulk Update List</h2>

                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">
                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>

                    </div>

                    <!-- Product Section Start-->
                    <LotsOfItemsAlert :perpage="perpage"/>


                    <div v-if="listLoading" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching Bulk Updates...</h4>
                    </div>
                    <div v-else-if="!bulk_updatesData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Bulk Updates found here at the moment.</h4>
                    </div>
                    <div v-else-if="bulk_updatesData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="object_type"
                                :keys_list="bulk_update_list_keys_list"
                                line_type="bulk_updates"
                                :get-on-click-row="onClickRow"
                            >
                                <template v-slot:bulk_updates_actions="{ item, index }">

                                    <div class="btn-group bl-auto">
                                        <button type="button" class="btn btn-none dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <b-dropdown-item v-if="permissions_list['view_' + object_type]"
                                                             :href="'/' + item_type + '/bulk_updates/' + item.id + '/view'">
                                                View
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="permissions_list['delete_' + object_type]"
                                                             variant="danger"
                                                             @click="performAction(item, 'delete' + object_type, index, $event)">
                                                Delete
                                            </b-dropdown-item>
                                        </div>
                                    </div>

                                </template>
                            </po-lines>
                        </div>
                    </div>

                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                            @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import SearchBox from "@/components/search-box";

const bulk_updateResource = new Resource('api/assetflow/bulk_updates');
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import {BulkUpdateKeysList} from "@/views/assetflow/form_data/BulkUpdateKeysList";
import PoLines from "../../items/widgets/detailsViews/PoLines";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";

export default {
    name: 'BulkUpdatesList',
    props: ['item_id', 'item_type', 'action'],
    components: {LotsOfItemsAlert, PoLines, SearchBox, Layout, PageHeader},
    data() {
        return {
            listLoading: false,
            bulk_update_list_keys_list: BulkUpdateKeysList,
            total: 0,
            bulk_updatesData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Bulk Updates",
            items: [
                {
                    text: "Bulk Updates",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'Status',
                    name: 'status',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            object_type: 'bulk_updates'
        };
    },
    created() {
        this.sorting.sortOptions.splice(0,2);
        console.log("this.action");
        console.log(this.action);

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_bulk_updates_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.bulk_update_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.bulk_update_preferred_sorting;
        }

        this.getList();
    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {

        onClickRow(item, line_type){
            if (item){
                let item_id = item.id;
                window.location.href = '/' + this.item_type + '/bulk_updates/' + item_id + '/view';
            }
        },
        checkBulkUpdateStatus(bulk_update){
            return parseInt(bulk_update.assets_sum_quantity) !== 0;
        },

        performAction(bulk_update, action, bulk_updateKey, e){
            if (action === 'edit'){
                this.$refs['add-new-bulk_update'].setSelectedItem(bulk_update);
                this.$bvModal.show('create-bulk_update');
            }else if (action === ('delete'+this.object_type)){
                if (bulk_update.id !== '' && bulk_update.assets_count === 0){
                    this.deleteItemByID(bulk_update.id, bulk_update.name, this.object_type, bulk_update)
                        .then((response) => {
                            if (response.data.data.status){
                                this.bulk_updatesData.splice(bulk_updateKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
            return this.bulk_updatesData;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('bulk_update_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_bulk_updates_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item=null) {
            this.listLoading = true;
            this.handleQueryItems();
            this.listQuery = this.addPaginationParams(this.listQuery)
            if (this.action === 'user'){
                this.listQuery.only_mine = 1;
            }
            const {data} = await bulk_updateResource.list(this.listQuery);

            this.bulk_updatesData = data.data.items;
            if (data.hasOwnProperty('meta')){
                this.request_meta = data.meta;
                this.total = data.meta.total;
            }else{
                this.total = this.bulk_updatesData.length;
                this.request_meta = {total: this.total}
            }

            // PAGINATION ISN'T WORKING!
            this.listLoading = false;
        },
        handleQueryItems() {
            if (this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.item_id !== '' ? this.item_id : "0";
            }
            if (this.item_type === 'customer') {
                this.listQuery.customer_id = this.item_id !== '' ? this.item_id : "0";
            }
        },

        addNewItem() {
            this.$refs['add-new-bulk_update'].setSelectedItem(null);
            this.$bvModal.show('create-bulk_update');
        }
    },
}
</script>

