<template>

    <div>
        <div class="row">
            <div class="col-md-2 col-xl-2">
                <div class="cm_list_title_content">
                    <div class="cm_list_view_title" v-if="is_sub"><a :href="'/'+item_type+'/'+object_type+'/'+object.id+'/view'"><i class="text-af-accent" :class="object.hasOwnProperty('icon') ? object.icon : ''"></i> {{ object.name }}</a></div>
                    <div class="cm_list_view_title" v-else><h3><a :href="'/'+item_type+'/'+object_type+'/'+object.id+'/view'">
                        <i class="text-af-accent" :class="object.hasOwnProperty('icon') ? object.icon : ''"></i> {{ object.name }}</a></h3></div>
                </div>
            </div>
            <div v-if="object_type === 'tags'" class="col-md-2 col-xl-2">
            </div>
            <div v-else class="col-md-2 col-xl-2">
                <div class="cm_list_title_content">
                    <div class="cm_list_view_title" v-if="object.hasOwnProperty('subfolders_count')">{{ object.subfolders_count }} sub{{ object_type }}</div>
                    <div class="cm_list_view_title" v-else>0 sub{{ object_type }}</div>
                </div>
            </div>
            <div class="col-md-1 col-xl-1">
                <div class="cm_list_title_content">
                    <div v-if="object.created_at" class="cm_list_view_title">{{ object.created_at | moment('DD/MM/YYYY')}}</div>
                    <div v-else class="cm_list_view_title">-</div>
                </div>
            </div>
            <div v-if="object_type === 'categories'" class="col-md-2 col-xl-2">
            </div>
            <div v-else class="col-md-2 col-xl-2">
                <div class="cm_list_title_content">
                    <div class="cm_list_view_title" v-if="object.hasOwnProperty('user') && object.user"><a
                        :href="'./users/'+object.user.id+'/view'" class="text-dark">{{ object.user.name | titleize }}</a>
                    </div>
                    <div class="cm_list_view_title" v-else>-</div>
                </div>
            </div>
            <div class="col-md-1 col-xl-1">
                <div class="cm_list_title_content">
                    <div class="cm_list_view_title" v-if="object.hasOwnProperty('assets_count')">
                        <a :href="'/'+item_type+'/managed_storages/in_storage?queue=' + object_type + '&id='+object.id">{{ object.assets_count }} Assets</a>
                    </div>
                    <div class="cm_list_view_title" v-else>-</div>
                </div>
            </div>
            <div class="col-md-4 col-xl-4">
                <div class="cm_status_title_content">
                    <div class="cm_status_ul">
                        <ul>
                            <li>
                                <p>Total
                                    <span class="total_box_bg" v-if="object.hasOwnProperty('assets_total_quantity')">{{ object.assets_total_quantity }}</span>
                                    <span class="total_box_bg" v-else>0</span>
                                </p>
                            </li>
                            <li>
                                <p>Available
                                    <span class="available_box_bg"
                                          v-if="object.hasOwnProperty('assets_available_quantity')">{{ object.assets_available_quantity }}</span>
                                    <span class="available_box_bg" v-else>0</span>
                                </p>
                            </li>
                            <li>
                                <p>Reserved
                                    <span class="reserved_box_bg" v-if="object.hasOwnProperty('assets_reserved_quantity')">{{ object.assets_reserved_quantity }}</span>
                                    <span class="reserved_box_bg" v-else>0</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="cm_list_view_more_button children-action-menu" v-if="performAction">
            <div v-if="hasPermission('edit_' + object_type) || hasPermission('browse_'+object_type)" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                    <b-dropdown-item v-if="hasPermission('browse_' + object_type)"
                                     :href="'/'+ item_type + '/' + object_type + '/' + object.id + '/view'" >
                        View
                    </b-dropdown-item>
                    <b-dropdown-item v-if="object_type !== 'categories' && hasPermission('edit_' + object_type)"
                                     @click="performAction(object, 'edit', object_index, $event)">
                        Edit
                    </b-dropdown-item>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ParentAwareObjectRow',
    props: {
        is_sub: {},
        item_type: {},
        object: {},
        object_type: {},
        object_index: {},
        performAction: {},
    }
}
</script>
