<template>
    <div class="intelligence-template">

        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>

        <div class="tab-content tab_content_design">


            <div class="tab-pane" id="import_summary_tab" role="tabpanel" :class="isActiveTab('import_summary_tab')">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-title">
                                <h6 v-iconize="'import_summary'">Import Summary</h6>
                            </div>
                            <div class="card-body">
                                <div class="col-12 mt-3" v-if="item_info">
                                    <ul class="summary-log-items">
                                        <li><b>Import ID:</b>
                                            <a class="text-dark" :href="'/' + item_info.current_page_type + '/file_imports/' + item_info.id + '/view'">
                                                <span class="file-import-status-btn" :class="{
                                                    'status-btn-acquired': item_info.status === 0,
                                                    'status-btn-active': item_info.status === 1,
                                                    'status-btn-disposed': item_info.status === 2
                                                }">{{ item_info.uid }}</span>
                                            </a>
                                        </li>
                                        <li><b>Original Uploaded File:</b> <a :href="item_info.hasOwnProperty('original_file_url') ? item_info.original_file_url : '#'" target="_blank">{{ item_info.name }}</a> (click to download)</li>
                                        <li v-if="item_info.hasOwnProperty('edited_file_url') && item_info.edited_file_url"><b>Updated File:</b> <a :href="item_info.hasOwnProperty('edited_file_url') ? item_info.edited_file_url : '#'" target="_blank">{{ item_info.name }}</a> (click to download)</li>
                                        <li><b>{{ item_info.entity_type === 'sp' ? 'SP' : 'Customer' }} Name:</b> {{item_info.entity_info.name}}</li>
                                        <li><b>Object Type:</b> {{ item_info.object_type | titleize}}s</li>
                                        <li><b>Records created:</b> {{ item_info.number_of_items }} {{ item_info.object_type | titleize}}s</li>
                                        <li><b>Created By:</b> {{ item_info.user_info.name }}</li>
                                        <li><b>Created Date:</b> {{ item_info.created_at | moment('DD-MM-YYYY') }}</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane" id="file_import_activity_tab" role="tabpanel" :class="isActiveTab('file_import_activity_tab')">
                <FileImportActivityTab :item_info="item_info"/>
            </div>

            <div class="tab-pane" id="linked_items_tab" role="tabpanel" :class="isActiveTab('linked_items_tab')">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-title">
                                <h6 v-iconize="'linked_items'">Linked Items</h6>
                            </div>
                            <div class="card-body">
                                <FileImportObjectsList :item_info="item_info" :object_type="item_info.object_type"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="log_tab" role="tabpanel" :class="isActiveTab('log_tab')">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-title">
                                <h6 v-iconize="'log'">Log</h6>
                            </div>
                            <div class="card-body">
                                <p>Log</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import PoLines from "./widgets/detailsViews/PoLines";
import FileImportObjectsList from "./FileImportObjectsList";
import FileImportActivityTab from "./FileImportActivityTab";

export default {
    name: 'CustomFileImportDetails',
    components: {FileImportActivityTab, FileImportObjectsList, PoLines },
    props: ['item_info'],
    data(){
        return {
            active_tab: 'import_summary_tab',
            tabs: {
                'import_summary_tab': {
                    'id': "import_summary_tab",
                    'name': "Import Summary",
                    'icon': "user",
                    'type': "import_summary_tab",
                    'isActive': true
                },
                'file_import_activity_tab': {
                    'id': "file_import_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "file_import_activity_tab",
                    'isActive': true
                },
                'linked_items_tab': {
                    'id': "linked_items_tab",
                    'name': "Linked Items",
                    'icon': "user",
                    'type': "linked_items_tab",
                    'isActive': true
                },
                'log_tab': {
                    'id': "log_tab",
                    'name': "Log",
                    'icon': "user",
                    'type': "log_tab",
                    'isActive': true
                },
            },
        }
    },
    created() {

        let file_imports_active_tab = localStorage.getItem('file_imports_active_tab');
        let queryVars = this.getQueryVars();
        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'linked_items':
                    this.active_tab = 'linked_items_tab';
                    break;
                case 'log':
                    this.active_tab = 'log_tab';
                    break;
                default:
                    this.active_tab = 'import_summary_tab';
                    break;
            }
        }else if (file_imports_active_tab && file_imports_active_tab !== ''){
            this.active_tab = file_imports_active_tab;
        }

    },
    methods:{
        tabClicked(tab_id){
            localStorage.setItem('file_imports_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }
}
</script>
