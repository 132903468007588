import moment from 'moment-timezone';

export default {
    props:{
    },
    data(){
        return {
            latest_required_query: {},
            selectedTab: null,
            object_type: '',
            is_actions_bar_opened: false,
            is_selecting_items: null,
            selected_ids: [],
            selected_objects: [],
            selected_tags: [],
            selected_bulk_order: {name: 'Choose Order'},
            selected_bulk_storage: {name: 'Choose Storage'},
            selected_items_mode: 1,
            should_hide_unselected: false,
            shall_notify_subscribers: false,
            with_entity_logo: 0,
            selected_redirection_option: '1',
            selected_confirmation_option: '1',
            tab_list_name: '',
            can_bulk_update: false,
            list_items_tab_name: 'list_items',
            list_bulk_actions_tab_name: 'list_bulk_actions',
        }
    },
    created: function () {
        this.setSelectedItemsMode(1);
    },

    computed:{
        bulk_actions_customer_id(){
            return this.getRequiredCustomerID();
        },
        required_bulk_actions_customer_id(){
            if (this.object_type === 'sp_locations'){
                return true;
            }
            return this.getRequiredCustomerID(true);
        },
        selected_tab_changed(){
            return this.selectedTab;
        },
    },

    watch:{
        selected_tab_changed: {
            deep: true,
            handler: function (selected_tab) {
                if (this.tabs && this.object_type === 'order_lines'){
                    let selected_tab_name = Object.keys(this.tabs)[selected_tab];
                    this.tabClicked(selected_tab_name);
                }
            }
        },
    },
    methods: {
        preformBulkAction(action, event){
            // coming from asset, handle other logic?
            this.continueClicked(event, (action === 'export' ? 'export_item' : null));
        },
        appendBulkActionsTab(tab_list_name='listTabs'){
            let list_bulk_actions_tab = {
                'id': "list_bulk_actions",
                'name': 'Bulk Actions',
                'icon': "user",
                'type': "list_bulk_actions",
                'isActive': false
            };
            this.$set(this[tab_list_name], 'list_bulk_actions', list_bulk_actions_tab);
        },
        appendExportItemsTab(tab_list_name='listTabs'){
            let list_bulk_actions_tab = {
                'id': "export_item",
                'name': 'Export',
                'icon': "cube",
                'type': "export_item",
                'isActive': false,
            };
            this.$set(this[tab_list_name], 'export_item', list_bulk_actions_tab);
        },
        enableBulkActions(event){

            if (event){
                event.preventDefault();

                let strongThis = this;
                let user = this.user_info;

                let modal_content = '<p class="enable-bu-text bu-text-normal">Bulk Actions allow you to complete a lot of repetitive actions in just a few clicks.</p>';
                modal_content += '<p class="enable-bu-text bu-text-normal">They can be used to update a single item through to 1000\'s of items with just a few clicks.</p>';
                modal_content += '<p class="enable-bu-text bu-text-strong"><strong>Warning: Bulk Actions CANNOT be undone.</strong></p>';
                modal_content += '<p class="enable-bu-text bu-text-normal">If you would like a demo of Bulk Actions prior to activating them then please contact your Account Manager.</p>';
                modal_content += '<p class="enable-bu-text bu-text-strong"><strong>Enable Bulk Actions for your account now?</strong></p>';

                this.presentConfirmBox('Enable Bulk Actions', this.htmlToVNode(modal_content, 'enable-bu-block'), 'warning', 'md')
                    .then(value => {
                        if (value){
                            if (user){
                                let other_info = {
                                    success_message: 'Enabled Bulk Actions Successfully.'
                                }
                                this.set_item_property_new('users', user.id, 'can_bulk_update', 1, other_info, function () {
                                    strongThis.appendBulkActionsTab(strongThis.tab_list_name);
                                    strongThis.set_usermeta('bulk_actions_enabled_date', moment().tz('Europe/London').format('DD-MM-YYYY HH:mm'), "", false);
                                    if (user && user.hasOwnProperty('can_bulk_update')){
                                        strongThis.$set(strongThis.user_info, 'can_bulk_update', 1);
                                        strongThis.$set(strongThis, 'can_bulk_update', 1);
                                    }
                                });
                            }
                        }
                    }).catch((error) => {
                        reject(error);
                    });


            }
        },
        setBulkActionOptions(option_name, option_value){
            this[option_name] = option_value;
        },
        setSelectedItemsMode(selection_mode, event){
            this.selected_items_mode = selection_mode;
            this.is_selecting_items = selection_mode === 0;
        },
        startSelectingItems(event){
            if (event){
                this.tabClicked(this.list_items_tab_name);
                this.is_selecting_items = true;
            }
        },
        continueClicked(event, actions_tab_name){
            if (!actions_tab_name){
                actions_tab_name = this.list_bulk_actions_tab_name;
            }
            if (event){
                this.selected_items_mode = this.selected_objects.length === 0 ? 1 : 0;
                if (this.tabs && this.object_type === 'order_lines'){
                    this.selectedTab = Object.keys(this.tabs).indexOf(actions_tab_name);
                }else{
                    this.tabClicked(actions_tab_name);
                }

                this.$nextTick(() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                });

            }
        },
        performSelectionAction(selection_action=''){
            if (selection_action !== ''){
                switch (selection_action) {
                    case 'clear_selections':
                        this.clearSelectedItems();
                        break;
                    case 'hide_unselected':
                        this.hideUnselectedItems();
                        break;

                }
            }
        },

        exportAsset: function (item, addForm) {
            console.log("shall export this asset");
            console.log(item.id);

            if (!item){
                this.presentToast("Error!",
                    'Something went wrong',
                    'danger',
                    3000,
                    true,
                    true);
                return
            }



            let params = {
                action: 'export_assets',
                selected_items: [item.id],
                is_selecting_all: false,
                value: true,
                with_entity_logo: addForm.with_entity_logo,
                selected_confirmation_option: (''+addForm.send_email),
                selected_redirection_option: (''+addForm.where_to),
                file_type: addForm.type,
                template_id: 20,
                first_image: addForm.first_image,
                all_images: addForm.all_images,
                asset_details: addForm.asset_details,
                is_single: 1
            }

            axios({
                method:'post',
                url:'/api/assetflow/'+this.object_type+'/bulk_action',
                baseURL: '/',
                data: params
            }).then(response => {
                let status = response.data.data.status;
                let msg = response.data.data.msg;

                let redirect_url = response.data.data.redirect_url;
                if (status && redirect_url && redirect_url !== '#'){
                    if (redirect_url !== 'return_to_list'){
                        window.location.href = redirect_url;
                    }
                }else{
                    this.presentToast((status ? "Success!" : "Error!"),
                        msg,
                        (status ? 'success' : 'danger'),
                        3000,
                        true,
                        true);

                    let error = response.data.data.error;
                    if (!status && error === 'no_permission'){
                        window.flash(msg, 'danger');
                        location.reload();
                    }
                }
            })

        },
        performBulkAction(action, event){

            if (event){
                event.preventDefault();
                let currentTarget = event.currentTarget;
                let file_type = currentTarget.getAttribute('data-file_type') ?? '';
                let with_removed = currentTarget.getAttribute('data-with_removed') ?? false;
                let template_id = currentTarget.getAttribute('data-template_id') ?? null;
                let value = null;
                switch (action) {
                    case 'remove_from_order':
                        console.log("'remove_from_order'");
                        value = this.order.id;
                        break;
                    case 'complete_tasks':
                        console.log("'complete_tasks'");
                        value = this.order.id;
                        break;
                    case 'approve_intakes':
                        console.log("'approve_intakes'");
                        value = this.order.id;
                        break;
                    case 'export_single_order':
                        console.log("'export_single_order'");
                        value = this.order.id;
                        break;
                    case 'export_orders':
                        console.log("'export_orders'");
                        value = true;
                        break;
                    case 'add_to_order':
                        if (!this.selected_bulk_order){
                            return;
                        }
                        if (this.selected_bulk_order && this.selected_bulk_order.hasOwnProperty('id') && this.selected_bulk_order.id){
                            value = this.selected_bulk_order.id;
                        }
                        break;
                    case 'change_storage':
                        if (!this.selected_bulk_storage){
                            return;
                        }
                        if (this.selected_bulk_storage && this.selected_bulk_storage.hasOwnProperty('id') && this.selected_bulk_storage.id){
                            value = this.selected_bulk_storage.id;
                        }
                        break;
                    case 'tag_items':
                        value = this.selected_tags.join(',');
                        break;
                    case 'untag_items':
                        value = this.selected_tags.join(',');
                        break;
                    case 'export_sp_locations':
                        console.log("'export_sp_locations'");
                        value = true;
                        break;
                    case 'export_managed_storages':
                        console.log("'export_managed_storages'");
                        value = true;
                        break;
                    case 'export_assets':
                        console.log("'export_assets'");
                        value = true;
                        break;
                }

                if (!value){
                    this.presentToast("Error!",
                        'Invalid Input',
                        'danger',
                        3000,
                        true,
                        true);
                    return
                }
                const urlParts = window.location.pathname.split('/');
                let action_object_url_path = urlParts.slice(2).join('/').trim();
                let action_object_id = null;

                let params = {
                    action: action,
                    selected_items: this.selected_ids,
                    is_selecting_all: this.selected_items_mode === 1,
                    value: value,
                    shall_notify_subscribers: this.shall_notify_subscribers,
                    with_entity_logo: this.with_entity_logo,
                    selected_confirmation_option: this.selected_confirmation_option,
                    selected_redirection_option: this.selected_redirection_option,
                    action_object_url_path: action_object_url_path,
                    file_type: file_type,
                    template_id: template_id,
                    with_removed: (action === 'export_single_order') && with_removed
                }
                if (params.is_selecting_all){
                    if (['managed_storages', 'orders', 'sp_locations'].includes(this.object_type)){
                        params = this.SBHandleQueryItems(params, this.selected_item);
                        params.s_keyword = this.search_keyword;
                        params = this.appendTopFiltersValues(params);
                    }else if (this.object_type === 'order_lines' && this.order){
                        action_object_id = this.order.id;
                        params.order_id = action_object_id;
                    }else if (this.object_type === 'sp_storages' && this.item_info){
                        action_object_id = this.item_info.id;
                        params.sp_storage_id = action_object_id;
                        params.customer_id = this.bulk_actions_customer_id;
                    }else if (this.object_type === 'assets'){
                        if (this.latest_required_query !== {}){

                            params = Object.assign({}, this.latest_required_query, params);
                            delete params.limit;
                            delete params.page;
                            delete params.basic_info_only;

                        }else{
                            params = this.myHandleQueryItems(params);
                            params.s_keyword = this.search_keyword;
                        }
                    }
                }


                params.action_object_id = action_object_id;

                console.log("this.object_type");
                console.log(this.object_type);
                axios({
                    method:'post',
                    url:'/api/assetflow/'+this.object_type+'/bulk_action',
                    baseURL: '/',
                    data: params
                }).then(response => {
                    let status = response.data.data.status;
                    let msg = response.data.data.msg;

                    let redirect_url = response.data.data.redirect_url;
                    if (status && redirect_url && redirect_url !== '#'){
                        if (redirect_url === 'return_to_list'){
                            this.tabClicked(this.list_items_tab_name);
                            this.is_selecting_items = true;
                        }else{
                            window.location.href = redirect_url;
                        }
                    }else{

                        this.presentToast((status ? "Success!" : "Error!"),
                            msg,
                            (status ? 'success' : 'danger'),
                            3000,
                            true,
                            true);

                        let error = response.data.data.error;

                        if (!status && error === 'no_permission'){
                            window.flash(msg, 'danger');
                            location.reload();
                        }
                    }
                })
            }else{
                console.log("Event isn\'t defined!");
            }
        },
        clearSelectedItems(){
            this.should_hide_unselected = false;
            this.selected_ids.splice(0);
            this.selected_objects.splice(0);
        },
        hideUnselectedItems(){
            this.should_hide_unselected = !this.should_hide_unselected;
        },
        resetSelectingItems(){
            if (this.user_info.user_meta.hasOwnProperty('is_actions_bar_opened')){
                this.is_actions_bar_opened = !!this.user_info.user_meta.is_actions_bar_opened;
            }

            this.is_selecting_items = true;
            this.selected_items_mode = 1;

            this.clearSelectedItems();
        },
        shouldToggleSelecting(event){
            if (event){ event.preventDefault(); }
            this.is_actions_bar_opened = !this.is_actions_bar_opened;
            let is_actions_bar_opened = this.is_actions_bar_opened ? 1 : 0;
            this.set_usermeta('is_actions_bar_opened', is_actions_bar_opened, "", false);

            if (this.user_info && this.user_info.user_meta.hasOwnProperty('is_actions_bar_opened')){
                this.$set(this.user_info.user_meta, 'is_actions_bar_opened', is_actions_bar_opened);
            }

            this.is_selecting_items = true;

            if (!this.is_selecting_items){
                this.selected_ids.splice(0);
                this.selected_objects.splice(0);
            }
        },
        addToSelectedList(item, event, unique_value='id', name_value='name'){
            if (event){ event.preventDefault(); }
            if (item && item.hasOwnProperty(unique_value) && item[unique_value] !== undefined){
                let item_id = item[unique_value];
                let found_index = this.selected_ids.indexOf(item_id);

                if (found_index < 0){
                    let item_name = item[name_value];
                    let new_item = {
                        id: item_id,
                        uid: item.uid ?? '',
                        customer_id: item.customer_id ?? null,
                        available_quantity: item.available_quantity,
                        name: item_name
                    }
                    this.selected_ids.push(item_id);
                    this.selected_objects.push(new_item);
                }else{
                    this.selected_ids.splice(found_index, 1);
                    this.selected_objects.splice(found_index, 1);
                }
            }

        },
        toggleTag: function(tag) {
            if (this.isSelected(tag)) {
                this.selected_tags = this.selected_tags.filter((tagItem) => tagItem !== tag);
            } else {
                this.selected_tags.push(tag);
            }
        },
        setSelectedOrder: function(order) {
            this.selected_bulk_order = order;
        },
        setSelectedStorage: function(storage) {
            this.selected_bulk_storage = storage;
        },
        isSelected: function(tag) {
            return this.selected_tags.includes(tag);
        },
        toggleAccordion: function(event) {
            this.selected_tags = [];
            this.selected_bulk_order = {name: 'Choose Order'};
            this.selected_bulk_storage = {name: 'Choose Storage'};
        },

        orderSelectDetails({ uid, name }){
            return (uid ? (uid + ': ') : '') + name;
        },
        getRequiredCustomerID: function (only_customer=false) {
            let required_customer_id = null;
            if (!only_customer && this.selected_objects && this.selected_objects.length > 0) {
                required_customer_id = this.selected_objects[0].customer_id;
            }
            let item_type = this.item_type ? this.item_type : '';

            if (['sp', 'assetflow'].includes(item_type)){
                if (this.selected_customer && this.selected_customer.id) {
                    required_customer_id = this.selected_customer.id;
                }
            }else if (item_type === 'customer' && this.current_customer){
                required_customer_id = this.current_customer.id;
            }


            if (this.item_info && this.item_info.hasOwnProperty('customer_info') && this.item_info.customer_info) {
                required_customer_id = this.item_info.customer_info.id;
            }
            if (this.order && this.order.hasOwnProperty('customer') && this.order.customer) {
                required_customer_id = this.order.customer.id;
            }
            return required_customer_id;
        },
        addNewOrder(){
            let asset = {
                order_type: 1,
                status: 0
            };
            asset.customer_id = this.bulk_actions_customer_id;
            console.log("asset");
            console.log(asset);

            this.$refs['add-new-order'].setSelectedItem(asset);
            this.$bvModal.show('create-orders');
        },
    }
}
