export const ViewOptions = [
    {
        id: 0,
        name: 'Overview'
    },
    {
        id: 1,
        name: 'Usage'
    },
    {
        id: 3,
        name: 'Acquisition'
    },
    {
        id: 4,
        name: 'Disposition'
    },
    {
        id: 2,
        name: 'Storage'
    },
    {
        id: 5,
        name: 'Sustainability'
    },
    {
        id: 6,
        name: 'Label'
    },
];
