<template>
    <div class="HandleTableCell" v-if="required_field" :class="row.hasOwnProperty('calculated_class') ? row.calculated_class : ''">
        <div v-if="required_field.type === 'single-select'">
            <multiselect
                v-model="row[table_header]"
                class="my-multiselect"
                :placeholder="(required_field.placeholder || ('Select ' + required_field.label))"
                :multiple="required_field.hasOwnProperty('is_multi') && required_field.is_multi"
                :options="required_field.options"
                :track-by="required_field.track_by || 'id'"
                :label="required_field.option_label || 'name'"
                :custom-label="required_field.custom_label || CustomMSLabel"
                :allow-empty="required_field.allow_empty || false"
                @input="required_field.onSelectChanged ? required_field.onSelectChanged(table_header, row) : selectChanged(table_header, row)"
            >
            </multiselect>
            <small v-if="row[table_header] && row[table_header].hasOwnProperty('has_note') && row[table_header].has_note"
                   class="form-text text-muted default-value-block text-left"> {{ row[table_header].has_note }}</small>
        </div>


        <div class="form-group" v-else-if="['text', 'number'].includes(required_field.type)">
            <input
                v-if="required_field.hasOwnProperty('is_disabled') && required_field.is_disabled"
                :value="row[table_header]"
                :type="required_field.type"
                class="form-control"
                :disabled="required_field.hasOwnProperty('is_disabled') && required_field.is_disabled"
                :placeholder="(required_field.placeholder || ('Enter ' + required_field.label))" />

            <input v-else
                v-model="row[table_header]"
                :type="required_field.type"
                class="form-control"
                :placeholder="(required_field.placeholder || ('Enter ' + required_field.label))" />

            <small v-show="!row[table_header] && required_field.hasOwnProperty('default_value_note') && required_field.default_value_note"
                class="form-text text-muted default-value-block text-left">{{ required_field.default_value_note }}</small>
            <small v-show="required_field.hasOwnProperty('field_user_note') && required_field.field_user_note"
                class="form-text text-muted default-value-block text-left">{{ required_field.field_user_note }}</small>
        </div>
        <div v-else-if="required_field.type === 'datepicker'">
            <date-picker
                v-model="row[table_header]"
                lang="en" :first-day-of-week="1"
                type="datetime"
                format="DD-MM-YYYY"
                value-type="DD-MM-YYYY"
                :placeholder="(required_field.placeholder || ('Select ' + required_field.label))" />
        </div>

        <span v-else>{{ row[table_header] }}</span>

    </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';

export default {
    mixins: [multiSelect],
    components: {DatePicker},
    name: 'HandleTableCell',
    props: {
        data: {},
        row: {},
        table_header: {},
        required_field: {},
        selected_object_type: {},
    },
    data(){
        return {
            my_options: [
                {id: null, name: 'Empty'},
                {id: 1, name: 'item 1'},
                {id: 2, name: 'item 2'},
            ],
        }
    },
    methods:{

        selectChanged(header, row) {
            console.log(header);
            console.log(row);
        },
    }
}
</script>
