<template>
    <Layout>
        <move-assets-modal
            ref="change-addresses-modal"
            item_type="addresses"
            modal_title="Move All Address Assets"
            list_title="Destination Location"
            button_title="Move Assets"
            :selected_item="selected_folder"
            :options_list="all_folders"
            caution_title="I understand this bulk action will update all the selected items and cannot be undone"
            @on-modal-save="moveAddressContent"
            @on-modal-cancel="$refs['my-page-content'].selected_asset_id = null"
        >
        </move-assets-modal>

        <move-assets-modal
            ref="change-address_locations-modal"
            item_type="address_locations"
            modal_title="Change Location"
            list_title="Destination Location"
            button_title="Move Assets"
            :selected_item="selected_folder"
            :options_list="all_folders"
            @on-modal-save="moveAddressContent"
            @on-modal-cancel="$refs['my-page-content'].selected_asset_id = null"
        >
        </move-assets-modal>

        <split-assets-modal
            ref="split-asset-modal"
            :selected_asset="selected_asset"
            @after-split-asset="getList"
            @on-modal-cancel="$refs['my-page-content'].selected_asset_id = null"
        />

        <duplicate-assets-modal
            ref="duplicate-asset-modal"
            :selected_asset="selected_asset"
            @after-duplicate-asset="getList"
            @on-modal-cancel="$refs['my-page-content'].selected_asset_id = null"
        />

        <add-new-modal
            ref="add-new-address"
            item_type='address'
            modal_title="Create New Address"
            @on-new-item-adding="addressLocationChange"
            @on-modal-cancel="$refs['my-page-content'].selected_asset_id = null"
        ></add-new-modal>

        <add-new-modal
            ref="add-new-location"
            item_type='location'
            modal_title="Create New Location"
            @on-new-item-adding="addressLocationChange"
            @on-modal-cancel="$refs['my-page-content'].selected_asset_id = null"
        ></add-new-modal>



        <b-modal id="create-tag-modal"
                 ref="create-new-tag"
                 :title="(required_action === 'edit-tag' ? 'Update' : 'Create') + ' Tag'"
                 title-class="font-18"
                 @hide="$refs['my-page-content'].selected_asset_id = null"
                 hide-footer>

            <b-form-group label="Tag Name" label-for="tag_name">
                <b-form-input
                    v-model="folder_name"
                    type="text"
                    id="tag_name"
                    placeholder="Tag Name..."
                    class="form-control"
                ></b-form-input>
            </b-form-group>

            <b-form-group label="Tag Description" label-for="tag_description">
                <b-form-textarea
                    v-model="folder_description"
                    id="tag_description"
                    placeholder="Tag Description..."
                    class="form-control">
                </b-form-textarea>
            </b-form-group>

            <div class="text-right">
                <b-button type="submit"
                          variant="success"
                          v-on:click.once="addTag($event)">
                    {{ required_action === 'edit-tag' ? 'Update' : 'Create' }}</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('create-tag-modal')">Cancel</b-button>
            </div>
        </b-modal>

        <b-modal id="change-tag-modal"
                 ref="change-new-tag"
                 title="Change Tag"
                 title-class="font-18"
                 @hide="$refs['my-page-content'].selected_asset_id = null"
                 hide-footer>

            <b-form-group label="Destination Tag" label-for="destination_tags_list">
                <b-form-select
                    id="destination_tags_list"
                    name="destination_tags_list"
                    v-model="selected_destination_folder"
                    :options="tags_list"
                >
                </b-form-select>
            </b-form-group>

            <div class="text-right">
                <b-button type="submit"
                          variant="success"
                          v-on:click.once="performChangeTag">
                    Move Assets</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('change-tag-modal')">Cancel</b-button>
            </div>
        </b-modal>

        <b-modal id="change-group-modal"
                 ref="change-new-group"
                 title="Add To Group"
                 title-class="font-18"
                 @hide="$refs['my-page-content'].selected_asset_id = null"
                 hide-footer>

            <b-form-group label="Destination Group" label-for="destination_groups_list">
                <b-form-select
                    id="destination_groups_list"
                    name="destination_groups_list"
                    v-model="selected_destination_group"
                    :options="groups_list"
                >
                </b-form-select>
            </b-form-group>

            <div class="text-right">
                <b-button type="submit"
                          variant="success"
                          v-on:click.once="performChangeGroup()">
                    Add To Group</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('change-group-modal')">Cancel</b-button>
            </div>
        </b-modal>

        <b-modal id="create-folder-modal"
                 ref="create-new-folder"
                 :title="(required_action === 'edit-folder' ? 'Update' : 'Create') + ' Folder'"
                 title-class="font-18"
                 @hide="$refs['my-page-content'].selected_asset_id = null"
                 hide-footer>

            <b-form-group label="Parent Folder" label-for="folders_list">
                <b-form-select
                    id="folders_list"
                    name="folders_list"
                    v-model="selected_folder"
                    :options="folders_list"
                >
                </b-form-select>
            </b-form-group>

            <b-form-group label="Folder Name" label-for="folder_name">
                <b-form-input
                    v-model="folder_name"
                    type="text"
                    id="folder_name"
                    placeholder="Folder Name..."
                    class="form-control"
                ></b-form-input>
            </b-form-group>

            <b-form-group label="Folder Description" label-for="folder_description">
                <b-form-textarea
                    v-model="folder_description"
                    id="folder_description"
                    placeholder="Folder Description..."
                    class="form-control">
                </b-form-textarea>
            </b-form-group>

            <div class="text-right">
                <b-button type="submit"
                          variant="success"
                          v-on:click.once="addFolder($event)">
                    {{ required_action === 'edit-folder' ? 'Update' : 'Create' }}</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('create-folder-modal')">Cancel</b-button>
            </div>
        </b-modal>

        <b-modal id="change-folder-modal"
                 ref="change-new-folder"
                 title="Change Folder"
                 title-class="font-18"
                 @hide="$refs['my-page-content'].selected_asset_id = null"
                 hide-footer>

            <b-form-group label="Destination Folder" label-for="destination_folders_list">
                <b-form-select
                    id="destination_folders_list"
                    name="destination_folders_list"
                    v-model="selected_destination_folder"
                    :options="folders_list"
                >
                </b-form-select>
            </b-form-group>

            <div class="text-right">
                <b-button type="submit"
                          variant="success"
                          v-on:click.once="performChangeFolder">
                    Move Assets</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('change-folder-modal')">Cancel</b-button>
            </div>
        </b-modal>

        <div class="" id="wrapper">

                <folders-sidebar
                    :sidebar_list="treeList"
                    :is_listing_sidebar="is_listing_sidebar"
                    :filter_type="this.current_groupBy"
                    :item_type="item_type"
                    :is_group="is_group"
                    :selected_item="selected_item"
                    :selected_customer="selected_customer"
                    @on-group-changing="getList"
                    @on-filter-changing="getList"
                    @on-folder-creation="createFolder"
                    @on-tag-creation="createTag"
                    @on-tag-editing="editTag"
                    @on-add-location="addLocation"
                    @on-untag-assets="untagAssignAssets"
                    @on-folder-moving="moveFolder"
                    @on-folder-editing="editFolder"
                    @on-folder-deletion="deleteItem"
                    @on-content-moving="moveContent"
                >
                </folders-sidebar>

                <page-content
                    ref="my-page-content"
                    :shared_info_string="shared_info_string"
                    :items_list="itemsList"
                    :item_type="item_type"
                    :request_meta="request_meta"
                    :breadcrumb_items="breadcrumb_items"
                    :groupBy="current_groupBy"
                    :selected_item="selected_item"
                    :tags_list="tags_list"
                    :is_group="is_group"
                    :totals_info="totals_info"
                    @on-folder-creation="createFolder"
                    @on-tag-creation="createTag"
                    @on-updating-tags="getTagsList"
                    @on-change-folder="changeFolder"
                    @on-change-group="changeGroup"
                    @on-split-asset="splitAsset"
                    @on-duplicate-asset="duplicateAsset"
                    @on-item-deleted="getList"
                    @on-pagination-changed="getList"
                    @on-change-feature-view="featureViewChanged"
                    @on-search-keyword-change="startSearching"
                    @on-adding-new-asset="getList(selected_item)"
                    @on-export-assets="exportAssets"
                    @on-fetch-totals="fetchTotals"
                    @on-fetch-assets="fetchAssets"
                    @on-bulk-action="applyBulkAction"
                    @on-export-asset-images="exportAssetImages"

                    :total="total"
                    :listLoading="assetsListLoading"
                    :myHandleQueryItems="handleQueryItems"
                >
                    <template v-slot:current-page-filters="{ feature_views }">
                        <div class="current-page-filters">
                            <span v-if="selected_ownership_type && selected_ownership_type.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                                <span class="flex-grow-1 text-truncate text-white">Ownership: <b>{{ selected_ownership_type.name }}</b></span>
                                <button type="button" class="close ml-1" @click="resetFilter('ownership_type')"><i class="text-white bx font-size-22 bx-x"></i></button>
                            </span>
                            <span v-if="selected_ownership_status && selected_ownership_status.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                                <span class="flex-grow-1 text-truncate text-white">Type: <b>{{ selected_ownership_status.name }}</b></span>
                                <button type="button" class="close ml-1" @click="resetFilter('ownership_status')"><i class="text-white bx font-size-22 bx-x"></i></button>
                            </span>
                            <span v-if="selected_status && selected_status.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                                <span class="flex-grow-1 text-truncate text-white">Usage: <b>{{ selected_status.name | titleize }}</b></span>
                                <button type="button" class="close ml-1" @click="resetFilter('status')"><i class="text-white bx font-size-22 bx-x"></i></button>
                            </span>
                            <span v-if="selected_item && selected_item.id !== null && (selected_item.id !== 0 || selected_item.hasOwnProperty('type') || (selected_item.hasOwnProperty('name') && selected_item.name === 'Today'))" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                                <span class="flex-grow-1 text-truncate text-white">{{ current_groupBy_title | titleizeToSingle }}: <b>{{ selected_item.name | titleize }}</b></span>
                                <button type="button" class="close ml-1" @click="resetFilter('item')"><i class="text-white bx font-size-22 bx-x"></i></button>
                            </span>
                            <span v-if="!(current_module && current_module.hasOwnProperty('is_managed_storage') && current_module.is_managed_storage) && feature_views.selected_view && feature_views.selected_view.id === 2" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                                <span class="flex-grow-1 text-truncate text-white">In Storage</span>
                                <button type="button" class="close ml-1" title="Change 'View' to list all items." v-b-tooltip.bottom><i class="text-white bx font-size-22 bx-x"></i></button>
                            </span>

                            <span v-if="search_keyword && search_keyword !== ''" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                                <span class="flex-grow-1 text-truncate text-white">Keywords: <b>{{ search_keyword | titleize }}</b></span>
                                <button type="button" class="close ml-1" @click="$refs['my-page-content'].setSearchKeyword('')"><i class="text-white bx font-size-22 bx-x"></i></button>
                            </span>


                        </div>

                    </template>
                    <template v-slot:customer-header-content>
                        <div v-if="is_managed_storage_module" class="customers-list">
                            <div class="main-root-top">
                                <h3 class="filter_option header-customer">{{ selected_sp.name | titleize }}</h3>
                                <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                                    <li v-for="(sp_item, index) in sps_list" :class="'root-top-menu' + (selected_sp && selected_sp.id === sp_item.id ? ' active' : '')">
                                        <a @click="setSelectedSp(sp_item)">
                                            {{ sp_item ? sp_item.name : '-' }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <span class="arrow-right-icon"></span>

                            <div class="main-root-top">
                                <h3 class="filter_option header-ownership-status">{{ selected_ownership_status.name | titleize }}</h3>
                                <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                                    <li v-for="(ownership, key) in ownershipStatusOptions"
                                        :key="key"
                                        class="root-top-menu">
                                        <a @click="setSelectedOwnershipStatus(ownership)">{{ ownership.name | titleize }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-else class="customers-list">


                            <div class="main-root-top" v-if="has_departments">
                                <h3 class="filter_option header-department" :class="[0,1].includes(departments_list.length) ? 'single-option' : ''">{{ selected_department.name | titleize }}</h3>
                                <ul class="root-folder filters_list" style="display: none; padding: 15px;" v-if="departments_list.length !== 1">
                                    <li v-for="(department_item, index) in departments_list" :class="'root-top-menu' + (selected_department && selected_department.id === department_item.id ? ' active' : '')">
                                        <a @click="setSelectedDepartment(department_item)">
                                            {{ department_item ? department_item.name : '-' }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <span class="arrow-right-icon" v-if="has_departments"></span>

                            <div class="main-root-top" v-if="is_group">
                                <h3 class="filter_option header-customer">{{ selected_customer.name | titleize }}</h3>
                                <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                                    <li v-for="(customer_item, index) in customers_list" :class="'root-top-menu' + (selected_customer && selected_customer.id === customer_item.id ? ' active' : '')">
                                        <a @click="setSelectedCustomer(customer_item)">
                                            {{ customer_item ? customer_item.name : '-' }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <span class="arrow-right-icon" v-if="is_group"></span>

                            <div class="main-root-top">
                                <h3 class="filter_option header-ownership-type">{{ selected_ownership_type.name | titleize }}</h3>
                                <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                                    <li v-for="(ownership, key) in ownershipTypeOptions"
                                        :key="key"
                                        class="root-top-menu">
                                        <a @click="setSelectedOwnershipType(ownership)">{{ ownership.name | titleize }}</a>
                                    </li>
                                </ul>
                            </div>
                            <span class="arrow-right-icon"></span>
                            <div class="main-root-top">
                                <h3 class="filter_option header-ownership-status">{{ selected_ownership_status.name | titleize }}</h3>
                                <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                                    <li v-for="(ownership, key) in ownershipStatusOptions"
                                        :key="key"
                                        class="root-top-menu">
                                        <a @click="setSelectedOwnershipStatus(ownership)">{{ ownership.name | titleize }}</a>
                                    </li>
                                </ul>
                            </div>

                            <span v-if="current_groupBy !== 2" class="arrow-right-icon"></span>
                            <div v-if="current_groupBy !== 2" class="main-root-top">
                                <h3 class="filter_option header-asset-status">{{ selected_status.name | titleize }}</h3>
                                <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                                    <li v-for="(status, key) in statusesList"
                                        :key="key"
                                        class="root-top-menu">
                                        <a @click="setSelectedStatus(status)">{{ status.name | titleize }}</a>
                                    </li>
                                </ul>
                            </div>
                            <span class="arrow-right-icon"></span>
                            <div class="main-root-top">
                                <h3 class="header-selected-item">{{ (selected_item ? (selected_item.name) : '') | titleize }}</h3>
                            </div>

                        </div>
                    </template>

                </page-content>

            </div>
    </Layout>
</template>

<script>
import Resource from '@/api/resource';
import Layout from "@/layouts/main";

import UserMeta from "@/mixins/usermeta.mixin";
import Global from "@/mixins/global.mixin";
import AssetActions from "@/mixins/assetActions.mixin";
import {StatusesList} from "./Extras/StatusesList";
import {EditedStatusesList} from "./Extras/EditedStatusesList";
import {OwnershipTypeOptions} from "../form_data/OwnershipTypeOptions";
import {OwnershipStatusOptions} from "../form_data/OwnershipStatusOptions";

import {AvailableSectors} from "../form_data/AvailableSectors";
import {SustainabilityActions} from "../form_data/SustainabilityActions";
import {FDPStatuses} from "../form_data/FDPStatuses";
import {ReturnDatesList} from "../form_data/ReturnDatesList";
import {CreatedDatesList} from "@/views/assetflow/form_data/CreatedDatesList";
import {AssetTypes} from "../form_data/AssetTypes";

import FoldersSidebar from "./subItems/FoldersSidebar";
import PageContent from "./subItems/PageContent";
import AddNewModal from "../customers/extras/AddNewModal";
import MoveAssetsModal from "../customers/extras/MoveAssetsModal";
import SplitAssetsModal from "../customers/extras/SplitAssetsModal";
import DuplicateAssetsModal from "../customers/extras/DuplicateAssetsModal";

const assetResource = new Resource('api/assetflow/assets');
const folderResource = new Resource('api/assetflow/folders');
const categoryResource = new Resource('api/assetflow/categories');
const addressResource = new Resource('api/assetflow/address_locations');
const tagsResource = new Resource('api/assetflow/tags');
const assetGroupsResource = new Resource('api/assetflow/asset_groups');
const usersResource = new Resource('api/assetflow/users');
const supplierResource = new Resource('api/assetflow/suppliers');
const eventResource = new Resource('api/assetflow/events');
const departmentResource = new Resource('api/assetflow/departments');
const characterResource = new Resource('api/assetflow/characters');


const customFieldsResource = new Resource('api/assetflow/customFields');
const dispositionResource = new Resource('api/assetflow/dispositionList');
import {ViewOptions} from "@/views/assetflow/form_data/ViewOptions";


import AMDepartmentShared from "@/mixins/AMDepartmentShared.mixin";


String.prototype.repeat = String.prototype.repeat || function(n){
    n = n || 1;
    return Array(n+1).join(this);
}

/**
 * Advanced table component
 */
export default {
    name: "AssetList",
    mixins: [UserMeta, Global, AssetActions, AMDepartmentShared],
    props: ['item_id', 'item_type', 'shared_info_string', 'is_group'],
    components: {
        DuplicateAssetsModal,
        SplitAssetsModal,
        MoveAssetsModal,
        AddNewModal,
        PageContent,
        FoldersSidebar,
        Layout
    },
    data() {
        return {
            myViewOptions: ViewOptions,
            selectedLayoutIndex: null,
            title: "Assets",
            items: [
                {
                    text: "Assets",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            total:0,
            is_export:0,
            export_type:'pdf',
            template_id:10,
            is_bulk_action:0,
            bulk_action:'',
            other_info:null,
            totals_only:0,
            top_filters_text:'',
            request_meta:null,
            treeList: null,
            itemsList:[],
            totals_info: {total_quantity: 0, total_cost: 0},
            sortedItemsList: null,
            current_groupBy: 1,
            current_groupBy_title: 'Folders',
            is_listing_sidebar: false,
            selected_folder: null,
            required_method: null,
            folder_name: null,
            folder_description: null,
            all_folders: [],
            flat_result: [],
            quantities_list: [],
            folders_list: [],
            locations_list: [],
            tags_list: [],
            folders_list_copy: [],
            groups_list: [],
            selected_destination_group: null,
            selected_destination_folder: null,
            selected_folder_destination_folder: null,
            selected_asset: null,
            required_action: null,
            selected_item: null,
            breadcrumb_items: [
                {
                    text: window.AF_NAME,
                    href: "/"
                }
            ],
            customer_item: {
                id:0,
                value: 0,
                text: window.AF_NAME,
                name: window.AF_NAME,
            },
            groupBy_titles:{
                '0': 'All',
                '1': 'Folders',
                '2': 'Usage',
                '3': 'Categories',
                '4': 'Tags',
                '5': 'Users',
                '6': 'Addresses',
                '7': 'Suppliers',
                '8': 'Industry\\Sector',
                '9': 'FDP',
                '15': 'FDP Status',
                '16': 'Characters',
                '17': 'Return Dates',
                '12': 'Events',
                '10': 'Custom',
                '18': 'Created By',
                '19': 'Created Date'
            },
            selected_customer: {
                id: null,
                name: 'Customer'
            },
            customers_list: [],
            selected_sp: {
                id: null,
                name: 'SP'
            },
            sps_list: [],

            selected_status: {
                id: null,
                name: 'All Statuses'
            },
            selected_ownership_type: {
                id: null,
                name: 'All Ownerships'
            },
            selected_ownership_status: {
                id: null,
                name: 'All Assets'
            },
            ownershipTypeOptions: [],
            ownershipStatusOptions: [],
            assetsListLoading:false,
            search_keyword:null,
            filter_type_id: null,
            filtering_options: {},
            preferredView:{ id: 0, name: 'Assets'}
        };
    },

    mounted() {
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            // shall set to all?
            let search_keyword = decodeURI(queryVars["s"]);
            this.search_keyword = search_keyword;
            this.$refs['my-page-content'].setSearchKeyword(search_keyword);
        }

    },
    computed: {
        totalAssetsQty(){
            return this.sortedItemsList ? this.sortedItemsList.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
    },
    created() {

        if (this.is_managed_storage_module){
            let managed_storage_view = {
                id: 2,
                name: 'Managed Storage'
            };
            this.preferredView = managed_storage_view;

            this.getSpsListForAssetManagement();

        }else{
            this.preferredView = window.USER_INFO.user_meta.preferred_view;
        }




        // if (this.preferredView) {
        //     this.featureViewChanged(this.preferredView);
        // }

        let queryVars = this.getQueryVars();
        if (("s" in queryVars && queryVars["s"] !== '')) {
            this.current_groupBy = 0;
        }
        if (queryVars.hasOwnProperty('v') && queryVars.v !== ''){
            let selected_view_index = this.myViewOptions.map(function(e) { return e.id+""; }).indexOf(queryVars.v);
            if (selected_view_index !== -1){
                this.preferredView = this.myViewOptions[selected_view_index];
            }
        }


        let active_tab = localStorage.getItem('assets_list_active_tab');
        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let t_param = queryVars.t;
            switch (t_param){
                case 'dashboard':
                    active_tab = 'assets_dashboard';
                    break;
                case 'assets':
                    active_tab = 'assets_list';
                    break;
                case 'new':
                    active_tab = 'new_tab';
                    break;
                case 'export':
                    active_tab = 'export_tab';
                    break;
                case 'update':
                    active_tab = 'update_tab';
                    break;
            }
        }
        localStorage.setItem('assets_list_active_tab', active_tab);


        if (this.is_tv_film_asset){
            this.selected_customer.name = 'Production';
        }
        let all_status = {
            id: null,
            name: 'All Statuses',
            value: 'any'
        };
        this.statusesList = [all_status].concat(EditedStatusesList);

        let all_ownershipTypes = {
            id: null,
            name: 'Purchased & Rented'
        };
        this.ownershipTypeOptions = [all_ownershipTypes].concat(OwnershipTypeOptions);

        let all_ownershipStatuses = {
            id: null,
            name: 'All Assets'
        };
        this.ownershipStatusOptions = [all_ownershipStatuses].concat(OwnershipStatusOptions);





        let assets_selected_ownership_type = this.getUserMetaValueIfExist('assets_selected_ownership_type');
        if (assets_selected_ownership_type !== null){
            let found_type = this.ownershipTypeOptions.find((x) => x.id === (assets_selected_ownership_type));
            if (found_type){
                this.selected_ownership_type = found_type;
            }
        }

        let assets_selected_ownership_status = this.getUserMetaValueIfExist('assets_selected_ownership_status');
        if (assets_selected_ownership_status !== null){
            let found_status = this.ownershipStatusOptions.find((x) => x.id === (assets_selected_ownership_status));
            if (found_status){
                this.selected_ownership_status = found_status;
            }
        }

        let assets_selected_status = this.getUserMetaValueIfExist('assets_selected_status');
        if (assets_selected_status !== null){
            let found_status = this.statusesList.find((x) => x.id === (assets_selected_status));
            if (found_status){
                this.selected_status = found_status;
            }
        }

        if (this.is_group){
            this.groupBy_titles = {
                '0': 'All',
                '2': 'Categories',
                '3': 'Usage',
                '10': 'Asset Templates',
                '12': 'Events',
                '15': 'FDP Status',
            };

        }

        this.getCustomersListForGroup();
        document.body.classList.add('is_assets');

        this.getTagsList(true);
        this.getAssetGroupsList();
        this.getFoldersList('addresses', true);
        this.getFoldersList('folders', true);
        this.setGroupByOption();

        this.selectedLayoutIndex = 2;

        let preferredLayout = window.USER_INFO.user_meta.preferred_assets_layout;
        if (preferredLayout && /^\d+$/.test(preferredLayout)){
            this.selectedLayoutIndex = parseInt(preferredLayout);
        }

    },
    methods: {
        featureViewChanged(selected_view=''){

            this.preferredView = selected_view;
            if (selected_view){
                this.getList(this.selected_item);
            }
        },
        resetFilter(required_item=''){
            switch (required_item) {
                case 'ownership_type':
                    let selected_ownership_type = {
                        id: null,
                        name: 'Status'
                    };

                    this.set_usermeta('assets_selected_ownership_type', selected_ownership_type.id, "", false);
                    this.selected_ownership_type = selected_ownership_type;
                    break;
                case 'ownership_status':
                    let selected_ownership_status = {
                        id: null,
                        name: 'Type'
                    };
                    this.set_usermeta('assets_selected_ownership_status', selected_ownership_status.id, "", false);
                    this.selected_ownership_status = selected_ownership_status;

                    break;
                case 'status':
                    let selected_status = {
                        id: null,
                        name: 'Usage'
                    };
                    this.set_usermeta('assets_selected_status', selected_status.id, "", false);
                    this.selected_status = selected_status;


                    break;
                case 'item':
                    this.selected_item = this.customer_item;
                    break;
                case 'selected_view':
                    // shall change filter title, and get in storage items only
                    break;
                default:
                    break;
            }

            this.getList(this.selected_item);
        },
        startSearching(search_keyword){
            this.search_keyword = search_keyword;
            this.getList(this.selected_item);
        },
        resetSelectedStatus: function () {
            this.selected_status = this.statusesList[0];
        },
        async getCustomersListForGroup(for_sidebar= false) {
            return await new Promise( (resolve, reject) => {

                let listQuery = {
                    item_type: this.item_type,
                    item_id: this.item_id
                }
                axios({
                    method:'get',
                    url:'/api/assetflow/customers',
                    baseURL: '/',
                    params: listQuery
                }).then(response => {
                    let items_list = response.data.data.items;
                    if (!for_sidebar){
                        this.customers_list = items_list.map(function (e) {
                            return { id: e.id, name: e.name };
                        });
                        let all_item = {
                            id: null,
                            name: 'All'
                        };
                        this.customers_list.splice(0, 0, all_item);
                    }

                    resolve(items_list);

                }).catch(error => {
                    reject(new Error('Response Error!'));
                }).finally(() => {
                    this.listLoading = false;
                });

            })

        },
        async getSpsListForAssetManagement() {
            return await new Promise( (resolve, reject) => {
                let listQuery = {
                    item_type: this.item_type,
                    item_id: this.item_id
                }
                axios({
                    method:'get',
                    url:'/api/assetflow/service_providers',
                    baseURL: '/',
                    params: listQuery
                }).then(response => {
                    let items_list = response.data.data.items;
                    this.sps_list = items_list.map(function (e) {
                        return { id: e.id, name: e.name };
                    });
                    let all_item = {
                        id: null,
                        name: 'All'
                    };
                    this.sps_list.splice(0, 0, all_item);

                    let assets_selected_sp = this.getUserMetaValueIfExist('assets_selected_sp');
                    if (assets_selected_sp){
                        let found_sp = this.sps_list.find((x) => x.id === (assets_selected_sp));
                        if (found_sp){
                            this.selected_sp = found_sp;
                        }
                    }

                    resolve(items_list);
                }).catch(error => {
                    reject(new Error('Response Error!'));
                }).finally(() => {
                    this.listLoading = false;
                });
            })
        },

        setSelectedCustomer(customer_item){
            this.selected_customer = customer_item;
            if ([12, 13].includes(this.current_groupBy)){
                this.setGroupByOption();
            }else{
                this.getList(this.selected_item);
            }
        },

        setSelectedSp(sp_item){
            this.selected_sp = sp_item;
            let message = "Saved SP {"+sp_item.name+"} Successfully";
            window.flash(message);
            this.set_usermeta('assets_selected_sp', sp_item.id, "", false);
            this.getList(this.selected_item);
        },
        setSelectedStatus(status_item){
            this.selected_status = status_item;
            this.set_usermeta('assets_selected_status', status_item.id, "", false);

            this.getList(this.selected_item);
        },
        setSelectedOwnershipStatus(ownership_item){
            this.selected_ownership_status = ownership_item;
            this.set_usermeta('assets_selected_ownership_status', ownership_item.id, "", false);

            this.getList(this.selected_item);
        },
        setSelectedOwnershipType(ownership_item){
            this.selected_ownership_type = ownership_item;
            this.set_usermeta('assets_selected_ownership_type', ownership_item.id, "", false);

            this.getList(this.selected_item);
        },
        addressLocationChange(new_item){
            // if (new_item){
            //     // should select this? >>
            //     console.log("new_item");
            //     console.log(new_item);
            //
            // }
            this.handleListingAddresses(false);
        },
        refreshList(item_id, required_type='tags'){
            if (required_type === 'tags'){
                this.getTagsList();
            }else if (['addresses', 'address_locations'].includes(required_type)){
                this.handleListingAddresses(false);
            }
            this.delete_after_untag = false;
        },
        addFolder(){
            if (this.required_action === 'edit-folder'){
                let message = "Edited Folder {"+this.folder_name+"} Successfully";
                this.add_new_item('folders', this.selected_folder, this.folder_name, this.folder_description, message, true, this.selected_asset.id);
                this.$bvModal.hide('change-folder-modal');
            }else{
                let message = "Add a New Folder {"+this.folder_name+"} Successfully";
                this.add_new_item('folders', this.selected_folder, this.folder_name, this.folder_description, message, true);
                this.$bvModal.hide('change-folder-modal');
            }

        },
        async deleteItem(item_id='', item_type='folders', event){

            if (item_id !== ''){
                var index, required_item_name;
                if (item_type === 'folders'){
                    index = this.folders_list.map(function(e) { return e.value; }).indexOf(item_id);
                    required_item_name = this.folders_list[index].text;
                }else if (item_type === 'tags'){
                    if (this.treeList) {
                        index = this.treeList.map(function (e) {
                            return e.id;
                        }).indexOf(item_id);
                        required_item_name = this.treeList[index].name;
                    }
                }else if (item_type === 'addresses'){
                    index = 1;
                    required_item_name = item_id.name;
                    item_type = (item_id.address_id ? 'address_locations' : 'addresses')

                    // dont move this from here vv
                    item_id = item_id.id;
                }

                if (index > -1){
                    this.deleteItemByID(item_id, required_item_name, item_type, event)
                        .then((response) => {
                            if (item_type === 'folders'){
                                this.getFoldersList();
                            }else if (['address_locations', 'addresses'].includes(item_type)){
                                this.handleListingAddresses(false);
                            }else{
                                this.getTagsList();
                            }
                        });
                }else{
                    this.presentToast("Error!",
                        'Something went wrong!',
                        'danger',
                        3000,
                        true,
                        true);
                }

            }
        },
        untagAssignAssets(item='', required_type='tags', item_name='tag', event){
            if (required_type === 'tags'){
                this.header_message = 'Untag Assets of ';
                this.confirm_message = 'Are you sure you want to Un-tag Assets of ';
                this.item_name = 'Tag';
                this.untagAssets(item,'tags');
            }else{
                this.header_message = 'Un-Assign Assets of ';
                this.confirm_message = 'Are you sure you want to Un-Assign Assets of ';
                this.item_name = 'Location';
                this.untagAssets(item,'address_locations');
            }
        },
        addLocation(address){
            this.$refs['add-new-location'].setSelectedItem(null);
            this.$refs['add-new-location'].setAddressID(address.id);
            this.$bvModal.show('create-location');
        },
        editTag(tag, item_type='tags'){

            if (item_type === 'addresses'){
                let type = 'address';
                if (tag.address_id){
                    type = 'location';
                    this.$refs['add-new-location'].setAddressID(tag.address_id);
                    this.$refs['add-new-location'].setSelectedItem(tag);
                }else{
                    this.$refs['add-new-address'].setSelectedItem(tag);
                    this.setupAddressLookup();
                }

                this.$bvModal.show('create-'+type);

                return;
            }

            if (tag){
                this.required_action = 'edit-tag';
                this.selected_asset = tag;
                this.folder_name = tag.name;
                this.folder_description = tag.description;
                this.$bvModal.show('create-tag-modal');
            }
        },
        editFolder(folder=null){
            if (folder){
                this.required_action = 'edit-folder';

                if (folder.parent_folder_id !== ''){
                    let index = this.folders_list.map(function(e) { return e.value; }).indexOf(folder.parent_folder_id);
                    if (this.folders_list[index]){
                        this.selected_folder = this.folders_list[index].value;
                    }
                }else{
                    this.selected_folder = this.folders_list[0].value;
                }
                this.selected_asset = folder;
                this.folder_name = folder.name;
                this.folder_description = folder.description;

                this.$bvModal.show('create-folder-modal');
            }
        },
        moveAddressContent(type, item, destination_item, accepted_caution = false){
            if (accepted_caution){
                let message = "Moved Content Of "+type+" {"+item.name+"} Successfully";
                this.move_content_of(type, item.id, destination_item, message)

                this.$bvModal.hide('change-'+type+'-modal')
                this.handleListingAddresses(false);
                this.required_action = null;
            }else{
                this.$refs['change-'+type+'-modal'].showCautionError(true);
            }

        },
        performChangeGroup(){
            // shall set request to remove from group
            let message = "Added {"+this.selected_asset.name+"} to group {"+this.selected_asset.name+"} Successfully";
            this.set_item_value('assets', this.selected_asset.id, 'asset_group_id', this.selected_destination_group, message, false);
            this.$bvModal.hide('change-group-modal');
            this.required_action = null;

            // shall refresh asset info in page content

            this.$refs['my-page-content'].refreshAssetItem(this.selected_asset, 'asset_group_id', this.selected_destination_group);
        },
        performChangeTag(){
            if (this.required_action === 'moveContent'){
                let message = "Moved Content Of Tag {"+this.selected_asset.name+"} Successfully";
                this.move_content_of('tags', this.selected_asset.id, this.selected_destination_folder, message, true)
            }
            this.$bvModal.hide('change-tag-modal');

            this.required_action = null;
        },
        performChangeFolder(){
            let name = Vue.options.filters.titleizeToSingle(this.change_folder_item_type);

            if (this.required_action === 'split'){
                this.$bvModal.hide('split-asset-modal');
                this.required_action = null;
                return;
            }else if (this.required_action === 'duplicate'){
                this.$bvModal.hide('duplicate-asset-modal');
                this.required_action = null;
                return;
            }else if (this.required_action === 'move'){
                let message = "Changed Folder of "+name+" {"+this.selected_asset.name+"} Successfully";
                let key = this.change_folder_item_type === 'folders' ? 'parent_folder_id' : 'folder_id';
                this.set_item_value(this.change_folder_item_type, this.selected_asset.id, key, this.selected_destination_folder, message, true);
            }else if (this.required_action === 'moveContent'){
                let message = "Moved Content Of Folder {"+this.selected_asset.name+"} Successfully";
                this.move_content_of(this.change_folder_item_type, this.selected_asset.id, this.selected_destination_folder, message, true)
            }

            this.$bvModal.hide('change-folder-modal');

            this.required_action = null;
        },
        moveContent(folder=null, item_type='folders'){

            this.required_action = 'moveContent';
            this.change_folder_item_type = item_type;
            this.selected_asset = folder;
            this.selected_destination_folder = folder.id ? folder.id : null;
            this.selected_folder = folder.id;
            if (item_type === 'folders'){
                this.$bvModal.show('change-folder-modal');
            }else if(item_type === 'tags'){
                this.$bvModal.show('change-tag-modal');
            }else{
                // TODO:// SHOULD show caution
                this.$refs['change-'+item_type+'-modal'].set_item(folder);
                this.$refs['change-'+item_type+'-modal'].setGroupedOptions();
                this.$bvModal.show('change-'+item_type+'-modal');
            }
        },
        changeFolder(asset=null){
            this.required_action = 'move';
            this.change_folder_item_type = 'assets';
            this.selected_asset = asset;
            this.selected_destination_folder = asset.parent_folder_id ? asset.parent_folder_id : null;
            this.selected_folder = asset.parent_folder_id;
            this.$bvModal.show('change-folder-modal');
        },
        changeGroup(asset=null){
            this.required_action = 'changeGroup';
            this.change_folder_item_type = 'assets';
            this.selected_asset = asset;
            this.selected_destination_folder = asset.asset_group_id ? asset.asset_group_id : null;
            this.selected_folder = asset.asset_group_id;
            this.$bvModal.show('change-group-modal');
        },
        splitAsset(asset=null){
            this.required_action = 'split';
            this.change_folder_item_type = 'assets';
            this.selected_asset = asset;
            this.$bvModal.show('split-asset-modal');
        },
        duplicateAsset(asset=null){
            this.required_action = 'duplicate';
            this.change_folder_item_type = 'assets';
            this.selected_asset = asset;
            this.$bvModal.show('duplicate-asset-modal');
        },
        moveFolder(folder=null){
            this.required_action = 'move';
            this.change_folder_item_type = 'folders';
            this.selected_asset = folder;
            this.selected_destination_folder = folder.parent_folder_id ? folder.parent_folder_id : null;
            this.selected_folder = folder.id;
            this.$bvModal.show('change-folder-modal');
        },
        addTag(){
            if (this.required_action === 'edit-tag'){
                let message = "Edited Tag {"+this.folder_name+"} Successfully";
                this.add_new_item('tags', 0, this.folder_name, this.folder_description, message, true, this.selected_asset.id);
            }else{
                let message = "Add a New Tag {"+this.folder_name+"} Successfully";
                this.add_new_item('tags', 0, this.folder_name, this.folder_description, message, true);
            }
            this.$bvModal.hide('create-tag-modal');
        },
        createTag(){
            this.required_action = 'create-tag';
            this.$bvModal.show('create-tag-modal');
        },
        newAsset(){
            this.$refs['add-new-asset'].setSelectedItem(null);
            this.$bvModal.show('create-asset');
        },
        createFolder(parent_id=''){
            this.required_action = 'create-subfolder';
            if (parent_id !== ''){
                let index = this.folders_list.map(function(e) { return e.value; }).indexOf(parent_id);
                if (this.folders_list[index]){
                    this.selected_folder = this.folders_list[index].value;
                }
            }else{
                this.selected_folder = this.folders_list[0].value;
            }

            this.$bvModal.show('create-folder-modal');
        },
        handleListingFolders() {
            this.current_groupBy = 1;
            this.current_groupBy_title = this.groupBy_titles["1"];
            this.is_listing_sidebar = true;
            this.getFoldersList();
        },
        handleListingStatuses() {
            this.current_groupBy_title = this.groupBy_titles[this.current_groupBy+''];
            this.is_listing_sidebar = true;
            this.getStatusList();
        },
        handleListingCategories() {
            this.current_groupBy = 3;
            this.current_groupBy_title = this.groupBy_titles['3'];
            this.is_listing_sidebar = true;
            this.getFoldersList('categories');
        },

        handleListingUsers(){
            this.current_groupBy = 5;
            this.current_groupBy_title = this.groupBy_titles['5'];
            this.is_listing_sidebar = true;
            this.getUsersList();
        },
        handleListingAddresses(is_listing_sidebar=true){
            this.current_groupBy = 6;
            this.current_groupBy_title = this.groupBy_titles['6'];
            this.is_listing_sidebar = is_listing_sidebar;
            this.getFoldersList('addresses', false);
        },
        handleListingTags() {
            this.current_groupBy = 4;
            this.current_groupBy_title = this.groupBy_titles['4'];
            this.is_listing_sidebar = true;
            this.getTagsList();
        },
        handleListingSuppliers(){
            this.current_groupBy = 7;
            this.current_groupBy_title = this.groupBy_titles['7'];
            this.is_listing_sidebar = true;
            this.getFoldersList('suppliers');
        },

        handleListingItems(item_type, current_groupByID){
            this.current_groupBy = current_groupByID;
            this.current_groupBy_title = this.groupBy_titles[''+current_groupByID];
            this.is_listing_sidebar = true;
            this.getUsersList(item_type);
        },
        handleListingEvents(){
            this.current_groupBy = 12;
            this.current_groupBy_title = this.groupBy_titles['12'];

            if (this.is_group && !this.selected_customer.id){
                this.treeList = [];

                this.setSelectedItemForQueue();
                this.getList(this.selected_item);

            }else{
                this.is_listing_sidebar = true;
                this.getUsersList('events');
            }
        },
        handleListingDepartments(){
            this.current_groupBy = 13;
            this.current_groupBy_title = this.groupBy_titles['13'];

            if (this.is_group && !this.selected_customer.id){
                this.treeList = [];
                this.setSelectedItemForQueue();
                this.getList(this.selected_item);
            }else{
                this.is_listing_sidebar = true;
                this.getUsersList('departments');
            }
        },
        handleSelectedFilter() {

            if (this.is_group){
                // shall handle group filters

                switch (this.current_groupBy) {
                    case 0:
                        this.handleListingStatuses();
                        break;
                    case 2:
                        this.handleListingStatuses();
                        break;
                    case 3:
                        this.handleListingCategories();
                        break;
                    case 10:
                        // asset types
                        this.current_groupBy_title = this.groupBy_titles[this.current_groupBy+''];
                        this.is_listing_sidebar = true;
                        this.getCustomFieldsList();
                        break;
                    case 12:
                        this.handleListingEvents();
                        break;
                    case 13:
                        this.handleListingDepartments();
                        break;
                    case 14:
                    case 15:
                        this.handleListingStatuses();
                        break;

                }
            }else{
                // handle production/customer filters
                if (!this.sectors_list || this.sectors_list.length === 0){
                    if (this.current_groupBy === 8){
                        this.current_groupBy = 0;
                    }
                }
                switch (this.current_groupBy) {
                    case 0:
                        this.handleListingStatuses();
                        break;
                    case 1:
                        this.handleListingFolders();
                        break;
                    case 2:
                        this.handleListingStatuses();
                        break;
                    case 3:
                        this.handleListingCategories();
                        break;
                    case 4:
                        this.handleListingTags();
                        break;
                    case 5:
                        this.handleListingUsers();
                        break;
                    case 6:
                        this.handleListingAddresses();
                        break;
                    case 7:
                        this.handleListingSuppliers();
                        break;
                    case 8:
                        this.handleListingStatuses();
                        break;
                    case 9:
                        this.handleListingStatuses();
                        break;
                    case 10:
                        this.current_groupBy_title = this.groupBy_titles[this.current_groupBy+''];
                        this.is_listing_sidebar = true;
                        this.getCustomFieldsList();
                        break;
                    case 12:
                        this.handleListingEvents();
                        break;
                    case 13:
                        this.handleListingDepartments();
                        break;
                    case 16:
                        this.handleListingItems('characters', 16);
                        break;
                    case 18:
                        this.handleListingItems('created_by', 18);
                        break;
                    case 14:
                    case 15:
                    case 17:
                    case 19:
                        this.handleListingStatuses();
                        break;
                    default:
                        this.handleListingFolders();
                        break;
                }
            }
        },
        setGroupByOption(){
            let queryVars = this.getQueryVars();
            if ("queue" in queryVars && queryVars["queue"] !== '') {
                let filtering_option = queryVars["queue"];
                let filtering_item_id = "id" in queryVars && queryVars["id"] !== '' ? queryVars["id"] : null;

                this.filtering_options = {
                    item_id: filtering_item_id,
                    queue: filtering_option,
                }

                switch (filtering_option){
                    case "status":
                        this.current_groupBy = 2;
                        this.handleListingStatuses();
                        break;
                    case "category":
                        this.handleListingCategories();
                        break;
                    case "folder":
                        this.handleListingFolders();
                        break;
                    case "character":
                        this.handleListingItems('characters', 16);
                        break;
                    case "created_by":
                        this.handleListingItems('created_by', 18);
                        break;
                    case "tag":
                        this.handleListingTags();
                        break;
                    case "user":
                        this.handleListingUsers();
                        break;
                    case "address":
                        this.handleListingAddresses(true);
                        break;
                    case "location":
                        this.handleListingAddresses(true);
                        break;
                    case "supplier":
                        this.handleListingSuppliers();
                        break;
                    case "asset_usage":
                        this.current_groupBy = 8;
                        this.handleListingStatuses();
                        break;
                    case "is_hero":
                        this.current_groupBy = 8;
                        this.handleListingStatuses();
                        break;
                    case "is_asset":
                        this.current_groupBy = 8;
                        this.handleListingStatuses();
                        break;
                    case "fdp":
                        this.current_groupBy = 9;
                        this.handleListingStatuses();
                        break;
                    case "fdp_status":
                        this.current_groupBy = 15;
                        this.handleListingStatuses();
                        break;
                    case "event":
                        this.handleListingEvents();
                        break;
                    case "department":
                        this.handleListingDepartments()
                        break;
                    case "liability":
                        this.current_groupBy = 14;
                        this.handleListingStatuses();
                        break;


                    default:
                        // this.handleListingFolders();
                        break;
                }
            }else{
                let queryVars = this.getQueryVars();
                if (this.current_groupBy !== 0){

                    let saved_filter_type = localStorage.getItem('filter_type' + (this.is_group ? '_group' : ''));

                    if (("s" in queryVars && queryVars["s"] !== '')){
                        saved_filter_type = 0;
                    }else{
                        let filter_type_key = 'filter_type_id' + (this.is_group ? '_group' : '');
                        if (window.USER_INFO.user_meta.hasOwnProperty(filter_type_key)){
                            this.filter_type_id = window.USER_INFO.user_meta[filter_type_key];
                        }
                    }

                    if (saved_filter_type){
                        this.current_groupBy = parseInt(saved_filter_type);
                        this.current_groupBy_title = this.groupBy_titles[saved_filter_type + ''];
                    }else if (this.is_group){
                        this.current_groupBy = 0;
                        this.current_groupBy_title = this.groupBy_titles[0];
                    }
                    this.handleSelectedFilter();
                }
            }
        },
        async getUsersList(item_type='users'){
            this.listLoading = true;

            let queryList = {};
            if (this.item_type === 'customer'){
                queryList.customer_id = this.item_id !== '' ? this.item_id : "0";
            }else if (this.is_group && this.item_type === 'group' && this.selected_customer.id){
                queryList.customer_id = this.selected_customer.id;
            }

            let requiredResource;
            if (item_type === 'events'){
                requiredResource = eventResource;
            }else if (item_type === 'departments'){
                requiredResource = departmentResource;
            }else if (item_type === 'characters'){
                requiredResource = characterResource;
                queryList.is_short_list = true;
            }else if (item_type === 'created_by'){
                requiredResource = usersResource;
                queryList.is_short_list = true;
                queryList.object_type = 'assets';
            }else{
                requiredResource = usersResource;
            }
            const { data } = await requiredResource.list(queryList);


            if (Object.keys(data).includes('data')){
                this.treeList = data.data.items;

                this.setSelectedItemForQueue();
                this.getList(this.selected_item);

                this.is_listing_sidebar = false;
            }else{
                console.log('Response Error');
            }
        },
        async getAssetGroupsList(){
            let queryList = {};
            if (this.item_type === 'customer'){
                queryList.customer_id = this.item_id !== '' ? this.item_id : "0";
            }

            const { data } = await assetGroupsResource.list(queryList);

            if (Object.keys(data).includes('data')){
                this.groups_list = data.data.items.map(function(e) { return {value: e.id, text: e.name}; })
            }else{
                console.log('Response Error');
            }
        },
        setSelectedItemForQueue: function () {
            // shall refactor???
            this.selected_item = this.customer_item;
            if (this.filtering_options && this.filtering_options.hasOwnProperty('item_id') && this.filtering_options.hasOwnProperty('queue')) {
                // shall handle filters?
                let queue = this.filtering_options.queue;
                let required_id = parseInt(this.filtering_options.item_id);

                let index = 0;
                switch (queue) {
                    case 'tag':
                    case 'user':
                    case 'event':
                    case 'department':
                    case 'character':
                    case 'created_by':
                        if (this.treeList) {
                            index = this.treeList.map(function (e) {
                                return e.id;
                            }).indexOf(required_id);
                        }
                        break;
                }

                this.selected_item = this.treeList[index];
            }else if (this.filter_type_id !== null){
                let required_id = this.filter_type_id;
                this.filter_type_id = null;

                let index = 0;
                if (this.treeList){
                    index = this.treeList.map(function(e) { return e.id; }).indexOf(required_id);
                }

                if (index > -1){
                    this.selected_item = this.treeList[index];
                }

            }
        },
        async getTagsList(just_fetch=false, should_refresh_list=true){
            if (!just_fetch){
                this.listLoading = true;
            }

            let queryList = {};
            if (this.item_type === 'customer'){
                queryList.customer_id = this.item_id !== '' ? this.item_id : "0";
            }
            const { data } = await tagsResource.list(queryList, (just_fetch ? just_fetch : null), just_fetch);

            if (Object.keys(data).includes('data')){
                if (just_fetch){
                    let my_tags_list = data.data.items;
                    this.$refs['my-page-content'].set_tags_list(my_tags_list);
                }else{
                    this.treeList = data.data.items;


                    this.setSelectedItemForQueue();

                    if(should_refresh_list){
                        this.getList(this.selected_item);
                    }

                    if (this.treeList){
                        this.tags_list = this.treeList.map(function(e) { return {value: e.id, text: e.name}; })
                    }
                    let add_new_item = {id: -98, name: 'Untagged'};
                    this.treeList.splice(0, 0, add_new_item);

                    this.is_listing_sidebar = false;
                }

            }else{
                console.log('Response Error');
            }
        },
        getCustomFieldsList(){
            // shall work here?
            this.treeList = AssetTypes;
            if (this.is_group && this.treeList.length > 1){
                this.treeList.splice(1, 1);
            }
            this.is_listing_sidebar = false;
            this.getList(this.selected_item);
        },
        getStatusList(){
            if (this.current_groupBy === 2){
                this.treeList = StatusesList;

            }else if (this.current_groupBy === 0){
                this.treeList = [
                    {
                        id: '0',
                        name: 'All'
                    }
                ];
            }else if (this.current_groupBy === 8){
                if (this.sectors_list && this.sectors_list.length){
                    let sectors_list = this.sectors_list;
                    this.treeList = AvailableSectors.filter(function(e) {
                        return sectors_list.includes(e.id);
                    });
                }

            }else if (this.current_groupBy === 9){
                this.treeList = SustainabilityActions;
            }else if (this.current_groupBy === 15){
                this.treeList = FDPStatuses;
            }else if (this.current_groupBy === 17){
                this.treeList = ReturnDatesList;
            }else if (this.current_groupBy === 19){
                this.treeList = CreatedDatesList;
            }

            // shall refactor???
            this.selected_item = this.customer_item;
            if (this.filtering_options && this.filtering_options.hasOwnProperty('item_id') && this.filtering_options.hasOwnProperty('queue')){
                // shall handle filters?
                let queue = this.filtering_options.queue;
                let required_id = this.filtering_options.item_id;

                let index = 0;
                let pre_selected_item = false;
                switch (queue){
                    case 'status':
                        if (this.treeList){
                            index = this.treeList.map(function(e) { return e.value; }).indexOf(required_id);
                        }
                        break;
                    case 'asset_usage':
                    case 'is_hero':
                    case 'is_asset':
                        let tv_and_film_index = -1;
                        if (this.treeList){
                            tv_and_film_index = this.treeList.map(function(e) { return e.id; }).indexOf('1');
                        }

                        let queue_index = ['asset_usage', 'is_hero', 'is_asset'].indexOf(queue);

                        if (tv_and_film_index >= 0){
                            let tv_and_film_item = this.treeList[tv_and_film_index];
                            let item_index = parseInt(required_id);
                            if (queue === 'asset_usage'){
                                switch (required_id){
                                    case 'on_cam':
                                        item_index = 0;
                                        break;
                                    case 'off_cam':
                                        item_index = 1;
                                        break;
                                    case 'unknown':
                                        item_index = 2;
                                        break;
                                }
                            }else{
                                switch (required_id){
                                    case 'yes':
                                        item_index = 0;
                                        break;
                                    case 'no':
                                        item_index = 1;
                                        break;
                                    case 'unknown':
                                        item_index = 2;
                                        break;
                                }
                            }
                            this.selected_item = tv_and_film_item.subfolders[queue_index].subfolders[item_index];
                            pre_selected_item = true;
                        }
                        break;
                    case 'liability':
                        if (this.treeList){
                            index = this.treeList.map(function(e) { return e.id; }).indexOf(required_id);
                        }
                        break;
                    default:
                        if (this.treeList){
                            index = this.treeList.map(function(e) { return parseInt(e.id); }).indexOf(parseInt(required_id));
                        }
                        break;
                }

                if (!pre_selected_item){
                    this.selected_item = this.treeList[index];
                }

            }else if (this.filter_type_id !== null && this.filter_type_id !== -99 && this.current_groupBy !== 8){
                let required_id = this.filter_type_id;
                this.filter_type_id = null;

                if (this.treeList){
                    let id_name = this.current_groupBy === 2 ? 'value': 'id';
                    let index = this.treeList.map(function(e) { return e[id_name]; }).indexOf(required_id);
                    if (index > -1){
                        this.selected_item = this.treeList[index];
                    }
                }
            }

            this.is_listing_sidebar = false;
            this.getList(this.selected_item);
        },
        fixItemQuantities (items) {
            var allItemObjects = [];
            if (items.length){
                for (let i = 0; i < items.length; i++) {
                    let item = items[i];
                    item.assets_sum_quantity = this.quantities_list[item.id];
                    if (item.subfolders) {
                        item.subfolders = this.fixItemQuantities(item.subfolders);
                    }
                    allItemObjects.push(item);
                }
            }
            return allItemObjects;
        },
        async getFoldersList(item_type='folders', just_fetch=false){
            this.listLoading = true;

            let queryList = {};
            if (this.item_type === 'customer' && ['folders', 'addresses', 'suppliers'].includes(item_type)){
                queryList.customer_id = this.item_id !== '' ? this.item_id : "0";
            }

            queryList.item_page_type = this.item_type;
            queryList.with_sum_quantity = 0; //just_fetch ? 0 : 1;


            var requiredResource;
            var customer_folder = [
                this.customer_item
            ];
            queryList.flat_only = 0;
            if (item_type === 'folders'){
                requiredResource = folderResource;
            }else if (item_type === 'categories'){
                queryList.flat_only = 1;
                requiredResource = categoryResource;
            }else if (item_type === 'addresses'){
                requiredResource = addressResource;
            }else if (item_type === 'suppliers'){
                requiredResource = supplierResource;
            }else{
                return;
            }
            const { data } = await requiredResource.list(queryList);

            if (Object.keys(data).includes('data')){
                if (just_fetch){
                    let addresses_list = data.data.items.map(function(e) { return {value: e.id, text: e.name}; });
                    this.$refs['add-new-location'].set_addresses_list(addresses_list);

                    if (item_type === 'folders'){

                        if (data.hasOwnProperty('all_folders') && data.all_folders){

                            this.all_folders = data.all_folders.items;
                            this.folders_list = customer_folder.concat(this.getFolderObject(data.all_folders.items, 1, item_type));
                            this.folders_list_copy = this.folders_list;
                        }
                        if (data.hasOwnProperty('flat_result') && data.flat_result){
                            this.flat_result = data.flat_result.items;
                        }

                    }
                }else{
                    this.treeList = data.data.items;

                    if (item_type === 'addresses'){
                        let add_new_item = {id: -98, name: 'No Address'};
                        this.treeList.splice(0, 0, add_new_item);
                    }


                    if (item_type === 'events'){
                        return;
                    }
                    if (data.hasOwnProperty('all_folders') && data.all_folders){
                        this.all_folders = data.all_folders.items;
                        this.folders_list = customer_folder.concat(this.getFolderObject(data.all_folders.items, 1, item_type));
                        this.folders_list_copy = this.folders_list;

                    }

                    if (data.hasOwnProperty('flat_result') && data.flat_result){
                        this.flat_result = data.flat_result.items;
                    }

                    if (this.current_groupBy === 3){
                        for (const key in this.treeList) {
                            let parent_category = this.treeList[key];
                            let child_categories = parent_category.subfolders;
                            let child_categories_quantities = 0;
                            if (child_categories && child_categories.length){
                                child_categories_quantities = child_categories.reduce((a, b) => +a + +b.assets_sum_quantity, 0);
                            }
                            parent_category.assets_sum_quantity = parseInt((parent_category.assets_sum_quantity ? parent_category.assets_sum_quantity : 0)) + parseInt(child_categories_quantities);
                        }
                    }

                    // shall refactor???
                    let index = 0;
                    this.selected_item = this.customer_item;
                    if (this.filtering_options && this.filtering_options.hasOwnProperty('item_id') && this.filtering_options.hasOwnProperty('queue')){
                        let queue = this.filtering_options.queue;
                        let required_id = this.filtering_options.item_id;

                        switch (queue){
                            case 'category':

                                let child_category = this.flat_result.filter(function(e) {
                                    return e.id === parseInt(required_id);
                                });
                                if (child_category.length > 0){
                                    required_id = child_category[0].parent_category_id !== 0 ? child_category[0].parent_category_id : child_category[0].id;
                                }
                                break;
                            case 'address':
                            case 'supplier':
                                // nothing to do ?
                                break;
                            case 'location':
                                // shall get parent folder of this location? !! required_id

                                let parent_address = this.all_folders.filter(function(e) {
                                    return e.subfolders.map(function(e) { return e.id; }).indexOf(parseInt(required_id)) >= 0;
                                });
                                if (parent_address){
                                    required_id = parent_address[0].id;
                                }
                                break;

                            default:
                                if (this.treeList){
                                    index = this.treeList.map(function(e) { return parseInt(e.id); }).indexOf(parseInt(required_id));
                                }
                                break;
                        }

                        if (required_id){
                            index = this.all_folders.map(function(e) { return e.id; }).indexOf(parseInt(required_id));
                            this.selected_item = this.all_folders[index];
                            if (queue === 'folder' && this.selected_item === undefined){
                                this.selected_item = this.customer_item;
                            }
                            switch (queue){
                                case 'address':
                                    // nothing to do ?ll mark item as parent >
                                    this.selected_item.force_open = true;
                                    break;
                                case 'location':
                                case 'category':
                                case 'folder':
                                    // shall mark item as parent >
                                    this.selected_item.child_only = this.filtering_options.item_id;
                                    this.selected_item.force_open = true;
                                    break;
                            }
                        }


                    }else if (this.filter_type_id !== null && this.filter_type_id !== -99){
                        let required_id = this.filter_type_id;
                        this.filter_type_id = null;

                        if ([1, 3].includes(this.current_groupBy)){
                            let child_category = this.flat_result.filter(function(e) {
                                return e.id === parseInt(required_id);
                            });
                            if (child_category.length > 0){
                                let parent_id_name = '';
                                switch (this.current_groupBy) {
                                    case 1:
                                        parent_id_name = 'parent_folder_id';
                                        break;
                                    case 3:
                                        parent_id_name = 'parent_category_id';
                                        break;
                                }
                                required_id = child_category[0][parent_id_name] !== 0 ? child_category[0][parent_id_name] : child_category[0].id;
                            }
                        }
                        if (this.treeList){
                            index = this.treeList.map(function(e) { return e.id; }).indexOf(required_id);
                            if (index > -1){
                                this.selected_item = this.treeList[index];
                            }
                        }
                    }else{
                        this.selected_item = this.folders_list[index];
                    }

                    this.getList(this.selected_item);

                    this.is_listing_sidebar = false;
                }

            }else{
                console.log('Response Error');
            }

        },
        setIsLoading: function (status) {
            this.assetsListLoading = status;
            if (!status){
                this.is_export = 0;
                this.is_bulk_action = 0;
                this.bulk_action = '';
                this.other_info = null;
            }

            if (this.$refs['my-page-content']){
                if (status === true){
                    this.itemsList = [];
                    this.total = 0;
                    this.totals_info = {total_quantity: 0, total_cost: 0};
                }
                this.$refs['my-page-content'].setIsLoadingAssets(status);
            }
        },
        async getDispositionsList () {
            let queryList = this.handleQueryItems({});

            const { data } = await dispositionResource.list(queryList);
            var subfolders;
            if (data.data.items.length !== 0){
                subfolders = data.data.items;
            }else{
                subfolders = [
                    {
                        id: -99,
                        name: 'Nothing to show..',
                        icon: ''
                        // meta_key: 'custom_fields'
                    },
                ];
            }
            if (this.treeList.length > 2){
                this.treeList[2].subfolders = subfolders;
            }

            console.log("'shall load other dispositions'");
            console.log('shall load other dispositions');
        },
        async exportAssetImages(){
            const exportImagesResource = new Resource('api/assetflow/images/exportImages');

            let params = {
                object_type: 'assets',
                exported_file_name: 'asset_images',
            }

            const { data } = await exportImagesResource.store(params);

            let new_item = data.data.items;

            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ('Export request made Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);

        },
        fetchTotals(){
            this.cancelTokens = [];
            this.getList(this.selected_item);
        },
        fetchAssets(){
            this.cancelTokens = [];
            this.getList(this.selected_item);
        },
        applyBulkAction(bulk_action, item_info){
            this.is_bulk_action = 1;
            this.bulk_action = bulk_action;
            this.other_info = item_info;
            this.getList(this.selected_item);
        },
        exportAssets(export_type='pdf', template_id=10){
            this.is_export = 1;
            this.export_type = export_type;
            this.template_id = template_id;
            this.top_filters_text = '';
            if (this.selected_ownership_type && this.selected_ownership_type.id !== null){
                this.top_filters_text += this.selected_ownership_type.name + '-';
            }
            if (this.selected_ownership_status && this.selected_ownership_status.id !== null){
                this.top_filters_text += this.selected_ownership_status.name + '-';
            }
            if (this.selected_status && this.selected_status.id !== null){
                this.top_filters_text += Vue.options.filters.titleize(this.selected_status.name) + '-';
            }
            if (this.selected_item && this.selected_item.id !== null && (this.selected_item.id !== 0 || this.selected_item.hasOwnProperty('type') || (this.selected_item.hasOwnProperty('name') && this.selected_item.name === 'Today'))){
                this.top_filters_text += Vue.options.filters.titleize(this.selected_item.name) + '-';
            }
            if (window.USER_INFO && window.USER_INFO.user_meta.preferred_view && window.USER_INFO.user_meta.preferred_view.id === 2){
                this.top_filters_text += 'In Storage-';
            }
            if (this.search_keyword && this.search_keyword !== ''){
                this.top_filters_text += Vue.options.filters.titleize(this.search_keyword) + '-';
            }
            this.getList(this.selected_item);
        },
        startGettingAssetDetails() {
            if (this.$refs['my-page-content']){
                this.$refs['my-page-content'].setIsLoadingAssetDetails(true);
                // shall get asset details
                this.$nextTick(() => {
                    let that = this;
                    setTimeout(function(){
                        that.$refs['my-page-content'].setIsLoadingAssetDetails(false);
                    }, 500);
                });
            }
        },
        async getList(selected_item="", changing_type='group') {
            // console.trace();
            if (this.$refs['my-page-content'] && this.$refs['my-page-content'].length > 0){
                this.$refs['my-page-content'][0].forceResetSelectingItems();
            } else if (this.$refs['my-page-content']) {
                this.$refs['my-page-content'].forceResetSelectingItems();
            }
            if (selected_item === '' || selected_item === null) {
                selected_item = this.customer_item;
            }

            let active_tab = localStorage.getItem('assets_list_active_tab');
            if (!active_tab) {
                active_tab = 'assets_dashboard';
            }


            if (!['assets_list', 'assets_dashboard', 'export_tab', 'update_tab'].includes(active_tab)){
                console.log("tab not enabled");
                console.log(active_tab);
                return;
            }

            this.totals_only = active_tab === 'assets_dashboard' ? 1 : 0;

            if (!this.is_export || !this.totals_only || !this.is_bulk_action){
                this.listLoading = true;
                this.setIsLoading(true);
            }

            let page_content_component = this.$refs['my-page-content'];
            var required_query = {};


            let currentPage = null;
            if (this.selected_item !== selected_item){
                if (changing_type !== 'filter'){
                    this.selected_item = selected_item;
                }
                currentPage = 1;
            }

            if (page_content_component){
                if (!currentPage){
                    currentPage = page_content_component.perpage.currentPage;
                }else{
                    page_content_component.perpage.currentPage = currentPage;
                }

                required_query = {
                    page: currentPage,
                    limit: page_content_component.perpage.perPage,
                    sort_by: page_content_component.sorting.sortType.name,
                    sort_dir: page_content_component.sorting.sortType.reversed ? 'desc' : 'asc',
                    selected_view: this.preferredView ? this.preferredView.id : 0
                };
            }else{

                let user_meta = window.USER_INFO.user_meta;
                if (user_meta){
                    let preferredItemsPerPage = user_meta.preferred_items_per_page;
                    preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
                    if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
                        preferredItemsPerPage = parseInt(preferredItemsPerPage);

                        let sortType = {
                            name: 'name',
                            reversed: false
                        };
                        if (user_meta.preferred_sorting) {
                            sortType = user_meta.preferred_sorting;
                        }

                        required_query = {
                            page: 1,
                            limit: preferredItemsPerPage,
                            sort_by: sortType.name,
                            sort_dir: sortType.reversed ? 'desc' : 'asc',
                            selected_view: this.preferredView ? this.preferredView.id : 0
                        }
                    }

                }

            }

            if (this.search_keyword){
                required_query.s_keyword = this.search_keyword;
            }

            if (selected_item !== ''){
                // this can help reducing no. of requests
                // if (this.selected_item === selected_item){ return; }

                if (changing_type === 'filter'){
                    this.current_groupBy = parseInt(selected_item.value);
                    this.current_groupBy_title = selected_item.name;
                    localStorage.setItem('filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy);
                    this.handleSelectedFilter();
                    return;
                } else{
                    if (selected_item.id !== -99){
                        this.selected_item = selected_item;
                    }
                    required_query = this.handleQueryItems(required_query);


                    switch (this.current_groupBy){
                        case 1:
                            // console.log(selected_item.id);
                            // console.log(selected_item.name);
                            // console.trace();
                            required_query.folder_id = selected_item.id;
                            break;
                        case 2:
                            // console.log(selected_item.name);
                            if (selected_item.id !== 0){
                                let status_name = selected_item.value;
                                if (selected_item.id === 5){
                                    status_name = 'disposed';
                                    this.getDispositionsList();
                                }
                                required_query.status_name = status_name;
                                if (selected_item.hasOwnProperty('disposition_type') && selected_item.disposition_type){
                                    required_query.disposition_type = selected_item.disposition_type;
                                    required_query.status_name = 'disposed';
                                }
                                if (selected_item.hasOwnProperty('disposition_name') && selected_item.disposition_name){
                                    required_query.disposition_name = selected_item.disposition_name;
                                }
                                if (selected_item.hasOwnProperty('disposition_customer_id') && selected_item.disposition_customer_id){
                                    required_query.customer_id = selected_item.disposition_customer_id;
                                }
                            }
                            break;
                        case 3:
                            // console.log(selected_item.id);
                            required_query.category_id = selected_item.id;
                            break;
                        case 4:
                            // console.log(selected_item.id);
                            required_query.tag_id = selected_item.id;
                            break;
                        case 5:
                            // console.log(selected_item.id);
                            required_query.user_id = selected_item.id;
                            break;
                        case 6:
                            // console.log(selected_item.id);
                            var location_ids;
                            if (selected_item.hasOwnProperty('child_only')){
                                location_ids = selected_item.child_only;
                            }else if (selected_item.address_id){
                                location_ids = selected_item.id;
                            }else{
                                location_ids = selected_item.location_ids;
                                required_query.address_id = selected_item.id;
                            }
                            required_query.location_ids = location_ids;
                            break;
                        case 7:
                            // console.log(selected_item.id);

                            // var po_ids;
                            // if (!selected_item.address_id){
                            //     po_ids = selected_item.id;
                            //     required_query.po_ids = po_ids;
                            // }else{
                            required_query.supplier_id = selected_item.id;
                            // }


                            break;

                        case 8:
                            // console.log(selected_item.id);
                            if ("type" in selected_item){
                                required_query['using_key'] = selected_item.type;
                                required_query['required_value'] = selected_item.id;
                            }else{
                                this.listLoading = false;
                                this.setIsLoading(false);
                                return;
                            }
                            break;

                        case 9:
                            required_query['using_key'] = 'final_disposition_planning';
                            required_query['required_value'] = selected_item.id;
                            break;
                        case 10:
                            let queryList = {};
                            if (this.item_type === 'customer'){
                                queryList.customer_id = this.item_id !== '' ? this.item_id : "0";
                            }
                            queryList.is_group = this.is_group;

                            let index = null;
                            if ("meta_key" in selected_item){
                                required_query['using_meta'] = selected_item.meta_key;
                                required_query['required_meta_value'] = selected_item.id;
                            }else if("fetch_meta_key" in selected_item){

                                queryList.meta_key = selected_item.fetch_meta_key;
                                index = selected_item.fetch_meta_key === 'asset_template' ? 0 : 1;
                            }

                            if (index !== null){
                                const { data } = await customFieldsResource.list(queryList);
                                var subfolders;
                                if (data.data.items.length !== 0){
                                    subfolders = data.data.items;
                                }else{
                                    subfolders = [
                                        {
                                            id: -99,
                                            name: 'Nothing to show..',
                                            icon: ''
                                            // meta_key: 'custom_fields'
                                        },
                                    ];
                                }
                                this.treeList[index].subfolders = subfolders;
                            }

                            if (selected_item.id === -99){
                                this.listLoading = false;
                                this.setIsLoading(false);
                                return;
                            }
                            break;
                        case 12:
                            required_query.event_id = selected_item.id;
                            break;
                        case 13:
                            required_query.department_id = selected_item.id;
                            break;
                        case 14:
                            required_query.asset_liability = selected_item.id;
                            break;
                        case 15:
                            required_query.fdp_status = selected_item.id;
                            break;
                        case 16:
                            required_query.character_id = selected_item.id;
                            break;
                        case 17:
                            required_query.return_date = selected_item.id;
                            break;
                        case 18:
                            required_query.user_id = selected_item.id;
                            break;
                        case 19:
                            required_query.created_at = selected_item.id;
                            break;
                        default:
                            break;
                    }
                }
            }else{
                console.log("selected_item is Empty");
            }

            required_query.is_export = this.is_export;
            if (this.is_export){
                required_query.export_type = this.export_type;
                required_query.template_id = this.template_id;
            }

            if (this.preferredView){
                required_query.in_storage_only = this.preferredView.id === 2 ? 1 : 0;;
            }

            if (this.is_bulk_action){
                required_query.is_bulk_action = this.is_bulk_action;
                required_query.bulk_action = this.bulk_action;
                required_query.other_info = this.other_info;
            }

            required_query.totals_only = this.totals_only;
            required_query.top_filters_text = this.top_filters_text;
            required_query.basic_info_only = true;

            if (this.$refs['my-page-content'] && this.$refs['my-page-content'].length > 0){
                this.$refs['my-page-content'][0].latest_required_query = required_query;
            } else if (this.$refs['my-page-content']) {
                this.$refs['my-page-content'].latest_required_query = required_query;
            }
            const response = await assetResource.list(required_query);
            if (!response){
                return;
            }

            const data = response.data;
            if (Object.keys(data.data).includes('status')){
                if (this.totals_only){
                    // shall refresh totals
                    let dashboard_info = data.data.items;
                    this.$refs['my-page-content'].set_dashboard_info(dashboard_info);
                    this.listLoading = false;
                    this.setIsLoading(false);
                    return;
                }
                let has_error = data.data.error || false;

                if (this.is_export || this.is_bulk_action || has_error){
                    let new_item = data.data.status;
                    this.presentToast((new_item ? "Success!" : "Error!"),
                        data.data.msg,
                        (new_item ? 'success' : 'danger'),
                        3000,
                        true,
                        true);
                    this.listLoading = false;
                    this.setIsLoading(false);
                    return;
                }
            }


            if (Object.keys(data).includes('data')){
                this.itemsList = data.data.items;
                if (data.hasOwnProperty('meta')){
                    this.total = data.meta.total;
                    this.totals_info = {total_quantity: data.data.total_quantity, total_cost: data.data.total_cost};
                    this.request_meta = data.meta;
                    this.listLoading = false;
                    this.setIsLoading(false);
                }else if (data.data.hasOwnProperty('total')){
                    this.total = data.data.total;
                    this.totals_info = {total_quantity: 0, total_cost: 0};
                    this.request_meta = null;
                    this.listLoading = false;
                    this.setIsLoading(false);
                }

                this.startGettingAssetDetails();

            }else{
                // console.log('Response Error');
                this.listLoading = false;
                this.setIsLoading(false);
            }
        },
        handleQueryItems(query) {
            if (this.item_type === 'bss'){
                query.bss_status = true;
            }else{
                if (this.item_type === 'sp'){
                    query.warehouse_id = this.item_id !== '' ? this.item_id : "0";
                }
                if (this.item_type === 'customer') {
                    query.customer_id = this.item_id !== '' ? this.item_id : "0";
                }

                if (this.is_group){
                    if (this.selected_customer && this.selected_customer.id){
                        query.customer_id = this.selected_customer.id;
                    }
                    query.item_type = this.item_type;
                    query.filter_option = this.current_groupBy;
                }
            }
            if (this.item_type === 'customer' && this.is_managed_storage_module){
                query.storage_warehouse_id = this.selected_sp.id;
            }

            if (this.selected_status && this.selected_status.id && this.current_groupBy !== 2){
                query.status_name = this.selected_status.name;
            }
            if (this.selected_ownership_type && this.selected_ownership_type.id !== null){
                query.ownership_type = this.selected_ownership_type.id;
            }
            if (this.selected_ownership_status && this.selected_ownership_status.id !== null){
                query.ownership_status = this.selected_ownership_status.id;
            }

            return query;
        },

        setSelectedLayout(layoutIndex){
            this.selectedLayoutIndex = layoutIndex;
            let message = "Saved New Layout {"+this.layoutOptions[layoutIndex].title+"} Successfully";
            this.set_usermeta('preferred_assets_layout', layoutIndex, message);
        },
        get_parents_info(item, item_type='folders'){

            let parent_item_id = item_type === 'folders' ? item.parent_folder_id : item.parent_category_id,
                current_item_parent_ids = [];

            let index = this.flat_result.map(function(e) { return e.id; }).indexOf(parent_item_id);

            if (index === -1){
                return [];
            }

            let parent_item = this.flat_result[index];

            current_item_parent_ids.push({
                text: parent_item.name,
                href: '/'+item_type+'/' + parent_item.id + '/view'
            });
            current_item_parent_ids = this.get_parents_info(parent_item).concat(current_item_parent_ids);

            return current_item_parent_ids;
        },
        get_subfolder_ids(folder){

            let c_subfolders = folder.hierarchy,
                current_folder_subfolder_ids = [];

            if(c_subfolders.length > 0){
                for (let i = 0; i < c_subfolders.length; i++) {
                    current_folder_subfolder_ids.push(c_subfolders[i].id);
                    current_folder_subfolder_ids = current_folder_subfolder_ids.concat(this.get_subfolder_ids(c_subfolders[i]));
                }
            }
            return current_folder_subfolder_ids;
        }
    },
    watch: {
        selected_item: {
            deep: true,
            handler: function(newValue, oldValue){
                if ([1,3].includes(this.current_groupBy)){ // folders and categories
                    var new_breadcrumb_items = this.breadcrumb_items;
                    let item_type = this.current_groupBy === 1 ? 'folders' : 'categories';

                    let index = this.flat_result.map(function(e) { return e.id; }).indexOf(this.selected_item.id);
                    if (index !== -1){
                        var current_folder_info = this.flat_result[index];

                        if (current_folder_info){
                            let current_folder_text =
                                {
                                    text: current_folder_info.name,
                                    href: '/'+item_type+'/' + current_folder_info.id + '/view'
                                };

                            new_breadcrumb_items = this.breadcrumb_items.concat(this.get_parents_info(current_folder_info, item_type));
                            new_breadcrumb_items.push(current_folder_text);
                        }
                        this.$refs['my-page-content'].set_breadcrumb_items(new_breadcrumb_items);
                    }else{
                        this.$refs['my-page-content'].set_breadcrumb_items(this.breadcrumb_items);
                    }
                }else if ([2,4,5, 6].includes(this.current_groupBy)){ // TODO: // handle groups/children

                    let required_id = (this.current_groupBy === 6 && this.selected_item.address_id) ? this.selected_item.address_id :  this.selected_item.id;

                    if (this.treeList){
                        let index = this.treeList.map(function(e) { return e.id; }).indexOf(required_id);

                        let status_info = this.treeList[index];
                        let selected_status;
                        let new_breadcrumb_items = this.breadcrumb_items;
                        if (status_info){
                            let status_name = Vue.options.filters.titleize(status_info.name);
                            selected_status = [
                                {
                                    text: status_name,
                                    active: true
                                }
                            ];
                            new_breadcrumb_items = new_breadcrumb_items.concat(selected_status);
                        }

                        this.$refs['my-page-content'].set_breadcrumb_items(new_breadcrumb_items);
                    }else{
                        this.$refs['my-page-content'].set_breadcrumb_items(this.breadcrumb_items);
                    }

                }else{
                    this.$refs['my-page-content'].set_breadcrumb_items(this.breadcrumb_items);
                }

            }
        },
        selected_folder: {
            deep: true,
            handler: function(newValue, oldValue){

                if (['addresses', 'address_locations'].includes(this.change_folder_item_type)){

                }else{

                    let index = this.flat_result.map(function(e) { return e.id; }).indexOf(this.selected_folder);
                    if (index !== -1){
                        var current_folder_info = this.flat_result[index];

                        if (this.required_action !== 'create-subfolder'){
                            var subfolder_ids = this.get_subfolder_ids(current_folder_info);
                            subfolder_ids = [current_folder_info.id].concat(subfolder_ids);

                            this.folders_list = this.folders_list_copy.filter(function(e) {
                                return !subfolder_ids.includes(e.value);
                            });
                        }
                    }
                }

            }
        }
    }
};
</script>


<style>

.customers-list .root-top-menu.active a{
    color: #0D5296;
}
.customers-list .main-root-top > ul > li a:before{
    display: none;
}


.is_assets .page-content {
    padding: 70px 0px 60px 0px !important;
}
</style>
