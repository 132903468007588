import {SPStorageShortKeysList} from "@/views/assetflow/form_data/SPStorageShortKeysList";
import Resource from '@/api/resource';

export default {
    data() {
        return {
            sp_storage_keys_list: SPStorageShortKeysList,
            other_info: {},
            isAddingItem: false,
            addedItem: null,

            isAddingAsset: false,
            addedAsset: null,
            failedAddingAsset: null,
            isAddingOrderLine: false,
            addedOrderLine: null,
            failedAddingOrderLine: null,
            isAddingManagedStorage: false,
            addedManagedStorage: null,
            failedAddingManagedStorage: null,
            isAddingIntakeProfile: false,
            addedIntakeProfile: null,
            failedAddingIntakeProfile: null,
            isAddingWarehousingMovement: false,
            addedWarehousingMovement: null,
            failedAddingWarehousingMovement: null,
            couldNotAddWarehousingMovement: null,
        }
    },
    mounted(){
        /*Function Calls*/
        this.setupJQueryMethods();
    },
    computed:{
    },
    watch:{
    },
    methods: {
        tryAgain(){
            this.isAddingItem = false;
            this.addedItem = false;

            this.addedAsset = null;
            this.addedOrderLine = null;
            this.addedManagedStorage = null;
            this.addedIntakeProfile = null;
            this.addedWarehousingMovement = null;
            this.couldNotAddWarehousingMovement = null;

            this.failedAddingAsset = null;
            this.failedAddingOrderLine = null;
            this.failedAddingManagedStorage = null;
            this.failedAddingIntakeProfile = null;
            this.failedAddingWarehousingMovement = null;
        },
        setupJQueryMethods(){
            let strongThis = this;
            let action_names = [
                'change-sp_storages-utilisation',
                'assign-sp_storages-location',
                'remove-sp_storages-location'
            ];
            for (const actionNamesKey in action_names) {
                let action_name = action_names[actionNamesKey];
                $(document).on('click', '.'+action_name, function(event) {
                    strongThis.getItemAndShowModal(action_name, event);
                });
            }
        },

        showModal(selected_item, modal_ref){
            if (this.$refs[modal_ref]){
                this.$refs[modal_ref].setSelectedItem(selected_item);
                this.$bvModal.show(modal_ref);
            }
        },
        refreshItem(modal_id, edited_storage){
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            if (!edited_storage){ return; }

            if (['assign-and-get-sp_storages', 'assign-sp_storages-location','remove-sp_storages-location'].includes(modal_id)){
                if (modal_id === 'assign-and-get-sp_storages'){
                    this.required_sections.movement.required_items.storage_id.options.push(edited_storage);
                    this.required_sections.movement.required_items.recent_storage.options.splice(0,0, edited_storage);
                }
                this.setSelectedStorage(edited_storage);
            }else if (modal_id === 'change-sp_storages-utilisation'){
                if (this.selected_storage_info && this.selected_storage_info.id !== null){
                    this.selected_storage_info.storage_utilisation = edited_storage.storage_utilisation;
                }
            }
        },
        appendOrder(order_info = null){
            if (order_info){
                this.setFormValue('order_id', order_info);
                this.required_sections.order_details.required_items.order_id.options.push(order_info);
            }
        },
        appendFolder(folder_info = null){
            if (folder_info){
                this.setFormValue('folder_id', folder_info);
                this.required_sections.asset_setup.required_items.folder_id.options.push(folder_info);
            }
        },

        setSelectedStorage(storage, event){
            if (event){
                event.preventDefault();
            }

            this.setFormValue('storage_id', storage);
        },
        assignStorage(event, suffix=''){
            event.preventDefault();
            let selected_item = {
                customer_id:this.addForm.customer_id.id
            }
            let modal_ref = 'assign-and-get-sp_storages'+suffix;
            this.$refs[modal_ref].setCustomersList([this.addForm.customer_id]);
            this.showModal(selected_item, modal_ref);
        },

        async getSPCustomerStorage(customer_id){
            this.required_sections.movement.required_items.storage_id.listLoading = true;

            this.required_sections.movement.required_items.recent_storage.label = 'Loading Recent Storage...';
            this.required_sections.movement.required_items.recent_storage.options = [];

            const getSPCustomerStorageResource = new Resource('api/assetflow/customers/get_list');

            let queryList = {
                list_name: 'SPStorage',
                customer_id: customer_id.id,
                with_recent: true
            };
            const { data } = await getSPCustomerStorageResource.store(queryList);

            let storage_response = data.items;

            if (storage_response){
                let recent_storage_ids = storage_response.recent_storage_ids;
                let storage_list = storage_response.storage_list;
                this.required_sections.movement.required_items.storage_id.options = storage_list;
                let recent_storage = storage_list.filter(function (e) {
                    return recent_storage_ids.includes(e.id);
                }).map(function (storage) {
                    return {
                        id: storage.id,
                        name: storage.name,
                        storage_utilisation: storage.storage_utilisation,
                        status: storage.status,
                        location_status: storage.location_status,
                        assets_count: storage.assets_count,
                        location_info: storage.location_info,
                        current_warehouse_info: storage.current_warehouse_info,
                        current_space_info: storage.current_space_info,
                        storage_wrapper_info: storage.storage_wrapper_info
                    };
                });
                this.required_sections.movement.required_items.recent_storage.options = recent_storage;
                if (recent_storage.length > 0){
                    this.required_sections.movement.required_items.recent_storage.label = 'Recent Storage';
                }else{
                    this.required_sections.movement.required_items.recent_storage.label = '';
                }
            }

            this.required_sections.movement.required_items.storage_id.listLoading = false;

            if (this.other_info.hasOwnProperty('storage_info') && this.other_info.storage_info){
                let storage_info = this.getObjectOf(this.other_info.storage_info.id, this.required_sections.movement.required_items.storage_id.options);
                this.setFormValue('storage_id', storage_info);
            }

        },
        async getSPCustomerOrders(customer_id){
            this.required_sections.order_details.required_items.order_id.listLoading = true;
            const getSPCustomerOrdersResource = new Resource('api/assetflow/customers/get_list');

            let queryList = {
                list_name: 'SPCustomerOrders',
                order_type: 0,
                customer_id: customer_id.id
            };
            const { data } = await getSPCustomerOrdersResource.store(queryList);


            let add_new_item = {id: -99, name: 'Add New'};
            let order_id_options = data.items;
            order_id_options.splice(0, 0, add_new_item);

            this.required_sections.order_details.required_items.order_id.options = order_id_options;
            this.required_sections.order_details.required_items.order_id.listLoading = false;

            if (this.other_info.hasOwnProperty('order_info') && this.other_info.order_info){
                let order_info = this.getObjectOf(this.other_info.order_info.id, this.required_sections.order_details.required_items.order_id.options);
                this.setFormValue('order_id', order_info);
            }
        },

        async getSPCustomerFolders(customer_id){
            this.required_sections.asset_setup.required_items.folder_id.listLoading = true;
            const getSPCustomerFoldersResource = new Resource('api/assetflow/customers/get_list');

            let queryList = {
                list_name: 'SPCustomerFolders',
                customer_id: customer_id.id
            };
            const { data } = await getSPCustomerFoldersResource.store(queryList);

            let add_new_item = {id: -99, name: 'Add New'};
            let folder_id_options = this.getFolderObject(data.items, 1, 'folders', 'id', 'name');
            folder_id_options.splice(0, 0, add_new_item);

            this.required_sections.asset_setup.required_items.folder_id.options = folder_id_options;
            this.required_sections.asset_setup.required_items.folder_id.listLoading = false;

            if (this.other_info.hasOwnProperty('folder_id') && this.other_info.folder_id !== undefined){
                let folder_info = this.getObjectOf(this.other_info.folder_id, this.required_sections.asset_setup.required_items.folder_id.options);
                this.setFormValue('folder_id', folder_info);
            }
        },

        getNextActionParams(){
            let params = '';
            if (this.addForm.customer_id){
                params += '?customer_id=' + this.addForm.customer_id.id;
            }
            if (this.addForm.order_id){
                params += (params === '' ? '?' : '&') + 'order_id=' + this.addForm.order_id.id;
            }
            if (this.addForm.storage_id){
                params += (params === '' ? '?' : '&') + 'storage_id=' + this.addForm.storage_id.id;
            }
            return params;
        },




        // adding functionality
        async addManagedStorage(asset_id, order_line_id, is_return_to_storage=false, asset_info=null, all_files=null){
            return new Promise( async (resolve, reject) => {
                this.isAddingManagedStorage = true;
                let params = {
                    customer_id: this.addForm.customer_id ? this.addForm.customer_id.id : null,
                    storage_id: this.selected_storage_info ? this.selected_storage_info.id : null,
                    order_line_id: order_line_id,
                    aag_item_id: asset_id,
                    item_type: 0,
                    status: 1,
                    item_reference: this.addForm.reference,
                    notes: this.addForm.notes,
                    intake_profile_id: null,
                    warehousing_movement_id: null,
                    total_quantity: this.addForm.quantity,
                    available_quantity: this.addForm.quantity,
                    reserved_quantity: 0,
                    dispatched_quantity: 0,
                    asset_images: all_files,
                };

                if (is_return_to_storage && asset_info){
                    params.item_reference = asset_info.internal_reference;
                    params.notes = asset_info.description;
                    params.total_quantity = asset_info.quantity;
                    params.available_quantity = asset_info.quantity;
                }


                const managed_storageResource = new Resource('api/assetflow/managed_storages');
                const { data } = await managed_storageResource.store(params);
                this.isAddingManagedStorage = false;
                let success_message = 'Added Managed Storage Successfully';
                this.handleResponseForObject(data, success_message, resolve, reject, 'ManagedStorage');

            });
        },
        async updateAsset(asset_id){
            return new Promise( async (resolve, reject) => {
                this.isAddingAsset = true;
                let params = {
                    asset_id: asset_id,
                    item_details: {
                        'storage_status': 1,
                        'status': 'acquired',
                        condition: this.addForm.condition ? this.addForm.condition.id : null,
                    },
                    should_update_quantity:true,
                    with_intake_profile:true,
                    customer_id: this.addForm.customer_id ? this.addForm.customer_id.id : null,
                    order_id: this.order_id ? this.order_id.id : null,
                    storage_id: this.selected_storage_info ? this.selected_storage_info.id : null,
                    should_check_assigned_storage: true,
                };
                axios({
                    method: 'put',
                    url: '/api/assetflow/assets/' + asset_id,
                    baseURL: '/',
                    data: params,
                }).then(response => {
                    if (response.data.data.status === true) {
                        let data = response.data;
                        this.isAddingAsset = false;
                        let success_message = 'Updated Asset Successfully';
                        this.handleResponseForObject(data, success_message, resolve, reject, 'Asset');
                    }else{
                        if (response.data.data.status === false && response.data.data.msg){
                            this.addedAsset = null;
                            this.isAddingAsset = false;
                            this.failedAddingAsset = { message: response.data.data.msg };
                        }else{
                            reject();
                        }
                    }
                })
            });
        },
        async addOrderLine(asset_id, update_from_asset=false){
            return new Promise( async (resolve, reject) => {
                this.isAddingOrderLine = true;
                let params = {
                    customer_id: this.addForm.customer_id ? this.addForm.customer_id.id : null,
                    order_id: this.addForm.order_id ? this.addForm.order_id.id : null,
                    quantity: this.addForm.quantity,
                    aag_item_id: asset_id,
                    item_type: 0,
                    reference: this.addForm.reference,
                    notes: this.addForm.notes,
                    condition: this.addForm.condition ? this.addForm.condition.id : 1,
                    intake_date: this.addForm.intake_date,
                    short_form: 'order_line_with_intake',
                    update_from_asset: update_from_asset
                };
                const order_lineResource = new Resource('api/assetflow/order_lines');
                const { data } = await order_lineResource.store(params);
                this.isAddingOrderLine = false;
                let success_message = 'Added Order Line Successfully';
                this.handleResponseForObject(data, success_message, resolve, reject, 'OrderLine');
            });
        },

        async addIntakeProfile(managed_storage, all_files){
            return new Promise( async (resolve, reject) => {
                this.isAddingIntakeProfile = true;
                let params = {
                    customer_id: this.addForm.customer_id ? this.addForm.customer_id.id : null,
                    order_line_id: managed_storage.order_line_id,
                    managed_storage_id: managed_storage.id,
                    asset_images: all_files,
                    category_id: this.addForm.category_id ? this.addForm.category_id.id : 1,
                    folder_id: this.addForm.folder_id ? this.addForm.folder_id.id : 1,

                    preferred_unit: this.addForm.preferred_unit ? this.addForm.preferred_unit.name : 'm',

                    quantity: this.addForm.quantity,
                    value_type: this.addForm.value_type, // 0 for one item >> do nothing, 1 for all items > divide the biggest by quantity

                    width: this.addForm.width,
                    height: this.addForm.height,
                    depth: this.addForm.depth,
                    area: this.addForm.area,
                    total_area: this.addForm.total_area,
                    volume: this.addForm.volume,
                    total_volume: this.addForm.total_volume,
                    weight: this.addForm.weight,
                    total_weight: this.addForm.total_weight,
                    colours: this.addForm.colours ? this.getIDs(this.addForm.colours) : null,

                    waste_stream_type: this.addForm.waste_stream_type ? this.addForm.waste_stream_type.id : null,
                    waste_stream_id: this.addForm.waste_stream_id ? this.addForm.waste_stream_id.id : null,
                    composition: this.addForm.composition ? this.getIDs(this.addForm.composition, false, 'name') : null,

                    condition: this.addForm.condition ? this.addForm.condition.id : null,
                    shall_update_parent: this.addForm.shall_update_parent ? this.addForm.shall_update_parent.id : null,
                    notes: this.addForm.notes
                };
                const intake_profileResource = new Resource('api/assetflow/intake_profiles');
                const { data } = await intake_profileResource.store(params);
                this.isAddingIntakeProfile = false;
                let success_message = 'Added Intake Profile Successfully';
                this.handleResponseForObject(data, success_message, resolve, reject, 'IntakeProfile');
            });
        },
        async addWarehousingMovement(managed_storage){
            return new Promise( async (resolve, reject) => {
                this.isAddingWarehousingMovement = true;
                let params = {
                    customer_id: this.addForm.customer_id ? this.addForm.customer_id.id : null,
                    order_line_id: managed_storage.order_line_id,
                    managed_storage_id: managed_storage.id,

                    status:1,
                    movement_type: 3, // intake
                    movement_source_type: 5, // Order,
                    movement_source_id: this.addForm.order_id ? this.addForm.order_id.id : null,
                    movement_target_type: 4,
                    movement_target_id: this.selected_storage_info ? this.selected_storage_info.id : null
                };
                const warehousing_movementResource = new Resource('api/assetflow/warehousing_movements');
                const { data } = await warehousing_movementResource.store(params);
                this.isAddingWarehousingMovement = false;
                let success_message = 'Added Warehousing Movement Successfully';
                this.handleResponseForObject(data, success_message, resolve, reject, 'WarehousingMovement');
            });
        },


        handleResponseForObject(data, success_message, resolve, reject, object_name) {
            let added_object = data.data.items;
            this[('added' + object_name)] = added_object;
            this.presentToast((added_object ? "Success!" : "Error!"),
                added_object ? success_message : data.data.msg,
                (added_object ? 'success' : 'danger'),
                3000,
                true,
                true);

            if (resolve === null){
                if (!added_object) {
                    this[('failedAdding' + object_name)] = {message: data.hasOwnProperty('data') && data.data.hasOwnProperty('msg') && data.data.msg ? data.data.msg : ''};
                }
            }else{
                if (added_object) {
                    resolve(added_object);
                } else {
                    this[('failedAdding' + object_name)] = {message: data.hasOwnProperty('data') && data.data.hasOwnProperty('msg') && data.data.msg ? data.data.msg : ''};
                    reject();
                }
            }
        },

    }
}
