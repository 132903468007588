<template>
    <div class="cm_list_view" :class="asset.id === selected_object_id ? 'active_check' : ''" :style="(is_actions_bar_opened && (!selected_ids.includes(asset.id) && should_hide_unselected)) ? 'display:none;' : ''">
        <SelectableItemActions
            :add-to-selected-list="addToSelectedList"
            :item="asset"
            :bulk_actions_customer_id="bulk_actions_customer_id"
            :is_actions_bar_opened="is_actions_bar_opened"
            :preform-bulk-action="preformBulkAction"
            :selected_ids="selected_ids"/>

        <div class="cm_list_row row">
            <div class="col-md-12 col-xl-2">
                <MyBCarouselImages parent_class="cm_list_view_image" :my_asset="asset" :show-captiom-img="showCaptiomImg" :required_image_size="'asset_list'"/>

                <div class="common_col_bg_box" v-if="item_info && item_info.hasOwnProperty('files_info') && item_info.files_info">
                    <ManagedStorageFiles
                        :ms_id="asset.id"
                        :item_info="item_info"
                        :item_type="item_type"
                        object_type="managed_storages"
                        :perform-action="performAction"
                        :ms_index="assetKey"
                        :ms_item="asset"
                    />
                </div>


                <div class="common_col_bg_box" v-if="item_info && item_info.hasOwnProperty('asset_meta') && item_info.asset_meta && item_info.asset_meta.hasOwnProperty('asset_links') && item_info.asset_meta.asset_links && item_info.asset_meta.asset_links.length !== 0">
                    <div class="row">
                        <div
                            v-for="(custom_field, key) in item_info.asset_meta.asset_links"
                            class="col-12 col-md-12" :class="customFieldsClass('asset_links')">
                            <div class="cm_usage">
                                <span>URL #{{ key + 1}}</span>
                                <p v-if="custom_field.field_value">
                                    <i class="fa fa-link"></i> <span class="cm_list_inner_common_value"><a class="text-dark" :href="custom_field.field_value" target="_blank">{{ custom_field.field_name || ('URL #' + (key + 1)) }}</a></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="col-xl-10">
                <div class="row cm_line_row">
                    <div class="col-md-6 col-xl-6">
                        <div class="cm_list_title_content">
                            <div class="cm_list_view_title">
                                <h3 v-html="getHtmlTitle(asset, true)"></h3>
                            </div>

                            <ManagedStorageIconList
                                v-if="item_info"
                                :item_info="asset"
                                :item_type="item_type"
                                :perform-action="performAction"/>
                        </div>

                        <div class="common_col_desc ms-item-description" v-if="item_info && item_info.hasOwnProperty('description') && item_info.description">
                            <p>{{ item_info.description }}</p>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-6">
                        <div class="cm_status_title_content">
                            <div class="cm_list_view_title" v-html="getManagedObjectStatus(asset)"></div>
                            <div class="cm_status_ul">
                                <ul v-if="[1, 2].includes(asset.status)">
                                    <li>
                                        <p>Total <span class="total_box_bg">{{ asset.total_quantity }}</span></p>
                                    </li>
                                    <li>
                                        <p>Available <span class="available_box_bg">{{ asset.available_quantity }}</span></p>
                                    </li>
                                    <li>
                                        <p>Reserved <span class="reserved_box_bg">{{ asset.reserved_quantity }}</span></p>
                                    </li>
                                </ul>
                                <ul v-else>
                                    <li>
                                        <p>Dispatched <span class="total_box_bg">{{ asset.dispatched_quantity }}</span></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row cm_list_view_row">
                    <div class="col-md-6 col-xl-6">
                        <div class="common_col_bg_box">
                            <ManagedStorageParents :item_info="item_info" :item_type="item_type"/>
                        </div>


                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage border_r">
                                        <span>Colours</span>
                                        <div v-if="item_info" v-html="getColourHTML(item_info)"></div>
                                        <span v-else>-</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage border_r">
                                        <span>Weight</span>
                                        <p>
                                            <span
                                                v-if="item_info && item_info.hasOwnProperty('weight') && item_info.weight">
                                                {{ item_info.weight }}kg
                                            </span>
                                            <span v-else>0kg</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Height</span>
                                        <p>
                                            <span
                                                v-if="item_info && item_info.hasOwnProperty('height') && item_info.height">
                                                {{ item_info.height }}{{ item_info.preferred_unit }}
                                            </span>
                                            <span v-else>0m</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Width</span>
                                        <p>
                                            <span
                                                v-if="item_info && item_info.hasOwnProperty('width') && item_info.width">
                                                {{ item_info.width }}{{ item_info.preferred_unit }}
                                            </span>
                                            <span v-else>0m</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Depth</span>
                                        <p>
                                            <span
                                                v-if="item_info && item_info.hasOwnProperty('depth') && item_info.depth">
                                                {{ item_info.depth }}{{ item_info.preferred_unit }}
                                            </span>
                                            <span v-else>0m</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Area/Volume</span>
                                        <p>
                                            <span
                                                v-if="item_info && item_info.hasOwnProperty('total_area') && item_info.total_area">
                                                {{ (item_info.area * item_info.quantity).toFixed(2) }}m<sup>2</sup>
                                            </span>
                                            <span v-else>0m<sup>2</sup></span>
                                            <span
                                                v-if="item_info && item_info.hasOwnProperty('total_volume') && item_info.total_volume">
                                                {{ (item_info.volume * item_info.quantity).toFixed(2) }}m<sup>3</sup>
                                            </span>
                                            <span v-else>0m<sup>3</sup></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage border_r">
                                        <span>Waste Stream</span>
                                        <p><b>{{ item_info && item_info.waste_stream_type === 0 ? 'Product' : 'Material' }}</b></p>
                                        <p>
                                            <span v-if="item_info && item_info.waste_stream_type === 0 && item_info.hasOwnProperty('waste_stream_info') && item_info.waste_stream_info">{{ item_info.waste_stream_info.name }}</span>
                                            <span v-else-if="item_info && item_info.waste_stream_type === 1 && item_info.hasOwnProperty('composition_info') && item_info.composition_info">
                                                {{item_info.composition_info.map(item => item.name.charAt(0).toUpperCase() + item.name.slice(1)).join(', ') }}
                                            </span>
                                            <span v-else></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage border_r">
                                        <span>Current Condition</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info && item_info.hasOwnProperty('condition')">
                                                {{ getConditionText(item_info) }}
                                            </span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="common_col_bg_box" v-if="item_info && item_info.hasOwnProperty('asset_meta') && item_info.asset_meta && item_info.asset_meta.hasOwnProperty('custom_fields') && item_info.asset_meta.custom_fields && item_info.asset_meta.custom_fields.length !== 0">
                            <div class="row">
                                <div
                                    v-for="(custom_field, key) in item_info.asset_meta.custom_fields"
                                    class="col-6 col-md-3" :class="customFieldsClass()">
                                    <div class="cm_usage">
                                        <span>{{ custom_field }}</span>
                                        <p>
                                            <i class=""></i> <span class="cm_list_inner_common_value">{{ item_info.asset_meta.hasOwnProperty(custom_field) && item_info.asset_meta[custom_field] ? item_info.asset_meta[custom_field] : '-' }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class=" cm_list_view_tag">
                            <div class="tag-wrap list-show">
                                <ul v-if="item_info && item_info.hasOwnProperty('tags_info')">
                                    <li v-for="tag in item_info.tags_info">
                                        <a v-if="tag.hasOwnProperty('id')" :href="'/' + item_type + '/tags/' + tag.id + '/view'">{{ tag.name }}</a>
                                    </li>
                                </ul>
                                <tags-manager v-if="received_all_tags_list"
                                    class="mt-2"
                                    ref="tags-manager1"
                                    :product="item_info"
                                    :all_tags_list="received_all_tags_list"
                                    :asset_key="assetKey"
                                    @on-add-tag="myAddTag"
                                    @on-remove-tag="myRemoveTag"
                                ></tags-manager>
                                <div v-else key="1" class="mt-2">
                                    <span class="loading_tags"><i class="fas fa-tag"></i> Loading Tags...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-6">
                        <div class="common_col_bg_box" v-if="['sp', 'assetflow'].includes(item_type) || is_cross_entity">
                            <div class="row">
                                <div class="col-6 col-md-6">
                                    <div class="cm_usage border_r">
                                        <span>{{ is_tv_film_asset ? 'Customer' : 'Production' }}</span>
                                        <p>
                                            <span>
                                                <a v-if="asset.customer_info" class="text-dark" :href="'/' + item_type + '/customers/' + asset.customer_info.id + '/view'">{{asset.customer_info.name | titleize}}</a>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6">
                                    <div class="cm_usage border_r">
                                        <span>MS ID</span>
                                        <p>
                                            <span v-if="asset">{{asset.uid}}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="common_col_bg_box" v-else>
                            <div class="row">
                                <div class="col-6 col-md-6">
                                    <div class="cm_usage">
                                        <span v-iconize="'service_providers'">Service Provider</span>
                                        <p
                                            v-if="asset.hasOwnProperty('sp_info') && asset.sp_info">
                                            <a class="text-dark" :href="'/' + item_type + '/service_providers/?s=' + asset.sp_info.name">{{ asset.sp_info.name }}</a>
                                        </p>
                                        <p v-else><span>-</span></p>
                                    </div>
                                </div>
                                <div v-if="[1,2].includes(asset.status)" class="col-6 col-md-6">
                                    <div class="cm_usage">
                                        <span>Address</span>
                                        <p v-if="asset.hasOwnProperty('current_warehouse_address_info') && asset.current_warehouse_address_info">
                                            <a class="text-dark"
                                               :href="'/' + item_type + '/addresses/' + asset.current_warehouse_address_info.id + '/view'">{{
                                                    asset.current_warehouse_address_info.name }}</a>
                                        </p>
                                        <p v-else>-</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-6 col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Intake</span>
                                        <p v-if="asset.hasOwnProperty('storage_order_info') && asset.storage_order_info">
                                            <span>
                                                <a class="text-dark"
                                                   :href="'/' + item_type + '/orders/' + asset.storage_order_info.id + '/view'">{{ asset.storage_order_info.uid }}</a>
                                            </span>
                                        </p>
                                        <p v-else>
                                            <span>-</span>
                                        </p>
                                    </div>

                                </div>

                                <div class="col-6 col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Intake By</span>
                                        <p>
                                            <span v-if="asset.hasOwnProperty('user_info') && asset.user_info"><a class="text-dark" :href="'/' + item_type + '/users/' + asset.user_info.id + '/view'">{{ asset.user_info.name }}</a></span>
                                            <span v-else class="unknown-user-span">[Unknown User]</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-6 col-md-4">
                                    <div class="cm_usage">
                                        <span>{{asset.status !== 0 ? 'Intake In' : 'Completed Date'}}</span>
                                        <p>
                                            <span v-if="asset.status === 0 && order_line_info && order_line_info.hasOwnProperty('completed_date')">
                                                ?{{ order_line_info.completed_date | moment('DD-MM-YYYY') }}?
                                            </span>
                                            <span v-else-if="asset.hasOwnProperty('created_at') && asset.created_at">
                                                {{ asset.created_at | moment('DD-MM-YYYY') }}
                                            </span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="common_col_bg_box" v-if="asset.status !== 0 && (['sp', 'assetflow'].includes(item_type) || is_cross_entity)">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage categories_list">
                                        <span v-iconize="'sp_storages'">Storage</span>
                                        <div v-html="getStorageBlock(asset)"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span v-iconize="'sp_locations'">Location</span>
                                        <p v-if="asset.storage_info && asset.location_info" v-html="getLocationBlock(asset)"></p>
                                        <p v-else-if="asset && asset.hasOwnProperty('current_warehouse_info') && asset.current_warehouse_info && asset && asset.hasOwnProperty('current_space_info') && asset.current_space_info"
                                           v-html="getSPLocationBlock(asset)"></p>
                                        <p v-else>-</p>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div
                            class="common_col_desc ms-reserved-quantity-note"
                            v-if="asset && asset.hasOwnProperty('reserved_quantity') && asset.reserved_quantity">
                            <p><a class="text-dark" :href="'/' + item_type + '/managed_storages/' + asset.id + '/view?t=orders'">'{{ asset.reserved_quantity }}' stock is currently on these Orders</a></p>
                        </div>


                        <div v-if="withOrders && asset && asset.hasOwnProperty('storage_orders') && asset.storage_orders && asset.storage_orders.length > 0 /* && hasPermission('browse_orders')*/" class="common_col_bg_box in_storage-ms-actions">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="cm_list_inner_common">
                                        <span>Storage Orders:</span>
                                        <p>
                                            <span
                                                v-for="(storage_order, index) in asset.storage_orders"
                                                :key="index"
                                                class="mr-1">
                                                <a :href="'/' + item_type + '/orders/'+storage_order.id+'/view'" class="text-dark">
                                                    <i :class="'font-size-10 fa fa-circle ' + (storage_order.status === 0 ? 'status-grey' : 'status-brown')"></i>{{storage_order.name}}{{ storage_order.uid !== storage_order.name ? ('(' + storage_order.uid + ')') : '' }}</a>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!withoutActions && (asset.status === 2 && hasPermission('can_add_storage_order'))" class="common_col_bg_box in_storage-ms-actions">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="cm_list_inner_common">
                                        <span>In Storage Ordering:</span>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="asset.available_quantity === 0" class="row">
                                <div class="col-12">
                                    <div class="common_col_desc no-available-stock">
                                        <p>No Available Stock</p>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="row">
                                <div class="col-md-4">
                                    <div class="cm_list_inner_common">
                                        <span>Qty</span>
                                        <p v-if="asset.available_quantity > 0">
                                            <multiselect
                                                v-model="selected_quantity_to_add"
                                                :options="getNumbers(1, asset.available_quantity)"
                                                placeholder="Qty"
                                                :show-labels="false"
                                            >
                                                <span slot="noResult">No data found.</span>
                                            </multiselect>
                                        </p>
                                        <p v-else>-</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_list_inner_common" v-if="orders_list && orders_list.length > 0">
                                        <span>Orders</span>
                                        <p>
                                            <multiselect
                                                v-model="selected_order_to_add_to"
                                                :options="orders_list"
                                                :custom-label="orderSelectDetails"
                                                placeholder="Order"
                                                @input="changedOrderField('order', $event)"
                                                :show-labels="false"
                                            >
                                                <span slot="noResult">No data found.</span>
                                            </multiselect>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_list_inner_common">
                                        <span></span>
                                        <p>
                                            <span>
                                                <button v-if="!selected_order_to_add_to || !selected_quantity_to_add" @click="manageAddToOrder($event)" class="btn btn-link af-btn-accent af-accent btn-secondary">Manage</button>
                                                <button v-else :disabled="isAddingItem" @click="addToOrder" class="btn btn-link af-btn-accent af-accent btn-secondary">Add To Order</button>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else-if="!withoutActions && asset.status === 1" class="common_col_bg_box in_progress-ms-actions">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="cm_list_inner_common">
                                        <p>
                                            <a v-if="order_line_info" :href="'/' + item_type + '/order_lines/'+order_line_info.id+'/view'" class="btn af-btn-accent af-accent btn-sm btn-block btn-view-intake"><i class="fa fa-arrow-alt-circle-down"></i>View Intake</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else-if="asset.status === 0" class="common_col_bg_box dispatched-ms-actions">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="cm_list_inner_common">
                                        <p>
                                            <a v-if="order_line_info" :href="'/' + item_type + '/orders/'+order_line_info.order_id+'/view'" class="btn btn-primary btn-sm af-accent">View Storage Order</a>
                                            <a v-else-if="parent_order_id" :href="'/' + item_type + '/orders/'+parent_order_id+'/view'" class="btn btn-primary btn-sm af-accent">View Storage Order</a>
                                            <a :href="'/' + item_type + '/order_lines/move_to_storage/return_to_storage?asset_id='+item_info.uid" class="btn btn-primary btn-sm af-accent ml-1 return-to-storage-btn">Return to Storage</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <template v-else-if="withoutActions">
                            <div v-if="(asset.status === 2 && hasPermission('can_add_storage_order'))" class="common_col_bg_box in_storage-ol-actions">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="cm_list_inner_common">
                                            <span>In Storage Ordering:</span>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="asset.available_quantity === 0" class="common_col_desc no-available-stock">
                                    <p>No Available Stock</p>
                                </div>
                                <div v-else class="cm_list_inner_common">
                                    <span></span>
                                    <p>
                                        <i class="bx bx-detail"></i>
                                        <span><button @click="manageAddToOrder($event)" class="btn btn-link af-btn-accent af-accent btn-secondary">Add To Order</button></span>
                                    </p>
                                </div>
                            </div>
                            <div v-else-if="asset.status === 1" class="common_col_bg_box in_progress-ol-actions">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="cm_list_inner_common">
                                            <p><a href="javascript:void(0)" @click="performAction(parent_order_line, 'process-order_line', 0, $event)" class="btn btn-primary btn-sm af-accent">Approve Intake</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </template>
                        <div class="cm_list_last_update mt-1" v-if="asset && asset.hasOwnProperty('latest_post_update')">
                            <p v-html="getPostText(asset)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cm_list_view_more_button" v-if="!withoutActions">

            <div v-if="hasPermission('browse_'+object_type) || hasPermission('edit_'+object_type) || hasPermission('delete_'+object_type) || hasPermission('can_add_storage_order')" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>
                </button>
                <ManagedObjectActionsList :item="asset" :index="assetKey" :item_type="item_type" :object_type="object_type" :perform-action="performAction"/>
            </div>

        </div>
        <div class="cm_list_view_btn">
            <a :href="'/' + item_type + '/managed_storages/' + asset.id + '/view'"><i class="bx bx-chevron-right"></i></a>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import TagsManager from "@/views/assetflow/assets/subItems/TagsManager";
import ManagedObjectActionsList from "./ManagedObjectActionsList";
import ManagedStorageIconList from "./ManagedStorageIconList";

import Resource from '@/api/resource';
import MyBCarouselImages from "../../assets/subItems/AssetLayouts/MyBCarouselImages";
import ManagedStorageParents from "./ManagedStorageParents";
import ManagedStorageEntityBlock from "./ManagedStorageEntityBlock";
import ManagedStorageIntakePPInfo from "./ManagedStorageIntakePPInfo";
import ManagedStorageIntakeInfo from "./ManagedStorageIntakeInfo";
import SelectableItemActions from "./SelectableItemActions";
import ManagedStorageFiles from "./ManagedStorageFiles";

const addToOrderResource = new Resource('api/assetflow/assets/addAssetToOrder');

export default {
    name: 'ManagedObjectListView',
    components: {
        ManagedStorageFiles,
        SelectableItemActions,
        ManagedStorageIntakeInfo,
        ManagedStorageIntakePPInfo,
        ManagedStorageEntityBlock,
        ManagedStorageParents,
        MyBCarouselImages,
        Multiselect,
        ManagedStorageIconList, ManagedObjectActionsList, TagsManager},
    props:[
        'item_type',
        'object_type',
        'selected_object_id',
        'asset',
        'assetKey',
        'showCaptiomImg',
        'received_all_tags_list',
        'received_orders_list',
        'myAddTag',
        'myRemoveTag',
        'getManagedObjectStatus',
        'getHtmlTitle',
        'performAction',
        'getStorageBlock',
        'getSPLocationBlock',
        'getLocationBlock',
        'getColourHTML',
        'getConditionText',
        'orderSelectDetails',
        'getNumbers',
        'getPostText',
        'withoutActions',
        'parent_order_id',
        'parent_order_line',
        'withOrders',
        'is_actions_bar_opened',
        'is_selecting_items',
        'selected_ids',
        'addToSelectedList',
        'should_hide_unselected',
        'preformBulkAction',
        'bulk_actions_customer_id',
    ],
    watch:{
        received_orders_list: {
            deep: true,
            handler: function(newValue){
                if (newValue){
                    this.prepareOrdersList();
                }
            }
        },
    },
    computed:{
        item_info(){
            return this.asset && this.asset.hasOwnProperty('item_info') && this.asset.item_info;
        },
        order_line_info(){
            return this.asset && this.asset.hasOwnProperty('order_line_info') && this.asset.order_line_info;
        },
    },
    data(){
        return {
            orders_list: [],
            selected_order_to_add_to: null,
            selected_quantity_to_add: null,
            isAddingItem: false,
        }
    },
    created() {
        this.prepareOrdersList();
    },
    methods:{

        manageAddToOrder(event){
            // shall send this.selected_quantity_to_add to modal >>
            this.changedOrderField('manage', event);
        },
        changedOrderField(field, event) {
            let option = this.selected_order_to_add_to;
            if ((option && option.id === -99 && field === 'order') || field === 'manage'){
                this.selected_order_to_add_to = null;
                if (field !== 'manage'){
                    if (!this.selected_quantity_to_add){
                        this.presentToast("Error!",
                            'Please select quantity first',
                            'danger',
                            3000,
                            true,
                            true);
                        return;
                    }
                }
                // Should show add new order modal
                this.asset.quantity = this.selected_quantity_to_add;
                this.performAction(this.asset, 'addToOrder', event);
                this.selected_quantity_to_add = null;
            }
        },
        prepareOrdersList(){
            let orders_list = this.received_orders_list;
            if (orders_list !== undefined){
                let add_new_option = [
                    {
                        id: -99,
                        name: 'Add New'
                    }
                ];
                let asset = this.asset;
                let current_asset_customer_id = asset && asset.hasOwnProperty('customer_info') && asset.customer_info ? asset.customer_info.id : (asset && asset.hasOwnProperty('customer_id') && asset.customer_id ? asset.customer_id : null);
                let order_type = 1;

                orders_list = orders_list.filter(function(order) {
                    let same_customer_id = order.hasOwnProperty('customer_info') && order.customer_info && (order.customer_info.id === current_asset_customer_id);
                    let same_order_type = order.order_type === order_type;

                    return same_customer_id && same_order_type;
                });

                this.orders_list = add_new_option.concat(orders_list);
            }
        },
        async addToOrder(){
            if (this.isAddingItem){
                return;
            }

            if (this.selected_quantity_to_add && this.selected_quantity_to_add > 0 && this.selected_order_to_add_to){
                this.isAddingItem = true;
                let selected_order_id = this.selected_order_to_add_to.id;
                let params = {
                    asset_id: this.item_info.id,
                    order_type: 1,
                    managed_storage_id: this.asset.id,
                    order_id: selected_order_id,
                    quantity: this.selected_quantity_to_add,
                }



                const { data } = await addToOrderResource.store(params);

                let status = data.data.status;
                if (status && data.data.hasOwnProperty('managed_storage')){
                    let managed_storage = data.data.managed_storage;
                    this.asset.available_quantity = managed_storage.available_quantity;
                    this.asset.reserved_quantity = managed_storage.reserved_quantity;
                }

                this.presentToast((status ? "Success!" : "Error!"),
                    status ? ('Managed Storage Added To Storage Order Successfully') : data.data.msg,
                    (status ? 'success' : 'danger'),
                    3000,
                    true,
                    true);
                this.isAddingItem = false;
            }else{
                this.presentToast("Error!",
                    'Please select a Quantity and Storage Order',
                    'danger',
                    3000,
                    true,
                    true);
            }

            this.selected_order_to_add_to = null;
            this.selected_quantity_to_add = null;
        },
    }
}
</script>

<style>

.multiselect__option {
    width: 100%;
}
</style>
