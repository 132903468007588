/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
import Vue from "vue";
import VueMask from "v-mask";
Vue.use(VueMask)

window.onerror = function(message, source, line, column, error) {
    console.log('ON ERROR:', message);
}

Vue.config.errorHandler = function(err, vm, info) {
    console.log(`Error: ${err.toString()}\nInfo: ${info}`);
}
Vue.config.warnHandler = function(msg, vm, trace) {
    console.log(`Warn: ${msg}\nTrace: ${trace}`);
}


import Skeleton from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/vue-loading-skeleton.css";
Vue.use(Skeleton);

window.Vue = Vue;

import { BootstrapVue } from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import i18n from "./i18n";

import VueApexCharts from "vue-apexcharts";
Vue.component("apexchart", VueApexCharts);

window.Vapor = require('laravel-vapor');
window.$ = window.jQuery = require('jquery');

import * as VueGoogleMaps from "vue2-google-maps";

import GmapCluster from 'vue2-google-maps/dist/components/cluster';
Vue.component('cluster', GmapCluster)

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyClc1X5xyJH3OfFxy9IfCuJ9CrNf4erCMs",
        libraries: "places"
    },
    installComponents: true
});


Vue.prototype.$isDev = process.env.MIX_APP_ENV !== "production";
Vue.config.devtools = Vue.prototype.$isDev;
Vue.config.debug = Vue.prototype.$isDev;
Vue.config.silent = !Vue.prototype.$isDev;


import paginationPlugin from './paginationPlugin';
// Use the pagination plugin globally
Vue.use(paginationPlugin);


Vue.use(require('vue-moment'));

import moment from 'moment';
Vue.filter('fromNow', function (date, with_prefix=false) {
    return moment(date).fromNow(with_prefix);
});

Vue.use(BootstrapVue);
Vue.use(vClickOutside);
Vue.component(
    "dynamic-component",
    require("./components/dynamic-component").default
);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import "./views";
import Layouts from "./mixins/layouts.mixin";


// this will support global functions
import Globals from "./mixins/global.mixin";
Vue.mixin(Globals);

import UserMeta from "./mixins/usermeta.mixin";
Vue.mixin(UserMeta);

import TotalsAndCurrencies from "./mixins/TotalsAndCurrencies.mixin";
Vue.mixin(TotalsAndCurrencies);

Vue.mixin({
    methods: {
        vapor_asset: window.Vapor.asset
    },
});

Vue.filter('capitalize', function (value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('titleizeToSingle', function (value) {
    if (!value) return '';
    value = value.toString();
    if (value.toLowerCase() === 'categories'){
        value = "Category";
    }else if (value.toLowerCase() === 'addresses'){
        value = "Address";
    }else if (value.toLowerCase() === 'usage'){
        value = "Usage";
    }else if (value.toLowerCase() === 'fdp status'){
        value = "FDP Status";
    }else if (value.toLowerCase() === 'fdp'){
        value = "FDP";
    }else if (value.toLowerCase() === 'custom'){
        value = "Custom";
    }else if (value.toLowerCase() === 'statuses'){
        value = "Status";
    }else if (value.toLowerCase() === 'industry\\sector'){
        value = "Industry\\Sector";
    }else if (['Created Date', 'Created By', 'Due Date', 'Location Status', 'Location Type', 'Type', 'All'].includes(value)){
        // do nothing
    }else{
        value = value.slice(0, -1);
    }

    return value.split('_').map(function(item) {
        return item.charAt(0).toUpperCase() + item.substring(1);
    }).join(' ');
});

function toMoney(num){
    // TODO:// handle currency and money conversions.
    var p = num.toFixed(2).split(".");
    return "£" + p[0].split("").reverse().reduce(function(acc, num, i, orig) {
        return  num=="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
    }, "") + "." + p[1];
}

Vue.filter('monetize', function (value) {
    return value !== '-' ? toMoney(value) : '-';
});

Vue.filter('titleize', function (value) {
    if (!value) return '';
    value = value.toString();
    if (value === 'sp_settings'){
        value = "Service Provider";
    }else if(value === 'bss_assets'){
        value = "Marketplace";
    }else if(value === 'managed_storages'){
        value = "Managed Storage";
    }else if(value === 'sp_id'){
        value = "SP ID";
    }else if(value === 'assign sp'){
        value = "Assign Service Provider";
    }else if(value === 'sp'){
        value = "SP";
    }else if(value === 'bss_status'){
        value = "BSS Status";
    }else if(value === 'id'){
        value = "ID";
    }else if(value === 'uid'){
        value = "UID";
    }else if(value === 'po_id'){
        value = "Purchase Order";
    }else if(['sp_warehouses', 'sp_warehouse', 'sp_spaces', 'sp_locations', 'sp_location_types', 'sp_assignments', 'sp_storages', 'sp_storage_wrappers'].includes(value)){
        value = value.replace("sp_", "SP_");
    }
    value = value.replace("_id", "_ID");

    value = value.replace("modified_", "");
    value = value.replace(" ", "_");
    return value.split('_').map(function(item) {
        return item.charAt(0).toUpperCase() + item.substring(1);
    }).join(' ');
});

// Vue.directive('uppercase', {
//     inserted(el){
//         el.value = el.value.toUpperCase();
//     },
//     update (el) {
//         el.value = el.value.toUpperCase();
//     },
// });

function getRequiredIconFor(required_object) {
    required_object = required_object.replace(' ', '_');
    let required_classes = {
        'assets': 'bx bx-cube',
        'files': 'fa fa-file',
        'folders': 'fa fa-folder',
        'tags': 'fa fa-tag',
        'categories': 'fa fa-plus-circle',
        'overview': 'fa fa-star',
        'navigation_items': 'fa fa-list',
        'acquisitions': 'bx bx-box',
        'dispositions': 'bx bx-box',
        'roles': 'fa fa-user-check',
        'permissions': 'fa fa-user-check',
        'modules': 'fa fa-vector-square',
        'module': 'fa fa-vector-square',
        'users': 'fa fa-users',
        'user_details': 'fa fa-user',
        'customers': 'fa fa-star',
        'service_providers': 'fa fa-warehouse',
        'sps': 'fa fa-warehouse',
        'groups': 'fa fa-layer-group',
        'assetflow': 'fa fa-circle',
        'sharing_type': 'fa fa-share-alt',
        'orders': 'fa fa-shopping-bag',
        'managed_stroage': 'fa fa-cube',
        'sp_storages': 'fa fa-box',
        'sp_locations': 'fa fa-location-arrow',
        'sp_movements': 'fa fa-arrow-right',
        'order_service': 'fa fa-shopping-bag',
        'departments': 'fa fa-clone',
        'suppliers': 'bx bx-cube',
    }
    if (required_classes.hasOwnProperty(required_object)){
        return '<i class="' + required_classes[required_object] + '"></i>';
    }
    return '<i class="fa fa-minus"></i>';
}

Vue.directive('iconize', (el, binding) => {
    // this will be called for both `mounted` and `updated`
    let required_object = binding.value;
    let required_icon = getRequiredIconFor(required_object);
    if (!binding.hasOwnProperty('oldValue')){
        el.innerHTML = required_icon + ' ' + el.innerHTML;
    }
})

Vue.directive('iconize-icon', (el, binding) => {
    el.innerHTML = getRequiredIconFor(binding.value);
})


Vue.filter('iconize', function (value, required_object = '') {
    let required_icon = getRequiredIconFor(required_object);
    return ( required_icon !== '' ? required_icon + ' ' : '') + value;
});

import MyLayout from "@/layouts/main";
Vue.component('MyLayout', MyLayout);

require('../../public/myAssets/KoolChart/KoolChart');
require('../../public/myAssets/KoolChart/KoolChartLicense');


const app = new Vue({
    el: "#app",
    mixins: [Layouts],
    i18n,
    components: {
        'MyLayout': MyLayout,
    },
    data: {
        current_customer: "Customer A?"
    }
});
