<template>
    <!-- Sidebar start-->
    <div class="sidebar" id="sidebar-wrapper">
        <div class="folder-wrapper">
            <div class="main-root-top">
                <h3 class="filter_option">{{ selected_filter.name | titleize }}</h3>
                <ul class="root-folder filters_list" style="display: none">
                    <li v-for="(filter_option, index) in filter_options" :class="'root-top-menu' + (filter_option && filter_type === filter_option.value ? ' active' : '')">
                        <a @click="changeFilter(filter_option, index, $event)">
                            {{ filter_option ? filter_option.name : '-' }}
                        </a>
                    </li>
                </ul>
            </div>

            <div class="sub-root-top folder-menu active">

                <ul :class="'root-folder' + (filter_type !== 1 ? ' custom-icons-root root-' + filter_classes[filter_type+''] : '')">
                    <li class="active filled">
                        <a class="root-heading customer-root" v-if="![0, 8, 9, 10].includes(filter_type)" @click="changeGroup(customer_item, $event)">
                            <i v-if="filter_type === 2" class="font-size-18 bx bxs-circle customer-status-icon"></i>
                            <i v-else-if="filter_type === 3" class="font-size-24 bx bxs-grid-alt text-af-accent"></i>
                            <i v-else-if="filter_type === 4" class="fas fa-tags"></i>
                            <span v-if="filter_type === 5 && af_initials !== ''" class="customer-users-icon text-center rounded-circle header-profile-user af-accent text-af-primary">{{af_initials}}</span>
                            <i v-else-if="filter_type === 6" class="font-size-18 bx bx-map-alt"></i>
                            <i v-else-if="filter_type === 7" class="font-size-18 bx bx-cube"></i>

                            <span class="pl-1">{{customer_item.text | titleize}}</span>

                            <b-dropdown dropleft v-if="filter_type === 1 && hasPermission('add_folders')" class="ml-auto" variant="none">
                                <template slot="button-content">
                                    <i class="mdi mdi-dots-horizontal"></i>
                                </template>
                                <b-dropdown-item v-if="hasPermission('add_folders')" @click="createFolder(0)">New Sub-Folder</b-dropdown-item>
                            </b-dropdown>


                            <b-dropdown dropleft v-else-if="filter_type === 4 && hasPermission('add_tags')" class="ml-auto" variant="none">
                                <template slot="button-content">
                                    <i class="mdi mdi-dots-horizontal"></i>
                                </template>
                                <b-dropdown-item v-if="hasPermission('add_tags')" @click="createTag">New Tag</b-dropdown-item>
                            </b-dropdown>

                        </a>
                        <folder-row
                            v-if="!is_listing_sidebar"
                            :items="sidebar_list"
                            :filter_type="filter_type"
                            :item_type="item_type"
                            :ul_class="'subfolder' + (filter_type !== 1 ? (' ' + filter_classes[filter_type] + '-list custom-icons-list') : '')"
                            :is_group="is_group"
                            :is_first="true"
                            :selected_item="selected_item"
                            :selected_customer="selected_customer"
                            @on-group-changing="changeGroup"
                            @on-folder-creation="createFolder"
                            @on-folder-editing="editFolder"
                            @on-tag-editing="editTag"
                            @on-add-location="addLocation"
                            @on-untag-assets="untagAssets"
                            @on-folder-moving="moveFolder"
                            @on-folder-deletion="deleteFolder"
                            @on-content-moving="moveContent"
                        ></folder-row>
                        <ul v-else>
                            <li class="mr-auto">
                                <div class="loading-spinner text-center">
                                    <div class="center">
                                        <b-spinner class="text-af-accent m-2"></b-spinner>
                                    </div>
                                    Loading...
                                    <br />
                                    <small>Please wait.</small>
                                </div>
                            </li>
                        </ul>

                    </li>
                </ul>
            </div>


        </div>
    </div>
    <!-- Sidebar end-->

</template>

<script>
import FolderRow from "./FolderRow";
export default {
    name: "FoldersSidebar",
    components: {FolderRow},
    props: ['sidebar_list', 'filter_type', 'item_type', 'is_group', 'selected_item', 'selected_customer', 'is_listing_sidebar'],
    data() {
        return {
            filter_classes: {
                '0': 'all',
                '1': '',
                '2': 'statuses',
                '3': 'af-categories',
                '4': 'tags',
                '5': 'users',
                '6': 'addresses-filter',
                '7': 'suppliers-filter',
                '8': 'industry-sector-filter',
                '9': 'fdp-filter',
                '10': 'custom-fields-filter',
                '12': 'events-filter',
                '15': 'fdp_status-filter',
                '16': 'character-filter',
                '17': 'return_date-filter',
            },
            customer_item:null,
            filter_options: [
                {
                    value: 0,
                    name: 'All'
                },
                {
                    value: 1,
                    name: 'Folders'
                },
                {
                    value: 2,
                    name: 'Usage'
                },
                {
                    value: 3,
                    name: 'Categories'
                },
                {
                    value: 4,
                    name: 'Tags'
                },
                {
                    value: 5,
                    name: 'Users'
                },
                {
                    value: 6,
                    name: 'Addresses'
                },
                {
                    value: 7,
                    name: 'Suppliers'
                },
                {
                    value: 8,
                    name: 'Industry\\Sector'
                },
                {
                    value: 9,
                    name: 'FDP'
                },
                {
                    value: 15,
                    name: 'FDP Status'
                },
                {
                    value: 16,
                    name: 'Characters'
                },
                {
                    value: 17,
                    name: 'Return Dates'
                },
                {
                    value: 12,
                    name: 'Events'
                },
                {
                    value: 10,
                    name: 'Custom'
                },
                {
                    value: 18,
                    name: 'Created By'
                },
                {
                    value: 19,
                    name: 'Created Date'
                },
            ],
            filemanager_items: null,
            hidden_sidebar: false,
            selected_filter: null,
            customer_name: null,
            af_initials: null
        };
    },
    created() {
        if (this.is_group){
            this.filter_classes = {
                '0': 'all',
                '2': 'group-categories',
                '3': 'group-statuses',
                '10': 'group-asset-types',
                '12': 'group-asset-events',
                '15': 'group-asset-fdp_status',
            };

            this.filter_options = [
                {
                    value: 0,
                    name: 'All'
                },
                {
                    value: 2,
                    name: 'Usage'
                },
                {
                    value: 3,
                    name: 'Categories'
                },
                {
                    value: 10,
                    name: 'Asset Types'
                },
                {
                    value: 12,
                    name: 'Events'
                },
                {
                    value: 15,
                    name: 'FDP Status'
                }
            ]

        }
        this.customer_name = window.AF_NAME;

        this.af_initials = (window.AF_INITIALS ? window.AF_INITIALS : "");
        this.customer_item = {
            id:0,
            value: 0,
            text: this.customer_name,
            name: this.customer_name
        };

        if (this.is_group){
            let group_name = window.AF_GROUP_NAME;
            this.customer_item = {
                id:0,
                value: 0,
                text: group_name,
                name: group_name
            }
        }else{
            if (!this.sectors_list || this.sectors_list.length === 0){
                this.filter_options.splice(8, 1);
                if (this.filter_type === 8){
                    this.filter_type = 0;
                }
            }
        }

        if (this.filter_type){
            let filter_type = this.filter_type;
            let found_item = _.find(this.filter_options, function(o) { return o.value === filter_type; });
            if (found_item){
                this.selected_filter = found_item;
            }else{
                this.selected_filter = this.filter_options[0];
            }
        }else{
            this.selected_filter = this.filter_options[0];
        }
    },
    methods: {
        deleteFolder(item_id='', item_type='folders', event){
            this.$emit('on-folder-deletion', item_id, item_type, event);
        },
        createTag(){
            this.$emit('on-tag-creation');
        },
        editTag(item=null, item_type='tags'){
            this.$emit('on-tag-editing', item, item_type);
        },
        addLocation(item=null){
            this.$emit('on-add-location', item);
        },
        untagAssets(item='', item_type, event){
            this.$emit('on-untag-assets', item, item_type);
        },
        createFolder(parent_id=''){
            this.$emit('on-folder-creation', parent_id);
        },
        editFolder(item=null){
            this.$emit('on-folder-editing', item);
        },
        moveFolder(item=''){
            this.$emit('on-folder-moving', item);
        },
        changeFilter(selected_item, index, event){
            event.preventDefault();
            this.selected_filter = selected_item;
            this.$emit('on-filter-changing', selected_item, 'filter');
        },
        changeGroup(selected_item){
            if (selected_item.id !== -99 || ("fetch_meta_key" in selected_item)){
                this.$emit('on-group-changing', selected_item);
            }
        },
        moveContent(item='', event){
            this.$emit('on-content-moving', item, event);
        },
    }
}
</script>

<style scoped>

.main-root-top{
    position: relative;
}

.main-root-top ul{
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    padding: 15px;
}

.main-root-top > ul > li a:before{
    display: none;
}
.main-root-top > ul.root-folder > li{
    font-size: 20px;
    margin-bottom: 10px;

}
.main-root-top > ul.root-folder > li a{
    font-weight: 600;
}
.sub-root-top  .root-heading{
    margin-bottom: 15px;
    padding-top: 15px;
}
.sub-root-top{
    display: none;
}
.sub-root-top.active{
    display: block;
}

.root-top-menu.active a{
    color: #0D5296;
}

</style>
