<script>
import {Timezones} from "../views/assetflow/form_data/Timezones";
import {ItemLayoutOptions} from "@/views/assetflow/form_data/ItemLayoutOptions";
import {UserLayoutOptions} from "@/views/assetflow/form_data/UserLayoutOptions";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";

import UserMeta from "@/mixins/usermeta.mixin";

/**
 * Right sidebar component
 */
export default {
    components: {},
    mixins: [UserMeta],
    data() {
        return {
            layout: this.$root.layout.type,
            width: this.$root.layout.width,
            sidebarType: this.$root.layout.sidebar,
            topbar: this.$root.layout.topbar,
            loader: this.$root.layout.loader,
            selected_items: {
                layout_mode: "default",
                sidebar_mode: "dark",
                layout_width: "fluid",
                use_icons: 0
            },
            user_preferences_options: {
                users_layout_options: {
                    title: 'Layout Options',
                    options: UserLayoutOptions
                }
            },
            user_meta: {}
        };
    },
    created() {
        this.user_meta = window.USER_INFO.user_meta ?? this.user_meta;
        if (this.user_meta !== {}){
            let layout_options = this.user_meta.layout_options ?? {};

            if (layout_options.layout_mode){
                this.$root.changeMode(layout_options.layout_mode);
                this.changeType(layout_options.sidebar_mode);
                this.changeWidth(layout_options.layout_width);

                if (layout_options.use_icons === true){
                    this.$parent.toggleMenu();
                }

                this.selected_items = {
                    layout_mode: layout_options.layout_mode ?? "default",
                    sidebar_mode: layout_options.sidebar_mode ?? "dark",
                    layout_width: layout_options.layout_width ?? "fluid",
                    use_icons: layout_options.use_icons ?? 0,
                }
            }

        }
    },
    methods: {
        setUseIcons(value){
            this.setLayout(value, 'use_icons');
        },
        changeLayout(layout) {
            this.sidebarType = "dark";
            this.topbar = "light";
            this.$root.changeLayout({
                type: layout,
                sidebar: "dark",
                topbar: "light"
            });
        },
        changeType(type) {
            return this.$root.changeLayout({sidebar: type});
        },
        changeWidth(width) {
            return this.$root.changeLayout({width: width});
        },
        setLayout(value, key){
            let new_key = 'layout_options.' + key;
            if (key === 'use_icons'){
                this.$parent.toggleMenu();
            }
            this.set_usermeta(new_key, value, '');
        }
    },
};
</script>

<template>
    <div></div>
</template>

<style lang="scss"></style>
<style scoped>
.right-bar{
    overflow-y: auto;
}
</style>
