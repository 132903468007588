<template>
    <b-tab :id="required_action_name" class="border-0">
        <template v-slot:title>
            <span class="d-inline-block d-sm-none"><i class="fas fa-home"></i></span><span class="d-none d-sm-inline-block" @click="toggleAccordion($event)">{{ tab_name }}</span>
        </template>
        <b-card-body>
            <b-form-group v-if="required_action_name === 'export_assets' && template_id === 20" id="input-group-0" label="" >
                <b-form-checkbox
                    v-model="with_entity_logo"
                    name="with_entity_logo"
                    id="with_entity_logo"
                    value="1"
                    class="custom-checkbox-af-accent m-3 d-inline"
                    @change="setBulkActionOptions('with_entity_logo', $event)"
                >
                    <span class="my-2">With Entity Logo</span>
                </b-form-checkbox>
            </b-form-group>

            <b-form-group v-if="required_action_name === 'add_to_order'" id="input-group--1" label="" >
                <b-form-checkbox
                    v-model="shall_notify_subscribers"
                    name="notify_subscribers"
                    id="notify_subscribers"
                    value="1"
                    class="custom-checkbox-af-accent m-3 d-inline"
                    @change="setBulkActionOptions('shall_notify_subscribers', $event)"
                >
                    <span class="my-2">Notify Subscribers</span>
                </b-form-checkbox>
            </b-form-group>

            <div class="add_to_order_options m-3">
                <b-form-group id="input-group-4" label="">
                    <button
                        @click="performBulkAction(required_action_name, $event)"
                        :data-file_type="file_type"
                        :data-template_id="template_id"
                        :data-with_removed="with_removed"
                        class="btn btn-link af-btn-accent af-accent btn-secondary">
                        {{ btn_text }}
                    </button>
                </b-form-group>
            </div>
        </b-card-body>
    </b-tab>
</template>
<script>
export default {
    name: 'BulkActionSharedTab',
    props: {
        required_action_name: '',
        tab_name: '',
        btn_text: '',
        performBulkAction: {},
        setBulkActionOptions: {},
        toggleAccordion: {},
        file_type: '',
        template_id: '',
        with_removed: '',
    },
    data(){
        return {
            shall_notify_subscribers: null,
            with_entity_logo: 0,
        }
    }
}
</script>
