<template>
    <div class="LabelLeftBox mt-1">
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-sm mb-0 table-bordered">
                        <tbody>
                        <tr>
                            <th style="width: 40%;">Production</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('customer_info') && my_asset.customer_info" class="customer_info">{{ my_asset.customer_info.name }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Department</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('department_info') && my_asset.department_info" class="department_info">{{ my_asset.department_info.name }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Studio</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('group_info') && my_asset.group_info" class="group_info">{{ my_asset.group_info.name }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-sm mb-0 table-bordered">
                        <tbody>
                        <tr>
                            <th style="width: 40%;">Wrap Box</th>
                            <td>
                                <span v-if="my_asset.asset_group_info">{{ my_asset.asset_group_info.uid}}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Label Date</th>
                            <td>{{ new Date() | moment('YYYY/MM/DD')}}</td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Label Created By</th>
                            <td>{{ user_info.name || '-' }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p class="mt-2 mb-1"><i class="bx bx-detail font-size-16 text-af-accent"></i> <b>Asset
                    Details</b></p>

                <div class="table-responsive">
                    <table class="table table-sm mb-0 table-bordered">
                        <tbody>
                        <tr>
                            <th style="width: 40%;">Created By</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('created_by') && my_asset.created_by" class="created_by">{{ my_asset.created_by.name }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Created Date</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('created_at') && my_asset.created_at" class="created_at">{{ my_asset.created_at | moment('YYYY/MM/DD') }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Modified Date</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('updated_at') && my_asset.updated_at" class="updated_at">{{ my_asset.updated_at | moment('YYYY/MM/DD') }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LabelLeftBox',
    props: {
        item_type: {},
        my_asset: {},
        performAction: {}
    }
}
</script>
