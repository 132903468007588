<template>
    <Layout>
        <add-new-department
            v-if="item_info.item_type === 'departments'"
            ref="add-new-department"
            custom_id="my-"
            item_type='department'
            modal_title="Create New Department"
            :without_status="false"
            @on-new-item-adding="departmentEdited"
        >
        </add-new-department>

        <split-assets-modal
            ref="split-asset-modal"
            :selected_asset="selected_asset"
            :with_refresh="true"
        />

        <add-order-asset-modal
            ref="add-order-asset-modal"
            :item_info="item_info"
            :with_asset_details="true"
            :with_refresh="true"
            @on-new-item-adding="afterSavingAsset"
        />


        <SetOrderSubscribers
            modal_title="Manage Order Subscribers"
            ref="set_order_subscribers"
            @after-saving="$bvModal.hide('set_order_subscribers');afterSavingSubscribers()"
            @on-cancel-clicked="$bvModal.hide('set_order_subscribers')"
        />

        <AcquisitionAssetModal
            v-if="item_info.item_type === 'assets'"
            :ref="add_new_asset_id"
            item_type="asset"
            modal_title="Add New Asset"
            save_text="Create & View"
            :should_add="true"
            @on-new-item-adding="goToAsset"
        />

        <PageHeaderCustom
            v-if="!(item_info.hasOwnProperty('component') && item_info.component === 'sp_storages-relocate')"
            :title="getPageTitle(true)"
            :item_info="item_info.item_type === 'assets' ? item_info : null"
            :items="items"
            :status_class="getItemStatus(true)"
            :status_text="getItemStatus()"
        >

            <OrderStatusButtons v-if="item_info.item_type === 'orders'" :item_info="item_info"/>


            <div v-if="item_info.item_type === 'orders'" class="action-button">
                <b-dropdown dropleft class="add-filter" variant="danger">
                    <template slot="button-content">
                        <i class="mdi mdi-dots-horizontal text-white"></i>
                    </template>
                    <b-dropdown-item
                        v-if="permissions_list['add_' + item_info.item_type]"
                        :href="'/' + item_info.current_page_type + '/'+item_info.item_type+'/new'">
                        New
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="item_info.status === 0 && permissions_list['edit_' + item_info.item_type]"
                        :href="'/' + item_info.current_page_type + '/'+item_info.item_type+'/' + item_info.id + '/edit'">Edit
                    </b-dropdown-item>

                    <b-dropdown-item
                        v-if="item_info.status === 0 && permissions_list['manage_' + item_info.item_type] && item_info.hasOwnProperty('order_lines_count') && item_info.order_lines_count"
                        @click="performAction(item_info, 'remove_order_items', $event)">Remove All {{ item_info.order_lines_count }} Items
                    </b-dropdown-item>

                </b-dropdown>
            </div>
            <div v-else-if="item_info.item_type === 'departments'" class="action-button">
                <b-dropdown dropleft class="add-filter" variant="danger">
                    <template slot="button-content">
                        <i class="mdi mdi-dots-horizontal text-white"></i>
                    </template>

                    <b-dropdown-item @click="myEditDepartment(item_info, $event)">Edit
                    </b-dropdown-item>

                    <b-dropdown-item
                        variant="danger"
                        @click="performAction(item_info, 'delete_'+item_info.item_type, $event)"
                    >Delete
                    </b-dropdown-item>
                </b-dropdown>
            </div>

            <div v-else-if="item_info.item_type === 'part_change'" class="action-button">

                <SetChangeSceneDescription
                    ref="set-change-scene-description"
                    item_type='description-dashboard'
                    modal_title="Update Scene Notes"
                    :received_item_id="item_info.id"
                    @on-new-item-adding="AfterSettingDescription"
                >
                </SetChangeSceneDescription>


                <EditChangeNotes
                    ref="edit-change-notes"
                    :item_type="'changes-notes' + unique_id"
                    modal_title="Edit Change Notes"
                    :with_item_details="false"
                    @on-new-item-adding="reloadPage"
                >
                </EditChangeNotes>

                <EditChangeScenes
                    ref="edit-change-scenes"
                    :item_type="'changes-scenes' + unique_id"
                    modal_title="Edit Change Scenes"
                    :with_item_details="false"
                    @on-new-item-adding="reloadPage"
                >
                </EditChangeScenes>

                <EditChangeSetup
                    ref="edit-change-setup"
                    :item_type="'changes-setup' + unique_id"
                    modal_title="Edit Change Setup"
                    :with_item_details="false"
                    @on-new-item-adding="reloadPage"
                >
                </EditChangeSetup>

                <EditGeneratePdf
                    ref="edit-generate-pdf"
                    :item_type="'generate-pdf' + unique_id"
                    modal_title="Generate PDF"
                    object_type="change"
                    :with_item_details="false"
                    @on-new-item-adding="reloadPage"
                >
                </EditGeneratePdf>


                <b-dropdown dropleft class="add-filter" variant="danger">
                    <template slot="button-content">
                        <i class="mdi mdi-dots-horizontal text-white"></i>
                    </template>


                    <b-dropdown-group id="dropdown-group-1" header="Change">
                        <b-dropdown-item target="_blank" :href="'/' + item_info.current_page_type + '/changes/'+item_info.change_info.id +'/view'">View Change</b-dropdown-item>
                        <b-dropdown-item v-if="item_info.change_info" @click="editChange(item_info.change_info, 'change_notes', $event)">Change Notes</b-dropdown-item>
                        <b-dropdown-item v-if="item_info.change_info" @click="editChange(item_info.change_info, 'change_scenes', $event)">Change Scenes</b-dropdown-item>
                        <b-dropdown-item v-if="item_info.change_info" @click="editChange(item_info.change_info, 'change_setup', $event)">Change Setup</b-dropdown-item>
                        <b-dropdown-item v-if="item_info.change_info" @click="editChange(item_info.change_info, 'generate_pdf', $event)">Generate PDF</b-dropdown-item>
                    </b-dropdown-group>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-group id="dropdown-group-2" header="Scene Notes">
                        <b-dropdown-item @click="addDescriptionModal()">Add Scene Notes</b-dropdown-item>
                        <b-dropdown-item v-if="item_info.change_info" target="_blank" :href="'/' + item_info.current_page_type + '/shoot-day-live?scene='+item_info.part_id+'&character='+item_info.change_info.character_info.id+'&change='+item_info.change_info.id">View in SDL</b-dropdown-item>
                    </b-dropdown-group>

                </b-dropdown>
            </div>
            <div v-else-if="item_info && !['dispositions', 'acquisitions', 'ledgers', 'characters', 'changes', 'file_imports', 'sp_locations', 'sp_storages'].includes(item_info.item_type)" class="action-button">
                <b-dropdown dropleft class="add-filter" variant="danger" v-if="permissions_list['add_' + item_info.item_type] || permissions_list['edit_' + item_info.item_type] || permissions_list['delete_' + item_info.item_type]">
                    <template slot="button-content">
                        <i class="mdi mdi-dots-horizontal text-white"></i>
                    </template>
                    <b-dropdown-item
                        v-if="item_info.item_type === 'assets' && permissions_list['add_' + item_info.item_type]"
                        @click="performAction(item_info, 'addAsset', $event)"
                    >New
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-else-if="permissions_list['add_' + item_info.item_type]"
                        :href="'/' + item_info.current_page_type + '/'+item_info.item_type+'/new'">
                        New
                    </b-dropdown-item>

                    <b-dropdown-item
                        v-if="item_info.item_type !=='assets' && permissions_list['edit_' + item_info.item_type]"
                        :href="'/' + item_info.current_page_type + '/'+item_info.item_type+'/' + item_info.id + '/edit'">Edit
                    </b-dropdown-item>

                    <b-dropdown-item
                        v-if="['assets', 'addresses'].includes(item_info.item_type) && permissions_list['delete_' + item_info.item_type]"
                        variant="danger"
                        @click="performAction(item_info, 'delete_'+item_info.item_type+'', $event)"
                    >Delete
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="item_info.item_type === 'orders' && permissions_list['edit_' + item_info.item_type] && item_info.status === 0"
                        @click="performAction(item_info, 'add_asset', $event)"
                    >Add Asset
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </PageHeaderCustom>

        <div v-if="['orders', 'order_lines', 'managed_storages', 'asset_passports', 'dispositions', 'acquisitions', 'changes', 'part_change'].includes(item_info.item_type)">
            <link rel="stylesheet" href="https://rawgit.com/enyo/dropzone/master/dist/dropzone.css"/>
        </div>

        <div v-if="item_info.item_type === 'purchaseOrders'">
            <purchase-order-header :po="item_info"/>
        </div>

        <div v-if="item_info.item_type === 'orders'">
            <CustomOrderDetails
                ref="custom-order-details"
                :order="item_info"
                @shall-show-add-asset-form="shallShowAddAssetForm"
            />
        </div>
        <div v-else-if="item_info.item_type === 'order_lines'">
            <CustomOrderLineDetails :order_line="item_info"/>
        </div>
        <div v-else-if="item_info.item_type === 'managed_storages'">
            <CustomManagedStorageDetails :managed_storage="item_info" />
        </div>
        <div v-else-if="item_info.item_type === 'asset_passports'">
            <CustomAssetPassportDetails :item_info="item_info"/>
        </div>
        <div v-else-if="item_info.item_type === 'dispositions'">
            <CustomDispositionDetails :item_info="item_info"/>
        </div>
        <div v-else-if="item_info.item_type === 'acquisitions'">
            <CustomAcquisitionDetails :item_info="item_info"/>
        </div>
        <div v-else-if="item_info.hasOwnProperty('has_scene_relation') && item_info.has_scene_relation">
            <CustomChangeSceneDetails :item_info="item_info"/>
        </div>
        <div v-else-if="item_info.item_type === 'changes'">
            <CustomChangeDetails :item_info="item_info"/>
        </div>
        <div v-else-if="item_info.item_type === 'ledgers'">
            <CustomLedgerDetails :item_info="item_info"/>
        </div>
        <div v-else-if="item_info.item_type === 'bulk_updates'">
            <CustomBulkUpdateDetails :item_info="item_info"/>
        </div>
        <div v-else-if="item_info.item_type === 'characters'">
            <CustomCharacterDetails :item_info="item_info"/>
        </div>
        <div v-else-if="item_info.item_type === 'assets'">
            <AssetDetailsNewAllTabs v-if="current_module && current_module.id !== 3" :tabs="tabs" :item_info="item_info"></AssetDetailsNewAllTabs>
            <AssetDetailsNewOld v-else :tabs="tabs" :item_info="item_info"></AssetDetailsNewOld>
        </div>
        <div v-else-if="item_info.item_type === 'modules'">
            <CustomModuleDetails :item_info="item_info"/>
        </div>
        <div v-else-if="item_info.item_type === 'file_imports'">
            <CustomFileImportDetails :item_info="item_info"/>
        </div>
        <div v-else-if="item_info.item_type === 'sp_locations'">
            <CustomSpLocationDetails :item_info="item_info"/>
        </div>
        <div v-else-if="item_info.hasOwnProperty('component') && item_info.component === 'sp_storages-relocate'">
            <RelocateStorage :item_info="item_info"></RelocateStorage>
        </div>
        <div v-else-if="item_info.item_type === 'sp_storages'">
            <CustomSpStorageDetails :item_info="item_info"/>
        </div>

        <div v-else-if="['folders', 'tags', 'categories'].includes(item_info.item_type)">
            <CustomAssetsParentDetails :item_info="item_info"/>
        </div>

        <div v-else class="row" :class="['customers', 'service_providers'].includes(item_info.item_type) ? 'af-dashboard' : ''">
            <div class="col-lg-12 role-description" v-if="item_info.item_type === 'roles'">
                <p class="mb-1">{{ item_info.description }}</p>
            </div>

            <div class="col-lg-12">
                <item-tabs :tabs="tabs" :item_info="item_info"></item-tabs>
            </div>
        </div>
    </Layout>
</template>


<script>

import Layout from "@/layouts/main";
import PageHeaderCustom from "@/components/page-header-custom";
import ItemTabs from "./widgets/Tabs";
import AssetDetailsNew from "./widgets/detailsViews/AssetDetailsNew";
import AddNewDepartment from "../customers/extras/AddNewDepartment";
import PurchaseOrderHeader from "./PurchaseOrderHeader";
import SplitAssetsModal from "../customers/extras/SplitAssetsModal";
import CustomOrderDetails from "./ordersContent/CustomOrderDetails";
import CustomOrderLineDetails from "./ordersContent/CustomOrderLineDetails";
import AddOrderAssetModal from "../customers/extras/AddOrderAssetModal";
import OrderStatusButtons from "./OrderStatusButtons";
import CustomManagedStorageDetails from "./ordersContent/CustomManagedStorageDetails";
import CustomAssetPassportDetails from "./CustomAssetPassportDetails";
import CustomDispositionDetails from "./CustomDispositionDetails";
import CustomAcquisitionDetails from "./CustomAcquisitionDetails";
import CustomLedgerDetails from "./CustomLedgerDetails";
import CustomCharacterDetails from "./CustomCharacterDetails";
import CustomChangeDetails from "./CustomChangeDetails";
import CustomChangeSceneDetails from "./CustomChangeSceneDetails";
import SetChangeSceneDescription from "./ChangeSceneRel/SetChangeSceneDescription";
import EditChangeNotes from "../customers/extras/EditChangeNotes";
import EditChangeScenes from "../customers/extras/EditChangeScenes";
import EditChangeSetup from "../customers/extras/EditChangeSetup";
import AssetDetailsNewAllTabs from "./widgets/detailsViews/AssetDetailsNewAllTabs";
import AcquisitionAssetModal
    from "../customers/acquisitions/CostumeAcquisitionForm/AcquisitionModals/AcquisitionAssetModal";
import EditGeneratePdf from "../customers/extras/EditGeneratePdf";
import CustomModuleDetails from "./CustomModuleDetails";
import CustomFileImportDetails from "./CustomFileImportDetails";
import RelocateStorage from "../customers/sp_storages/RelocateStorage";
import CustomSpLocationDetails from "./CustomSpLocationDetails";
import CustomSpStorageDetails from "./CustomSpStorageDetails";

import {getLocationDetails} from "@/views/assetflow/form_data/SpLocationSharedFunctions";
import CustomAssetsParentDetails from "./CustomAssetsParentDetails";
import SetOrderSubscribers from "../customers/orders/SetOrderSubscribers";
import CustomBulkUpdateDetails from "./CustomBulkUpdateDetails";
import AssetDetailsNewOld from "./widgets/detailsViews/AssetDetailsNewOld";

export default {
        name: "ViewItem",
        components: {
            AssetDetailsNewOld,
            CustomBulkUpdateDetails,
            SetOrderSubscribers,
            CustomAssetsParentDetails,
            CustomSpStorageDetails,
            CustomSpLocationDetails,
            RelocateStorage,
            CustomFileImportDetails,
            CustomModuleDetails,
            EditGeneratePdf,
            AcquisitionAssetModal,
            AssetDetailsNewAllTabs,
            EditChangeSetup,
            EditChangeScenes,
            EditChangeNotes,
            SetChangeSceneDescription,
            CustomChangeSceneDetails,
            CustomChangeDetails,
            CustomCharacterDetails,
            CustomLedgerDetails,
            CustomAcquisitionDetails,
            CustomDispositionDetails,
            CustomAssetPassportDetails,
            CustomManagedStorageDetails,
            OrderStatusButtons,
            AddOrderAssetModal,
            CustomOrderLineDetails,
            CustomOrderDetails,
            SplitAssetsModal,
            PurchaseOrderHeader, AddNewDepartment, AssetDetailsNew, ItemTabs, Layout, PageHeaderCustom },
        data() {
            return {
                add_new_asset_id: 'add-asset-list',
                unique_id: 'scene_notes',
                item_info: {},
                items: [
                    {
                        text: "Users",
                        href: "/"
                    },
                    {
                        text: "View",
                        active: true
                    }
                ],
                isResetError: false,
                tabs: [],
                permissions_list: {},
                selected_asset: null
            };
        },
        created() {
            if (this.received_form_lists !== ''){
                this.$root.setFormLists(this.received_form_lists);
            }
            if (this.shared_info_string !== ''){
                this.$root.setSharedInfo(this.shared_info_string);
            }

            this.item_info = JSON.parse(this.item_info_string);
            if (this.item_info.item_type === 'assets'){
                this.items = [
                    {
                        text: "Assets",
                        href: "/assets"
                    },
                    {
                        text: this.getPageTitle(),
                        active: true
                    }
                ]

                if (this.item_info.parent_folder_name){
                    this.items.splice(1, 0, {
                        text: this.item_info.parent_folder_name ?? '',
                        href: this.item_info.parent_folder_id ? '/folders/'+this.item_info.parent_folder_id+'/view' : '/'
                    });
                }
            }else{
                let item_title_text = this.item_title;
                switch (item_title_text){
                    case 'Sp Storages':
                        item_title_text = 'Storage';
                        break;
                    case 'Sp Locations':
                        item_title_text = 'Locations';
                        break;
                }

                this.items[0].text = item_title_text;

                let item_type = this.item_info.item_type;
                let current_page_type = this.item_info.current_page_type;
                if (['folders', 'categories'].includes(item_type) && this.item_info.hasOwnProperty('full_name_info')){
                    let full_name_info = this.item_info.full_name_info.map(function(full_name_item) {
                        let edited_item = full_name_item;
                        edited_item.href = ('/' + current_page_type + '/' + item_type + '/' + edited_item.id + '/view');
                        return edited_item;
                    });
                    let title_item = this.items[0];
                    title_item.href = '/' + current_page_type + '/' + item_type;
                    full_name_info.splice(0,0, title_item);
                    this.items = full_name_info;
                }else{
                    this.items[1].text = this.getPageTitle();
                }
            }


            this.tabs = this.item_info.tabs;
            this.isResetError = !!this.error;

            let currentUserPermissions =  this.item_info.currentUserPermissions;
            this.permissions_list = {};
            let item_type = this.item_info.item_type;
            if (item_type !== 'bulk_updates'){
                this.permissions_list['add_' + item_type] = this.hasPermission('add_' + item_type, currentUserPermissions);
                this.permissions_list['edit_' + item_type] = this.hasPermission('edit_' + item_type, currentUserPermissions);
            }
            this.permissions_list['delete_' + item_type] = this.hasPermission('delete_' + item_type, currentUserPermissions);
            if (item_type === 'assets') {
                this.permissions_list.assets_direct_admin = this.hasPermission('assets_direct_admin', currentUserPermissions);
            }else if (item_type === 'orders'){
                this.permissions_list['manage_' + item_type] = this.hasPermission('manage_' + item_type, currentUserPermissions);
            }

        },
        props: {
            item_title: {
                type: String,
                required: false,
                default: () => null
            },
            item_info_string: {
                type: String,
                required: false,
                default: () => null
            },
            error: {
                type: String,
                required: false,
                default: () => null
            },
            received_form_lists:{
                type: String,
                required: false,
                default: () => ""
            },
            shared_info_string:{
                type: String,
                required: false,
                default: () => ""
            },
        },
        methods:{
            editChange(change, action, event){
                let change_info_for_modal = Object.assign({}, change);

                switch (action) {
                    case 'change_notes':
                        change_info_for_modal.change_status = change.status;
                        this.$refs['edit-change-notes'].setSelectedItem(change_info_for_modal);
                        this.$bvModal.show('create-changes-notes' + this.unique_id);
                        break;
                    case 'change_scenes':
                        change_info_for_modal.part_ids = change.parts_info.map(function(e) { return e.id; });
                        this.$refs['edit-change-scenes'].setSelectedItem(change_info_for_modal);
                        this.$bvModal.show('create-changes-scenes' + this.unique_id);
                        break;
                    case 'change_setup':
                        change_info_for_modal.character_id = change.character_id;
                        change_info_for_modal.number = change.name;
                        this.$refs['edit-change-setup'].setSelectedItem(change_info_for_modal);
                        this.$bvModal.show('create-changes-setup' + this.unique_id);
                        break;
                    case 'generate_pdf':
                        this.$refs['edit-generate-pdf'].setSelectedItem(change_info_for_modal);
                        this.$bvModal.show('create-generate-pdf' + this.unique_id);
                        break;
                }
            },
            addDescriptionModal(){
                this.$refs['set-change-scene-description'].setSelectedItem({description: this.item_info.description});
                this.$bvModal.show('create-description-dashboard');
            },
            AfterSettingDescription(new_item, item_type){
                this.item_info.description = new_item.description;
            },
            getPageTitle(main_title=false){
                let item_type = this.item_info.item_type;
                if (!item_type && this.item_info.hasOwnProperty('has_scene_relation') && this.item_info.has_scene_relation){
                    item_type = 'part_change';
                }
                switch (item_type) {
                    case 'assets':
                    case 'characters':
                        return this.item_info.name;
                    case 'managed_storages':
                        let with_uid = main_title ? ` (${this.item_info.uid})` : '';
                        let asset_name = this.item_info.name;
                        if (this.item_info.hasOwnProperty('item_info') && this.item_info.item_info){
                            asset_name = this.item_info.item_info.name;
                        }
                        return (this.item_info.name !== '' ? (asset_name + with_uid) : this.item_title);
                    case 'sp_storages':
                        if (main_title){
                            return this.item_info && this.item_info.hasOwnProperty('name') ? ( ( (this.item_info.hasOwnProperty('storage_wrapper_info') ? ('<i class="' + this.item_info.storage_wrapper_info.icon +'"></i> ') : '')+this.item_info.name + '')) : '';
                        }
                        return this.item_info.name;
                    case 'sp_locations':
                        if (main_title && this.item_info.id !== null){
                            return getLocationDetails(this.item_info);
                        }
                        return this.item_info.hasOwnProperty('full_location_name') ? this.item_info.full_location_name : (this.item_info.hasOwnProperty('location_number') && this.item_info.location_number ? this.item_info.location_number : this.item_info.name);
                    case 'ledgers':
                        if (main_title){
                            return this.item_info.nominal_code + ': ' + this.item_info.description;
                        }
                        return this.item_info.uid;
                    case 'acquisitions':
                        if (main_title){
                            let acquisition_class = 'bx ' + (this.item_info.ownership_type === 1 ? 'bx-cube-alt' : 'bx-time');
                            return '<i class="' + acquisition_class + '"></i> ' + this.item_info.name
                        }
                        break;
                    case 'changes':
                        if (main_title){
                            if (this.item_info.hasOwnProperty('character_info') && this.item_info.character_info){
                                return '('+this.item_info.character_info.character_number+') ' +this.item_info.character_info.name + ': Change ' +this.item_info.name;
                            }
                            return this.item_info.name !== '' ? this.item_info.name : 'CHG-' + this.item_info.uid;
                        }
                        break;
                    case 'part_change':
                        if (main_title){
                            return this.getHtmlTitleFor('part_changes');
                        }
                        break;
                    case 'orders':
                        return this.item_info.uid + ': ' + this.item_info.name;
                    case 'bulk_updates':
                        if (main_title) {
                            let status_block = '<span class="cm_order_label status-background-' + (this.item_info.status === 'pending' ? 'orange' : 'green') + '">' + this.item_info.status + '</span>';
                            return this.item_info.uid + ' ' + status_block;
                        }
                    case 'users':
                        if (main_title) {
                            return (this.item_info.name !== '' ? this.item_info.name : this.item_title) + ' ' + this.getUserStatus(this.item_info);
                        }else{
                            return this.item_info.name !== '' ? this.item_info.name : this.item_title;
                        }
                    default:
                        return (this.item_info.name !== '' ? this.item_info.name : this.item_title);
                }
                return this.item_title;
            },
            getUserStatus(user_info){
                let status = user_info.user_state;
                if (user_info.status === 0){
                    status = user_info.status;
                }

                let user_states_list = [
                    'Disabled',
                    'Active',
                    'Invited',
                    'Created'
                ];
                let user_icons_list = [
                    'red',
                    'green',
                    'brown',
                    'grey'
                ];
                return '<span class="badge text-white p-2 status-background-' + user_icons_list[status] + '">' + user_states_list[status] + '</span>';
            },
            getItemStatus(just_class){
                if (this.item_info.item_type === 'orders'){
                    switch (this.item_info.status){
                        case 0:
                            return just_class ? 'cm_order_label status-background-grey' : 'Open';
                        case 1:
                            return just_class ? 'cm_order_label status-background-brown' : 'Confirmed';
                        case 2:
                            return just_class ? 'cm_order_label status-background-orange' : 'In Progress';
                        case 3:
                            return just_class ? 'cm_order_label status-background-green' : 'Completed';
                        case 4:
                            return just_class ? 'cm_order_label status-background-red' : 'Cancelled';
                        case 5:
                            return just_class ? 'cm_order_label status-background-red' : 'Awaiting Transport';
                        default:
                            return just_class ? 'cm_order_label status-background-black' : 'Open';
                    }
                }
                return '';
            },
            getAssetTitle(){
                let html = this.item_info.name;
                if (this.item_info.storage_status === 1){
                    html += ' <i class="bx bx-cuboid icon-active" title="In Storage"></i>';
                }else{
                    html += ' <i class="bx bx-cuboid icon-inactive" title="Not In Storage"></i>';
                }

                if (this.item_info.hasOwnProperty('cAFID_info') && this.item_info.cAFID_info){
                    html += ' <i class="bx bx-detail icon-active" title="Has Asset Passport"></i>';
                }else{
                    html += ' <i class="bx bx-detail icon-inactive" title="No Asset Passport"></i>';
                }

                if (this.item_info.bss_status === 1){
                    html += ' <i class="bx bx-store icon-active" title="In Markteplace"></i>';
                }else{
                    html += ' <i class="bx bx-store icon-inactive" title="Not In Markteplace"></i>';
                }

                if (this.item_info.asset_usage === 1){
                    html += ' <i class="bx bx-movie icon-active" title="On Camera"></i>';
                }else if (this.item_info.asset_usage === 0){
                    html += ' <i class="bx bx-movie icon-inactive" title="Off Camera"></i>';
                }else{
                    html += ' <i class="bx bx-movie icon-neutral" title="Asset Usage: Unknown"></i>';
                }

                if (this.item_info.is_hero === 1){
                    html += ' <i class="bx bx-cool icon-active" title="Hero Item"></i>';
                }else if (this.item_info.is_hero === 0){
                    html += ' <i class="bx bx-cool icon-inactive" title="Not Hero Item"></i>';
                }else{
                    html += ' <i class="bx bx-cool icon-neutral" title="Is Hero: Unknown"></i>';
                }


                if (this.item_info.is_asset === 1){
                    html += ' <i class="bx bx-money icon-active" title="Asset Item"></i>';
                }else if (this.item_info.is_asset === 0){
                    html += ' <i class="bx bx-money icon-inactive" title="Not Asset Item"></i>';
                }else{
                    html += ' <i class="bx bx-money icon-neutral" title="Is Asset: Unknown"></i>';
                }

                if (this.item_info.hasOwnProperty('asset_group_id') && this.item_info.asset_group_id){
                    html += ' <i class="bx bx-box icon-active" title="Has Asset Group"></i>';
                }else{
                    html += ' <i class="bx bx-box icon-inactive" title="No Asset Group"></i>';
                }

                if (this.item_info.hasOwnProperty('parent_order') && this.item_info.parent_order){
                    html += ' <i class="bx bx-shopping-bag icon-active" title="Has Order"></i>';
                }else{
                    html += ' <i class="bx bx-shopping-bag icon-inactive" title="No Order"></i>';
                }

                return html;

            },
            departmentEdited(edited_item, item_type, event){
                this.item_info.name = edited_item.name;
                this.item_info.status = edited_item.status;
            },
            myEditDepartment(item, event){
                this.$refs['add-new-department'].setSelectedItem(item);
                this.$bvModal.show('create-my-department');
            },
            performAction(item, action_name, event=null) {
                switch (action_name) {
                    case 'delete_assets':
                        this.deleteItem(item.id, item.name, 'assets', event);
                        break;
                    case 'delete_addresses':
                        this.deleteItem(item.id, item.name, 'addresses', event);
                        break;
                    case 'delete_departments':
                        this.deleteItem(item.id, item.name, 'departments', event);
                        break;
                    case 'splitAsset':
                        this.selected_asset = item;
                        this.$bvModal.show('split-asset-modal');
                        break;
                    case 'add_asset':
                        this.$bvModal.show('add-order-asset-modal');
                        break;
                    case 'set_subscribers':
                        this.$bvModal.show('set_order_subscribers');
                        break;
                    case 'addAsset':
                        this.$refs[this.add_new_asset_id].setSelectedItem(null, null);
                        this.$bvModal.show('create-asset');
                        break;
                    case 'remove_order_items':
                        console.log("remove_order_items");
                        break;

                }
            },
            goToAsset(asset_info){
                if (asset_info && asset_info.hasOwnProperty('id')){
                    window.location = encodeURI("/" + this.item_info.current_page_type + "/assets/" + asset_info.id + "/view");
                }
            },
            deleteItem(item_id, item_name, item_type, event) {
                this.deleteItemByID(item_id, item_name, item_type, event)
                    .then((response) => {
                        window.location = encodeURI("/" + this.item_info.current_page_type + "/" + item_type);
                    });
            },
            afterSavingAsset(new_item){
                // console.log("'added this asset '");
                console.log("Asset info > ");
                console.log(new_item);
                this.$refs['custom-order-details'].refreshOrderLines();
            },
            afterSavingSubscribers(new_item){
                console.log(new_item);
            },
            shallShowAddAssetForm(){
                this.performAction(this.item_info, 'add_asset');
            },
            shallShowAddSubscriberForm(){
                this.performAction(this.item_info, 'set_subscribers');
            }
        }
    };
</script>

