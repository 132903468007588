<template>
    <Layout>
        <b-tabs pills content-class="p-3 text-muted b-form-tags select-action-block"
                active-nav-item-class="af-accent text-af-primary">
            <b-tab :active="action === 'new_asset'" class="border-0">
                <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                        <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Add Asset</span>
                </template>
                <NewAsset
                    :item_id="item_id"
                    :item_type="item_type"
                    :action="action"
                    :other_info_string="other_info_string"/>

            </b-tab>

            <b-tab :active="action === 'bulk_photo_upload'">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                    <span class="d-none d-sm-inline-block">Bulk Photo Upload</span>
                </template>

                <BulkPhotoUpload
                    :item_id="item_id"
                    :item_type="item_type"
                    :action="action"
                    :other_info_string="other_info_string"/>
            </b-tab>

        </b-tabs>
    </Layout>
</template>
<script>
import Layout from "@/layouts/main";
import NewAsset from "./NewAsset";
import BulkPhotoUpload from "./BulkPhotoUpload";

export default {
    name: "NewAssetOrBulkPhotoUpload",
    props: ['item_id', 'item_type', 'action', 'other_info_string'],
    components: {BulkPhotoUpload, NewAsset, Layout},
    created() {
        console.log("this.action");
        console.log(this.action);
    }
}
</script>
